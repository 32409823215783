import { createRouter, createWebHistory } from 'vue-router';
import AddTypeView from '@/views/AddTypeView.vue';
import CanvasView from '@/views/CanvasView.vue';
import LoginView from '@/views/LoginView.vue';
import RegisterView from '@/views/RegisterView.vue';
import HomeViewVue from '@/views/HomeView.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import ConfirmEmailView from '@/views/ConfirmEmailView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginView
    },
    {
      path: '/register',
      name: 'register',
      component: RegisterView
    },
    {
      path: '/confirm-email',
      name: 'confirm-email',
      component: ConfirmEmailView
    },
    {
        path: '/',
        name: 'main',
        redirect: '/welcome',
        component: HomeViewVue,
        meta: {
          requiresAuth: true 
        },
        children: [
          {
            path: '/welcome',
            name: 'welcome',
            component: WelcomeView,
          },
          {
            path: '/canvas/:id',
            name: 'canvas',
            component: CanvasView,
          },
          {
            path: '/modify_types/:type',
            name: 'modify_types',
            component: AddTypeView,
          }
        ]
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    const token = window.localStorage.getItem('climagruen_token');
    // check if token is valid??
    if (token) {
      // User is authenticated, proceed to the route
        next();
    } else {
      // User is not authenticated, redirect to login
      next({ name: 'login' });
    }
  } else {
    // Non-protected route, allow access
    next();
  }
});

export default router
