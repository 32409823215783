<template>
    <div class="d-flex">
        <div ref="chart" class="chart"></div>
        <span class="ms-3 text-dark max-width-150" v-html="decodedWaterbalanceNote"></span>
    </div>
</template>
  
<script setup>
    import { ref, onMounted,computed } from 'vue'
    import * as echarts from 'echarts'

    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    const store = useStore();

    const { t } =  useI18n();
    
    // Reference to the chart DOM element
    const chart = ref(null);

    onMounted(async () => {
        const selectedSimulation = store.state.simulations.selectedSimulation;
        const simulationResults = await store.dispatch('simulations/getSimulationResult', {simulationId: selectedSimulation, resultType: 'total'});
        setupChart(simulationResults);

    })

    const setupChart = (simData)=>{
        // Initialize the chart when the component is mounted
        const myChart = echarts.init(chart.value, 'light')
    
        const option = {
        tooltip: {
            trigger: 'item',
            formatter: '{b} <br/>{a}: {c}%',
        },
        legend: {
            top: 'top',
            data: [t('results.evaporation'), t('results.infiltration'), t('results.outlet')]
        },
        xAxis: {
            type: 'category',
            data: [
                // t('results.before_intervention'), 
                t('results.planned')
            ]
        },
        yAxis: {
            type: 'value',
            min: 0,
            max: 100,
            axisLabel: {
            formatter: '{value} %'
            }
        },
        series: [
            {
                name: t('results.evaporation'),
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    position: 'inside'
                },
                data: [
                    // Math.round(simData.waterbalance_total_reference.evaporation*100), 
                    Math.round(simData.waterbalance_total.evaporation*100)
                ] 
            },
            {
                name: t('results.infiltration'),
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    position: 'inside'
                },
                data: [
                    // Math.round(simData.waterbalance_total_reference.infiltration*100), 
                    Math.round(simData.waterbalance_total.infiltration*100)
                ] 
            },
            {
                name: t('results.outlet'),
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    position: 'inside'
                },
                data: [
                    // Math.round(simData.waterbalance_total_reference.runoff*100),
                    Math.round(simData.waterbalance_total.runoff*100)
                ] 
            }
        ]
        }
    
        // Set the option for the chart
        myChart.setOption(option)

    }

    // Function to decode HTML entities
    const decodeHtml = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    }

    // Computed property to get the decoded waterbalance note
    const decodedWaterbalanceNote = computed(() => decodeHtml(t('results.waterbalance_note')));

</script>
  
<style>
    .max-width-150{
        max-width: 150px;
    }
</style>
  