// store/modules/simulations.js
import axios from 'axios';
import { api_endpoints } from '@/js/api_endpoints';
import { openDB } from 'idb';

const dbPromise = openDB('simulationDB', 1, {
    upgrade(db) {
        if (!db.objectStoreNames.contains('simulationResults')) {
            const store = db.createObjectStore('simulationResults', { keyPath: ['simulationId', 'resultType'] });
            store.createIndex('simulationId', 'simulationId'); // Create an index on the simulationId field
        }
    },
});

function processStatisticalData(data) {
    const startDate = new Date('2005-01-01T01:00:00Z');
    const timestamps = Array.from({ length: 8760 }, (_, index) => {
        const date = new Date(startDate);
        date.setHours(date.getHours() + index);
        return date.toISOString();
    });
    delete data.datetime;
    data.dates = timestamps;
    return data;
}

// const groupParameters = (parameters) =>{
//     // get values with unique ids and group them together
//     const groupedParams = {};
//     for (const key in parameters) {
//         const param = parameters[key];
//         const id = param.id;
//         param.discreteName = key;
//         if (!groupedParams[id]) {
//             groupedParams[id] = [];
//         }

//         groupedParams[id].push(param);
//     }
//     return groupedParams;
// }

export default {
    namespaced: true,
    state: () => ({
        simulations: [],
        selectedSimulation: null,
        simulationResults: {},
    }),
    mutations: {
        SET_SIMULATIONS(state, simulations) {
            state.simulations = simulations;
        },
        SET_SELECTED_SIMULATION(state, simulationId) {
            state.selectedSimulation = simulationId;
        },
        SET_SIMULATION_RESULT(state, { simulationId, resultType, result }) {
            state.simulationResults = {
                ...state.simulationResults,
                [simulationId]: {
                    ...state.simulationResults[simulationId],
                    [resultType]: result,
                },
            };
        }
    },
    actions: {
        async fetchSimulations({ commit }, projectId) {
            try {
                const token = window.localStorage.getItem('climagruen_token');
                const response = await axios.get(api_endpoints.simulations(projectId), {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                commit('SET_SIMULATIONS', response.data);
            } catch (error) {
                console.error('Error fetching simulations:', error);
            }
        },
        async fetchSimulationResults({ commit }, { simulationId, resultType }) {
            try {
                const db = await dbPromise;
                let simResultResponse;
                let hydraulicParams;

                switch (true) {
                    case resultType.startsWith('statistical'):
                        simResultResponse = await axios.get(api_endpoints.sim_results_statistical(simulationId));
                        simResultResponse.data = processStatisticalData(simResultResponse.data);
                        break;
                    case resultType.startsWith('blockrain'):
                        const event_id = resultType.split('_').slice(1).join('_');
                        simResultResponse = await axios.get(api_endpoints.sim_results_blockrain(simulationId, event_id));
                        break;
                    case resultType.startsWith('total'):
                        simResultResponse = await axios.get(api_endpoints.sim_results_total(simulationId));
                        hydraulicParams = simResultResponse.data.params;
                        break;
                    default:
                        break;
                }
                
                await db.put('simulationResults', { simulationId, resultType, data: simResultResponse.data });
                commit('SET_SIMULATION_RESULT', { simulationId, resultType , result: true });
                if(hydraulicParams){
                    // const groupedParams = groupParameters(hydraulicParams);
                    commit('SET_SIMULATION_RESULT', { simulationId, resultType: 'groupedParams' , result: hydraulicParams });
                }
                return simResultResponse.data;
            } catch (error) {
                console.error('Error fetching simulation results:', error);
            }
        },
        async getSimulationResult({ commit, dispatch, state }, { simulationId, resultType }) {
            console.log('getSimulationResult', simulationId, resultType);
            const db = await dbPromise;
            const existingEntry = await db.get('simulationResults', [simulationId, resultType]);

            if (existingEntry) {
                if(!state.simulationResults[simulationId]?.[resultType]){
                    commit('SET_SIMULATION_RESULT', { simulationId, resultType , result: true });
                }
                if(resultType === 'total' && !state.simulationResults[simulationId]?.groupedParams){
                    // const groupedParams = groupParameters(existingEntry.data.params);
                    commit('SET_SIMULATION_RESULT', { simulationId, resultType: 'groupedParams' , result: existingEntry.data.params });
                }
                return existingEntry.data; // Return data from IndexedDB
            }

            // Fetch data and store in indexedDB
            const apiResponse = await dispatch('fetchSimulationResults', {simulationId, resultType});
            return apiResponse;
        },
    },
    getters: {
        simulations(state) {
            return state.simulations;
        },
        selectedSimulation(state) {
            return state.selectedSimulation;
        },
    },
};
