<script setup>
import { ref, computed } from 'vue';

const documentSource = "DWA-A 138-1: Anlagen zur Versickerung von Niederschlagswasser – Teil 1: Planung, Bau, Betrieb"

const props = defineProps({
    areaTypeTable: {
        type: Array,
        required: true,
    },
});

let componentIndex = null

const emit = defineEmits(['areaSelected']);

defineExpose({
    openModal,
});

const showModal = ref(false);

function openModal(index){
    showModal.value = true;
    componentIndex = index
};

const closeModal = () => {
    showModal.value = false;
};

const selectAreaGroup = (areaGroup) => {
    emit('areaSelected', {componentIndex, areaGroup});
    closeModal();
};


// Group rows by the first column (areaType)
const groupedAreaTypeTable = computed(() => {
    const groups = {};
    props.areaTypeTable.forEach((row) => {
        if (!groups[row.areaType]) {
            groups[row.areaType] = [];
        }
        groups[row.areaType].push(row);
    });
    return groups;
});
</script>

<template>
    <div>
        <!-- Button to open modal -->
        <!-- <button type="button" class="btn btn-primary" @click="openModal">
            {{ $t('hydraulics.select_area_type') }}
        </button> -->
        <!-- Modal content -->
        <div v-if="showModal" class="modal" tabindex="-1" role="dialog" style="display: block;">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('hydraulics.area_type_selection') }}</h5>
                        <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <span class="text-dark" style="margin-right: 8px;"> <i> {{ $t('hydraulics.reference') }}</i> </span>
                        <span class="text-dark"> {{ documentSource }} </span> 
                        <table class="table table-bordered table-sm" style="margin-top: 16px;">
                            <thead>
                                <tr>
                                    <th><strong>{{ $t('hydraulics.area_type') }}</strong></th>
                                    <th><strong>{{ $t('hydraulics.area_specification') }}</strong></th>
                                    <th><strong>{{ $t('hydraulics.area_group') }}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(rows, areaType) in groupedAreaTypeTable" :key="areaType">
                                    <tr
                                        v-for="(row, index) in rows"
                                        :key="row.areaGroup"
                                        @click="selectAreaGroup(row.areaGroup)"
                                        class="selectable-row"
                                    >
                                        <td v-if="index === 0" :rowspan="rows.length">{{ areaType }}</td>
                                        <td>{{ row.areaSpecification }}</td>
                                        <td>{{ row.areaGroup }}</td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<style scoped>
.modal {
    z-index: 1050;
    position: fixed;
    left: 50%;
    transform: translateX(-50%); ;
    border-radius: 0.3rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.modal-content {
    max-height: 90vh;

}
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    color: #000;
}
.modal-body {
    padding: 1rem;
    overflow-y: auto;
}

.selectable-row {
    cursor: pointer;
}

td:hover {
  background-color: #e3e8ec; /* Change the background of the hovered cell */
}

td:hover ~ td {
  background-color: #e3e8ec; /* Change the background of the siblings */
}

/* Optional: Style all `td` including the hovered one */
td:hover,
td:hover ~ td,
td:first-child:hover {
  background-color: #e3e8ec;
}
</style>
