<template>
    <div class="d-flex gap-3">
        <div class="form-floating">
            <select
                id="simulationType-dropdown"
                class="form-select"
                v-model="selectedSimulationType"
                @change="handleTypeChange"
            >
                <option :value="null" disabled>
                    {{ $t('results.select_simulation_type') }}
                </option>
                <option
                    v-for="simulationType in simulationTypes"
                    :key="simulationType.value"
                    :value="simulationType.value"
                    :disabled="simulationType.value === 'long_term'"
                >
                    {{ simulationType.name }} 
                </option>
            </select>
            <label for="simulationType-dropdown" class="form-label">
                {{ $t('results.simulation_type') }}
            </label>
        </div>    

        <!-- Dropdown menu for all combinations of D and T -->
        <div v-if="selectedSimulationType === 'blockrain'" class="form-floating">
            <select
                id="extremeEvents-dropdown"
                class="form-select mb-3"
                v-model="selectedCombination"
                @change="handleBlockRainSelection"
            >
                <option :value="null" disabled>
                    {{ $t('results.select_extreme_event') }}
                </option>
                <option
                    v-for="(option, index) in combinations"
                    :key="index"
                    :value="option.file_key"
                >
                    {{ option.label }}
                </option>
            </select>
            <label for="extremeEvents-dropdown" class="form-label">
                {{ $t('results.extreme_type') }}
            </label>
        </div>

        <div class="form-floating ms-auto">
            <select id="chartType-dropdown" class="form-select mb-3" v-model="selectedChart" @change="updateChart">
                <option :value="null" disabled>{{ $t('results.select_diagram') }}</option>
                <option value="irrigation" :disabled="selectedSimulationType === 'blockrain'">{{ chart_settings.irrigation.title }}</option>
                <option value="waterlevel">{{ chart_settings.waterlevel.title }}</option>
                <option value="evaporation">{{ chart_settings.evaporation.title }}</option>
                <option value="infiltration">{{ chart_settings.infiltration.title }}</option>
                <option value="waterthroughput">{{ chart_settings.waterthroughput.title }}</option>
            </select>
            <label for="chartType-dropdown-dropdown" class="form-label">
                {{ $t('results.resullt_type') }}
            </label>
        </div>

    </div>
    
    <div ref="chartRef" class="chart"></div>
</template>

<script setup>
    import { onMounted, ref, computed } from 'vue';
    import * as echarts from 'echarts';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import {getHydraulicComponents, getHydraulicConnections} from '@/js/api_calls';
    import { useRoute } from 'vue-router';


    const { t } =  useI18n();
    const store = useStore();
    const route = useRoute();

    const selectedChart = ref('irrigation');
    const chartRef = ref(null);
    let chartInstance;
    const chart_data = {
        statistical: {},
        blockrain: {}
    };

    const selectedSimulationType = ref('statistical');

    // Simulations fetched from the Vuex store
    const simulationTypes = [
        { value: 'statistical', name: t('results.statistical_type')},
        { value: 'blockrain', name: t('results.extreme_type')},
        { value: 'long_term', name: t('results.long_term_type')},
    ];



    const selectedSimulation = computed(() => store.state.simulations.selectedSimulation);

    const chart_settings = {
        x_axis: {
            statistical: {label: '', type: 'time'},
            blockrain: {label: t('results.statistical_chart_x_label') + '[s]', type: 'value'},
        },
        irrigation: {
            title: t('results.irrigation_chart_title'),
            y_axis: {label: t('results.irrigation_chart_y') }
        },
        waterlevel: {
            title: t('results.water_level_chart_title'),
            y_axis: {label: t('results.water_level_chart_y') },
        },
        evaporation: {
            title: t('results.evaporation_chart_title'),
            y_axis: {label: t('results.evaporation_chart_y') },
        },
        infiltration: {
            title: t('results.infiltration_chart_title'),
            y_axis: {label: t('results.infiltration_chart_y') },
        },
        waterthroughput: {
            title: t('results.water_throughput_chart_title'),
            inflow_prefix: t('results.inflow'),
            outflow_prefix: t('results.outflow'),
            y_axis: {label: t('results.water_throughput_chart_y') },
        }
    }

    // Define durations (D) in mins and return periods (T) in years
    const durations = [
                5,
                10,
                15,
                20,
                30,
                45,
                60,
                90,
                120,
                180,
                240,
                360,
                540,
                720,
                1080,
                1440,
                2880,
                4320,
                5760,
                7200,
                8640,
                10080
    ];
    const returnPeriods = [1, 5, 10, 30, 100];

    // Create an array to store all combinations of D and T
    const combinations = [];
    // Generate all D, T combinations and store them in the `combinations` array
    durations.forEach(duration => {
        returnPeriods.forEach(return_period => {
            const combination = { label: `D ${duration} min, R ${return_period} a`,  file_key: `blockrain_${duration}_${return_period}` };
            combinations.push(combination);
        });
    });

    const selectedCombination = ref(combinations[0].file_key);


    async function handleBlockRainSelection() {
        if (!selectedCombination.value || !selectedSimulation.value) return;
        await setupBlockrainCharts();
        updateChart();
    }

    onMounted(async ()=>{
        chartInstance = echarts.init(chartRef.value, 'light');
        await Promise.all([
            setupStatisticalCharts(),
            setupBlockrainCharts()
        ]);
        updateChart();
    })

    const setupStatisticalCharts = async () => {
        // get statistical data form indexedDB

        const resultType = 'statistical';

        const statistical_data = await store.dispatch('simulations/getSimulationResult', {simulationId: selectedSimulation.value, resultType})
        if(!statistical_data){
            chartInstance.clear();
            return;
        };
        const groupedParams = store.state.simulations.simulationResults[selectedSimulation.value].groupedParams;
        if (!groupedParams) {
            await store.dispatch('simulations/getSimulationResults', { simulationId: selectedSimulation.value, resultType: 'total' });
            groupedParams = store.state.simulations.simulationResults[selectedSimulation.value].groupedParams;
        }
        
        setChartOptions(statistical_data.dates , statistical_data.irrigation, groupedParams, 'irrigation', resultType );
        setChartOptions(statistical_data.dates , statistical_data.waterlevel, groupedParams, 'waterlevel', resultType);
        setChartOptions(statistical_data.dates , statistical_data.evaporation, groupedParams, 'evaporation', resultType);
        setChartOptions(statistical_data.dates , statistical_data.infiltration, groupedParams, 'infiltration', resultType);
        await setWaterThroughputChartOption(statistical_data.dates , {inflow: statistical_data.inflow, outflow: statistical_data.outflow}, groupedParams, 'waterthroughput', resultType);
    };

    const setupBlockrainCharts = async () => {
        // get blockrain data form indexedDB
        const blockrain_data = await store.dispatch('simulations/getSimulationResult', {simulationId: selectedSimulation.value, resultType: selectedCombination.value});
        console.log('Blockrain data:', blockrain_data);
        if(!blockrain_data){
            selectedChart.value = null;
            chartInstance.clear();
            return;
        };
        const groupedParams = store.state.simulations.simulationResults[selectedSimulation.value].groupedParams;
        if (!groupedParams) {
            await store.dispatch('simulations/getSimulationResults', { simulationId: selectedSimulation.value, resultType: 'total' });
            groupedParams = store.state.simulations.simulationResults[selectedSimulation.value].groupedParams;
        }
        const resultType_ = 'blockrain'
        setChartOptions(blockrain_data.datetime, blockrain_data.waterlevel, groupedParams, 'waterlevel', resultType_);
        setChartOptions(blockrain_data.datetime, blockrain_data.evaporation, groupedParams, 'evaporation', resultType_);
        setChartOptions(blockrain_data.datetime, blockrain_data.infiltration, groupedParams, 'infiltration', resultType_);
    };

    function handleTypeChange() {
        if(selectedSimulationType.value === 'blockrain'){
            selectedChart.value = 'waterlevel';
        }
        updateChart();
    }
    function updateChart() {
        console.log('Selected chart:', selectedChart.value);
        if(!selectedChart.value) return;
        chartInstance.clear();
        const option = chart_data[selectedSimulationType.value][selectedChart.value]
        chartInstance.setOption(option);
        chartInstance.resize();
    }

    const setChartOptions = (time_data, data, parameters, dataType, resultType)=>{
        const series_data = [];
        const series_default_options = {
            type: 'line',
            smooth: false,
            symbol: 'none',
        };

        for (const [discreteName , paramList] of Object.entries(parameters)) {
            // check if paramList is an Array or not
            let param;
            if(Array.isArray(paramList)){
                param = paramList[0];
            }else{
                param = paramList;
            }

            let summedData = data[discreteName]; ;

            if(!summedData || summedData.length === 0) continue;
            let series_name = param.name
            const series_datum = time_data.map((timestamp, index) => [timestamp, summedData[index]]);
            series_data.push({ ...series_default_options, name:series_name, data: series_datum });
        }

        const option = {
                        backgroundColor: '#FFFFFF', // Set background to white

                        tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                            return [pt[0], '10%'];
                            }
                        },
                        legend: {
                            data: series_data.map(series => series.name),
                            top: '40px'
                        },
                        toolbox: {
                            feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            saveAsImage: {}
                            }
                        },
                        xAxis: {
                            name: chart_settings.x_axis[resultType].label,
                            type: chart_settings.x_axis[resultType].type,
                            boundaryGap: false
                        },
                        yAxis: {
                            name: chart_settings[dataType].y_axis.label,
                            type: 'value',
                            boundaryGap: [0, '10%']
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            start: 0,
                            end: 100
                            }
                        ],
                        series: series_data
        };
        chart_data[resultType][dataType] = option;
    }
    const setWaterThroughputChartOption = async (time_data, data, parameters, dataType, resultType)=>{
        // const hydraulicComponent = await getHydraulicComponents(route.params.id);
        // const hydraulicConnections = await getHydraulicConnections(route.params.id);

        const series_data = [];
        const series_default_options = {
            type: 'line',
            smooth: false,
            symbol: 'none',
        };

        for (const [discreteName , paramList] of Object.entries(parameters)) {
            // check if paramList is an Array or not
            let param;
            if(Array.isArray(paramList)){
                param = paramList[0];
            }else{
                param = paramList;
            }

            const inflow_data = data.inflow[discreteName];

            if(!inflow_data || inflow_data.length === 0) continue;
            const inflow_series_name = `${chart_settings[dataType].inflow_prefix}-${param.name}`
            const series_datum = time_data.map((timestamp, index) => [timestamp, inflow_data[index]]);
            series_data.push({ ...series_default_options, name: inflow_series_name, data: series_datum });

            const outflow_data = data.outflow[discreteName];
            if(!outflow_data || outflow_data.length === 0) continue;
            for (let outflow_index = 0; outflow_index < outflow_data[0].length; outflow_index++) {
                const outflow_series_name = `${param.name}-${chart_settings[dataType].outflow_prefix}-${outflow_index + 1}`;
                const series_datum = time_data.map((timestamp, index) => [timestamp, outflow_data[index][outflow_index]]);
                series_data.push({ ...series_default_options, name: outflow_series_name, data: series_datum });
            }
        }

        const option = {
                        backgroundColor: '#FFFFFF', // Set background to white

                        tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                            return [pt[0], '10%'];
                            }
                        },
                        legend: {
                            data: series_data.map(series => series.name),
                            top: '20px',
                            type: 'scroll', // Enable scrolling for the legend
                            orient: 'horizontal',
                            align: 'left',
                            padding: [10, 10, 10, 10],
                        },
                        grid: {
                            top: '100px', // Adjust the top margin to provide more space for the legend
                            left: '3%',
                            right: '10%', // Adjust the right margin to provide space for the toolbox
                            bottom: '3%',
                            containLabel: true
                        },
                        toolbox: {
                            feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            saveAsImage: {}
                            }
                        },
                        xAxis: {
                            name: chart_settings.x_axis[resultType].label,
                            type: chart_settings.x_axis[resultType].type,
                            boundaryGap: false
                        },
                        yAxis: {
                            name: chart_settings[dataType].y_axis.label,
                            type: 'value',
                            boundaryGap: [0, '10%']
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            start: 0,
                            end: 100
                            }
                        ],
                        series: series_data
        };
        chart_data[resultType][dataType] = option;
    }

</script>

<style scoped>
.align-right{
    width: max-content;
    align-self: flex-end;
}
</style>