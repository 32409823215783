<template>
    <div id="login-mask" class="d-flex flex-column justify-content-center align-items-center">
      <div class="card text-white bg-dark mb-3">
        <div class="card-header">{{$t('login')}}</div>
        <div class="card-body">
          <form @submit.prevent="login">
            <div class="mb-3">
              <label for="username" class="form-label">{{$t('username')}}</label>
              <input type="text" id="username" v-model="username" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">{{$t('password')}}</label>
              <input type="password" id="password" v-model="password" class="form-control" required>
            </div>
            <button type="submit" class="btn btn-light">{{$t('login')}}</button>
          </form>
        </div>
      </div>
      <span>
        {{ $t("no_account") }}
        <RouterLink :to="{ name: 'register'}">
          {{ $t("register_page.register") }}
        </RouterLink>
      </span>
    </div>
</template>
  
<script setup>
    import { ref } from 'vue';
    import {api_endpoints} from '../js/api_endpoints.js';
    import { useRouter } from 'vue-router';
    import { useStore } from 'vuex';
    import axios from 'axios';
    import { RouterLink } from 'vue-router';


    const router = useRouter();
    const store = useStore();

    const username = ref('');
    const password = ref('');

    const login = async () => {
            // Placeholder function for login
            const params = {username:username.value, password:password.value};
            const user_credetials = new URLSearchParams();
            user_credetials.set("username", username.value);
            user_credetials.set("password", password.value);
            
            axios.post(api_endpoints.login, 
                        user_credetials.toString(), 
                        {
                            headers: { 
                                "Content-Type": "application/x-www-form-urlencoded"
                            }
                        }
            )
            .then(async (response) => {
                window.localStorage.setItem("climagruen_token", response.data.access_token);
                console.log(`Login successful...`);
                await store.dispatch('initialize');
                router.push({name: "welcome"});
            })
            .catch((error) => {
                console.log(error);
            })            
    };
</script>
  
<style scoped>
    #login-mask{
        grid-area: main;
    }
</style>