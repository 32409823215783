import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { MeshoptDecoder } from  'three/examples/jsm/libs/meshopt_decoder.module.js';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { Lut } from 'three/examples/jsm/math/Lut.js';

import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { createRegularMesh } from './meshing_utils';
import { createStandardAnnotation, createDimensionLineTick } from './plan_annotations';

import { api_endpoints } from './api_endpoints';
import { getHydraulicComponent } from './api_calls';
import axios from 'axios';

import {scaleAndCenterGroup } from './dxf_converter';


import { PlaneHelperCustom } from './PlaneHelperCustom';

import Stats from 'stats.js';
import { v4 as uuidv4 } from 'uuid';

// Openbim components (formerly IFC.js)
import * as OBC from "@thatopen/components";
import * as OBCF from "@thatopen/components-front";
import * as BUI from "@thatopen/ui";
import * as CUI from "@thatopen/ui-obc";
import * as WEBIFC from "web-ifc";
import { Mouse } from "@thatopen/components";

import * as bimFragment from '@thatopen/fragments';

// import fs, { copyFileSync } from "fs";

import { i18n } from '../main.js';

import * as jsts from 'jsts';


const customEvents = {
    polylineCompleted: new Event('polylineCompleted'),
    polylineStarted: new Event('polylineStarted'),
    planeSelected: new Event('planeSelected'),
    objectSelected: new Event('objectSelected'),
    objectUnselected: new Event('objectUnselected'),
    assetPlacementError: new Event('assetPlacementError'),
    terrainPointConfirmed: new Event('terrainPointConfirmed'),
    terrainPointPicked: new Event('terrainPointPicked'),
    undoTerrain: new Event('undoTerrain'),
    saveCustomGeometries: new Event('saveCustomGeometries'),
    exitAllFunctions: new Event('exitAllFunctions'),
    triggerModificationMode: new Event('triggerModificationMode'),
    deleteObject: new Event('deleteObject'),
    objectDeleted: new Event('objectDeleted'),
    errorDuringDeletion: new Event('errorDuringDeletion'),
    exportComplete: new Event('exportComplete'),
    levelHeightSelected: new Event('levelHeightSelected'),
    drawingOnExistingObject: new Event('drawingOnExistingObject'),
    drawingFreely: new Event('drawingFreely'),
}

const grid_size = 0.5; // m (meter)
const intersection_threshold = 0.0001; // m^2 (square meter)
const z_fighting_offset = 0.001; // (m)
const level_dimension = 10.0 // (m)
const clipping_plane_offset = 0.2; // (m) // this is the same as the drawing sphere radius, so they are visible when clipping planes are active
const snapping_threshold_line = 1.0; // (m) squared 
const snapping_threshold_face = 1.0; // (m) squared
const raycaster_line_threshold = 0.5; // (m)

const layers = {visible: 0, hidden: 1};

const drawingModes = {
    standard_green_roof: "Standard green roof",
    terrain_modeling: "Terrain"
}

const highlightModes = {
    ifc: "ifc_fragments",
    threejs: "standard_threejs",
}

// color names should consistent with object type names
const colorPalette = {
    object_highlight: 0xea802a,
    standard_green_roof: 0x206f25,
    standard_line_elements: 0x808080,
    polygon_vertex: 0xa22a2a,
    polygon_line: 'red',
    plant_bounding_box: 0xadd8e6,
    standard_line_elements: 0x808080,
}

const objectTypes = {
    standard: "standard_green_roof",
    individual_plant: "individual_plant_asset",
    asset_mix: "asset_mix",
    level: "level",
    level_dxf_background: "level_dxf_background",
}

const polygon_relations = {
    included: 1,
    intersect: 2,
    disjoint: 3,
}

const default_values = {
    sigma: 2.0,
    occupancy: 10.0,
    individual_placement: true,
    default_level_name: "Default Level 0"
}

const randomColors = {
    1: '#4b8b3b',
    2: '#1f77b4',
    3: '#9467bd',
    4: '#d62728',
    5: '#2ca02c',
    6: '#17becf',
    7: '#bcbd22',
    8: '#e377c2',
    9: '#7f7f7f',
    10: '#8c564b',
    11: '#ffbb78',
    12: '#98df8a',
    13: '#ff9896',
    14: '#c5b0d5',
    15: '#c49c94',
    16: '#f7b6d2',
    17: '#dbdb8d',
    18: '#9edae5',
    19: '#aec7e8',
    20: '#ffbb78',
    21: '#98df8a',
    22: '#ff9896',
    23: '#c5b0d5',
    24: '#c49c94',
    25: '#f7b6d2',
    26: '#dbdb8d',
    27: '#9edae5',
    28: '#aec7e8',
    29: '#ffbb78',
    30: '#98df8a'
};

class HolePlacementError extends Error {
    constructor(message) {
        // Pass the message to the parent Error class
        super(message);

        // Maintain proper stack trace (for debugging)
        if (Error.captureStackTrace) {
        Error.captureStackTrace(this, HolePlacementError);
        }

        // Custom name for the error
        this.name = 'HolePlacementError';
    }
}

class DataBaseError extends Error {
    constructor(message) {
        // Pass the message to the parent Error class
        super(message);

        // Maintain proper stack trace (for debugging)
        if (Error.captureStackTrace) {
        Error.captureStackTrace(this, DataBaseError);
        }

        // Custom name for the error
        this.name = 'DataBaseError';
    }
}

class Action {
    constructor(undo) {
        this.undo = undo;
    }
}

class ModelingInterfaceManager{
    constructor(){
        this.domElement;
        this.components;
        this.world;
        this._scene;
        this.ifc_model;
        this.axesHelper;
        this.custom_orbit_controls;

        this.green_roof_objects = new THREE.Group();
        this.green_roof_objects.name = "cg_green_roof_objects";
        this.individual_assets = new THREE.Group();
        this.individual_assets.name = 'cg_individual_assets';
        this.asset_mixes = new THREE.Group();
        this.asset_mixes.name = 'cg_asset_mixes';
        this.annotations = new THREE.Group();
        this.annotations.name = 'cg_plan_annotations';
        this.levels = new THREE.Group();
        this.levels.name = 'cg_levels';
        // this.levels.visible = false;
        this.simulation_objects = new THREE.Group();
        this.simulation_objects.name = 'cg_simulation_objects';
        this.simulation_objects.visible = false;

        this.objects3d = [this.green_roof_objects, this.levels] // If an ifc model is uploaded the 3d geometries will be added to this array as well
        
        this.gltfLoader = createGLTFLoader();
        this.glbExporter = new GLTFExporter();
        this.animationId;
        this.selectionManager = new SelectionManager(this);
        this.current_green_roof_name;
        this.default_background;
        this.min_gloss;

        this.keyState = {
            shiftRight  : false,
            shiftLeft   : false
        };
    }

    get scene() {
        return this._scene;
    }

    set scene(value) {
        this._scene = value;
    }


    async setUpCanvas(canvasDomElement){
        this.domElement = canvasDomElement;
        const canvasParent = canvasDomElement.parentElement;

        // Set up openbim-environment
        BUI.Manager.init();
        CUI.Manager.init();
        const components = new OBC.Components();

        const worlds = components.get(OBC.Worlds);

        const world = worlds.create(
          OBC.SimpleScene,
          OBC.OrthoPerspectiveCamera,
          OBCF.PostproductionRenderer
        );
      
        world.scene = new OBC.SimpleScene(components);
        world.renderer = new OBCF.PostproductionRenderer(components, canvasDomElement);
        world.camera = new OBC.OrthoPerspectiveCamera(components);

      
        world.renderer.postproduction.enabled = false;
        world.renderer.three.shadowMap.enabled = true; // Enable Shadow        
        
        // // The Viewcube is disabled for the moment
        // const viewCube = document.createElement("bim-view-cube");
        // viewCube.id = "view-helper";
        // viewCube.camera = world.camera.three;
        // canvasParent.append(viewCube);

        // world.camera.controls.addEventListener("update", () =>
        //     viewCube.updateOrientation(),
        // );

        components.init();     
        world.scene.setup();
       
        const scene = world.scene.three;
        scene.traverse((object) => {
            if(object.isAmbientLight){
                object.color.copy(new THREE.Color(0xffffff))
                object.intensity = 3.0;
            }
            if (object.isDirectionalLight) {
                // Set the position of the light (this determines the direction the light is coming from)
                object.color.copy(new THREE.Color(0xffeedd));
                object.intensity = 0.65;
            }
        });

        
        const grids = components.get(OBC.Grids);
        const grid = grids.create(world);
        this.grid = grid.three;
        world.renderer.postproduction.customEffects.excludedMeshes.push(grid.three);

        const axesHelper = new THREE.AxesHelper( 100 );
        axesHelper.visible = false;
        scene.add( axesHelper );

        scene.add(this.green_roof_objects);
        scene.add(this.individual_assets);
        scene.add(this.asset_mixes);
        scene.add(this.annotations);
        scene.add(this.levels);
        scene.add(this.simulation_objects);
        // // stats for debugging
        // const stats = new Stats();
        // stats.showPanel(2);
        // stats.dom.style.right ="0px"
        // stats.dom.style.left ="auto"
        // document.body.appendChild(stats.dom)

        const pmremGenerator = new THREE.PMREMGenerator( world.renderer.three );
		pmremGenerator.compileEquirectangularShader();
        scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.04 ).texture;

        // NONE: 0;
        // ROTATE: 1;
        // TRUCK: 2; (PAN)
        // OFFSET: 4;
        // DOLLY: 8;
        // ZOOM: 16;
        world.camera.controls.mouseButtons = {left: 0, middle: 2, right: 2, wheel: 8};

        document.addEventListener( 'keydown', this.handleKeyDown);
        document.addEventListener( 'keyup', this.handleKeyUp);

        // FRAGMENT-IFC-LOADER
        this.fragments = components.get(OBC.FragmentsManager);
        this.fragmentIfcLoader = components.get(OBC.IfcLoader);

        //Calibrating the converter
        await this.fragmentIfcLoader.setup();

        // optional
        const excludedCats = [
            WEBIFC.IFCTENDONANCHOR,
            WEBIFC.IFCREINFORCINGBAR,
            WEBIFC.IFCREINFORCINGELEMENT,
            WEBIFC.IFCSPACE,
            WEBIFC.IFCBUILDINGELEMENTPROXY,
            WEBIFC.IFCFURNITURE,
            WEBIFC.IFCVEHICLE,
            WEBIFC.IFCGEOGRAPHICELEMENT,
            WEBIFC.IFCBUILDINGELEMENTPART,
            WEBIFC.IFCSANITARYTERMINAL
        ];

        for(const cat of excludedCats) {
            this.fragmentIfcLoader.settings.excludedCategories.add(cat);
        }
        //

        this.fragmentIfcLoader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
        this.fragmentIfcLoader.settings.webIfc.OPTIMIZE_PROFILES = true;

        // HIGHLIGHTER
        this.highlighter = components.get(OBCF.Highlighter);
        this.highlighter.setup({ world });   
        this.highlighter.outlinesEnabled = false;
        const highlightColor = new THREE.Color('#ea802a');
        this.highlighter.add('default', highlightColor);
        this.highlighter.enabled = false;

        this.components = components;
        this.world = world;
        this.axesHelper = axesHelper;
        this.scene = scene;
        this.custom_orbit_controls = world.camera.controls; // this is pobably not necessary anymore...
        this.savedCameraPosition = world.camera.three.position.clone();
        this.default_background = world.scene.three.background;
        this.min_gloss = world.renderer.postproduction.customEffects.minGloss;
    }

    // Loading the IFC
    async loadIfcAsFragments(file, properties = null, is_array_buffer = false) {
        if(this.ifc_model){
            this.disposeFragments();
        }
        let data, ifc_model;
        if(is_array_buffer){
            data = file;
        }
        else{
            data = await file.arrayBuffer();
        }
        const buffer = new Uint8Array(data);

        if(is_array_buffer){
            ifc_model = this.fragments.load(buffer);
        }else{
            ifc_model = await this.fragmentIfcLoader.load(buffer);
            this.fragments.load(buffer);

            const props_manager = new OBC.IfcPropertiesManager(this.components);
            const entityRef = await props_manager.getEntityRef(ifc_model, WEBIFC.IFCBUILDINGSTOREY)
            let storey_count = 1;
            for (const storey of entityRef){
                const props = await ifc_model.getProperties(storey.value)
                if(!props.Elevation || !props.Elevation.value) continue;
                let name
                if(props.Name && props.Name.value){
                    name = `${props.Name.value} (ifc)`
                }else{
                    name = `Level ${storey_count} (ifc)`
                }
                const plane = createPlaneObject(props.Elevation.value, name)
                this.registerLevels(plane)
                storey_count ++;
            }
            if(entityRef.length > 0) this.domElement.dispatchEvent(customEvents.saveCustomGeometries);            
        }
        this.scene.add(ifc_model);

        this.registerIfc(ifc_model);
        

        if(properties){
            const textDecoder = new TextDecoder("utf-8");
            const jsonString = textDecoder.decode(properties);
            const propsData = JSON.parse(jsonString);
            ifc_model.setLocalProperties(propsData);
        }
        this.ifc_model = ifc_model;
        this.setClippingEdgeStyle();
        // this.highlighter.updateHighlight();
    }

    //  Exporting the result
    // Once you have your precious fragments, you might want to save them so that you don't
    // need to open this IFC file each time your user gets into your app. Instead, the next time
    // you can load the fragments directly. Defining a function to export fragments is as easy as this:
    exportFragments() {
        if (!this.fragments.groups.size) return;
        const group = Array.from(this.fragments.groups.values())[0];
        const data = this.fragments.export(group);
        const blob = new Blob([data]);
        const fragmentFile = new File([blob], 'model.frag');
        const properties = group.getLocalProperties(); 
        const files = {};
        files["frag"] = fragmentFile;
        files["json"] =  new File([JSON.stringify(properties)], 'model.json');

        return files
    }

    disposeFragments() {
        this.fragments.dispose();
        this.ifc_model = null;
    }

    async disposeAll(){
        // This function needs to be checked if it is working properly

        document.removeEventListener( 'keydown', this.handleKeyDown);
        document.removeEventListener( 'keyup', this.handleKeyUp);
        
        this.objects3d = null;
        this.green_roof_objects = null;
        this.individual_assets = null;
        this.asset_mixes = null;
        this.annotations = null;
        this.disposeFragments();

        // Dispose openbim-components
        try {
            this.components.dispose();
        } catch (error) {
            console.log(error)
        }

        // Dispose
        clearThree(this.scene);

        // Stop the animation loop
        cancelAnimationFrame(this.animationId);

        function clearThree(obj){
            console.log(obj);
            while(obj.children.length > 0){
                clearThree(obj.children[0]);
                // obj.remove(obj.children[0]);
                obj.children[0].removeFromParent();
            }
            if(obj.geometry) obj.geometry.dispose();
            if(obj.material) obj.material.dispose();
            if(obj.texture) obj.texture.dispose();
        }
    }

    // download function
    _download(file) {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    async loadCustomGeometries(glb_file, asset_loader) {
        return new Promise((resolve, reject) => {
            this.gltfLoader.parse(
                // Data
                glb_file,
                "",
                // called when the resource is loaded
                async (gltf) => {
                    try {
                        console.log(gltf.scene);
    
                        // green roofs
                        for (let i = gltf.scene.children[0].children.length - 1; i >= 0; i--) {
                            gltf.scene.children[0].children[i].traverse((child) => {
                                if (child.isMesh && child.material) {
                                    child.material.flatShading = true;
                                    child.material.needsUpdate = true;
                                }
                            });
                            this.registerObject(gltf.scene.children[0].children[i]);
                        }
    
                        // individual assets
                        for (let i = gltf.scene.children[1].children.length - 1; i >= 0; i--) {
                            this.registerAssets(gltf.scene.children[1].children[i]);
                        }
    
                        await loadAssetsFromDatabase(this.individual_assets.children, asset_loader);
    
                        // asset mixes
                        for (let i = gltf.scene.children[2].children.length - 1; i >= 0; i--) {
                            this.registerAssetMix(gltf.scene.children[2].children[i]);
                        }
    
                        // levels
                        if (gltf.scene.children.length > 3) {
                            for (let i = gltf.scene.children[3].children.length - 1; i >= 0; i--) {
                                this.registerLevels(gltf.scene.children[3].children[i]);
                            }
                        }
    
                        // Resolve the promise when everything is loaded and processed successfully
                        resolve();
                    } catch (error) {
                        // Catch any errors that occur during processing and reject the promise
                        reject(error);
                    }
                },
                // called when loading has errors
                (error) => {
                    reject(error);
                }
            );
        });
    }
    

    /**
     * Exports custom geometries as a binary .glb file using three.js GLTFExporter.
     *
     * @returns {Promise<File|null>} A promise that resolves to the exported .glb File on success, or null on failure.
     */
    exportCustomGeometries = async () => {
        try {
            // Clone the individual_assets without their children (geometries)
            const individual_assets_shallow = this.individual_assets.clone(false);

            this.individual_assets.children.forEach(asset_wrapper => {
                // Shallow clone each child group (exclude geometries)
                const copied_asset_wrapper = asset_wrapper.clone(false);

                // Copy necessary properties
                copied_asset_wrapper.name = asset_wrapper.name;
                copied_asset_wrapper.userData = { ...asset_wrapper.userData };

                // Add the cloned group to the shallow copy
                individual_assets_shallow.add(copied_asset_wrapper);
            });

            // Define exporter options for binary .glb
            const options = {
                binary: true,      // Export as binary (.glb)
                onlyVisible: false // Include all objects, not just visible ones
            };

            // Use parseAsync to export the scene/group as a binary .glb
            const result = await this.glbExporter.parseAsync(
                [
                    this.green_roof_objects,
                    individual_assets_shallow,
                    this.asset_mixes,
                    this.levels
                ],
                options
            );

            // Ensure the result is an ArrayBuffer (since binary export is specified)
            if (!(result instanceof ArrayBuffer)) {
                console.error('Exported result is not an ArrayBuffer as expected for binary .glb export.');
                return null;
            }

            // Create a Blob from the ArrayBuffer
            const blob = new Blob([result], { type: 'application/octet-stream' });

            // Create and return a File object from the Blob
            const glbFile = new File([blob], 'custom_geometries.glb', { type: 'model/gltf-binary' });

            console.log('Export successful: custom_geometries.glb has been created.');
            return glbFile;
        } catch (error) {
            // Log the error and return null to indicate failure
            console.error('An error occurred during exporting custom geometries:', error);
            return null;
        }
    };


    async registerNewObject(object, project_id, hydraulic_type){
        const area_data = {name: this.current_green_roof_name ,area: object.userData.cg_exact_area, geometry_asset_id: 0};
        const [db_response, hydraulic_id] = await createAreaInDatabase(area_data, project_id, hydraulic_type);
        if(!db_response){
            // Error handling
            console.error("Error in registering the object in the database");
            return;
        }
        const new_green_roof = new THREE.Group();
        new_green_roof.name = db_response.id.toString();
        new_green_roof.add(object);
        new_green_roof.userData = { is_cg_roof: true, cg_db_data: db_response, cg_hydraulic_id: hydraulic_id};
        this.green_roof_objects.add(new_green_roof);
        this.domElement.dispatchEvent(customEvents.saveCustomGeometries);
    }

    registerObject(object){
        this.green_roof_objects.add(object);
    }

    registerAssets(object){
        this.individual_assets.add(object)
    }

    registerAssetMix(object){
        this.asset_mixes.add(object);
    }
    registerLevels(object){
        this.levels.add(object);
    }
    updateLevels(level_data){
        // Collect the IDs of the levels that should remain
        const levelIds = new Set(level_data.map(level_datum => level_datum.id).filter(id => id !== null));

        // Iterate through the existing levels and dispose of the ones not in levelIds
        for (const level of this.levels.children) {
            if (!levelIds.has(level.name)) {
                disposeRecursive(level);
            }
        }

        for (const level_datum of level_data) {
            let level_id;
            if(!level_datum.id){
                // Handle new levels
                const plane = createPlaneObject(level_datum.zElevation, level_datum.name)
                this.registerLevels(plane);
                level_id = plane.name;
            }else{
                // Modify existing
                const existing_level = this.levels.getObjectByName(level_datum.id);
                existing_level.userData.cg_level_name = level_datum.name;
                existing_level.position.copy(new THREE.Vector3(0,level_datum.zElevation,0));
                level_id = level_datum.id;
            }
            if(level_datum.dxfObject) this.registerDxfOnLevel(level_datum.dxfObject, level_id, level_datum.dxfScale);
            
            // Dispose of the dxfObject if it was removed by the user
            if(!level_datum.dxfObject && this.levels.getObjectByName(level_id).children.length > 0){
                disposeRecursive(this.levels.getObjectByName(level_id).children[0]);
            }
        }
    }

    registerIfc(ifc_model){
        this.objects3d.push(... ifc_model.children)
    }

    registerDxfOnLevel(dxf_group, level_id, scale){
        const level = this.levels.getObjectByName(level_id);

        if(level.children.length > 0){
            const existing_dxf = level.children[0]
            if(existing_dxf.name != dxf_group.name){
                disposeRecursive(existing_dxf);
                scaleAndCenterGroup(dxf_group, scale);
                level.add(dxf_group);
            }else{
                existing_dxf.removeFromParent();
                scaleAndCenterGroup(existing_dxf, scale);
                level.add(existing_dxf);
            }
        }else{
            scaleAndCenterGroup(dxf_group, scale);
            level.add(dxf_group);
        } 
    }

    switchToOrthoCamera(normalVector, referencePoint, distance = 10, smooth = true) {
        this.savedCameraPosition = this.world.camera.three.position.clone();
        this.world.camera.set("Plan");
        this.world.camera.projection.set("Orthographic");
        // Normalize the direction vector
        const direction = normalVector.clone().normalize();

        // Set the camera position based on the direction vector and distance
        const cameraPosition = direction.multiplyScalar(distance).add(referencePoint);
        this.savedOrthoLookAt = [cameraPosition, referencePoint];

        this.world.camera.controls.setLookAt(
            cameraPosition.x, cameraPosition.y, cameraPosition.z,
            referencePoint.x, referencePoint.y, referencePoint.z,
            smooth
        )
    }

    switchToPerspectiveCamera() {
        if(this.world.camera.projection.current !== "Perspective"){
            this.world.camera.set("Orbit");
            this.world.camera.projection.set("Perspective");
            this. world.camera.controls.setLookAt(
                this.savedCameraPosition.x, this.savedCameraPosition.y, this.savedCameraPosition.z,
                0, 0, 0,
                true
            );
        }

    }

    resetCameraView(){
        if(this.world.camera.projection.current === "Perspective"){
            this.world.camera.controls.setLookAt(
                this.savedCameraPosition.x, this.savedCameraPosition.y, this.savedCameraPosition.z,
                0, 0, 0,
                true
            );
        }
        else if(this.world.camera.projection.current === "Orthographic"){
            this.world.camera.controls.setLookAt(
                this.savedOrthoLookAt[0].x, this.savedOrthoLookAt[0].y, this.savedOrthoLookAt[0].z,
                this.savedOrthoLookAt[1].x, this.savedOrthoLookAt[1].y, this.savedOrthoLookAt[1].z,
                true
            );
        }
    }

    setTransparency(opacity) {
        if(this.ifc_model){
            this.ifc_model.traverse((child) => {
                if (child.isMesh) {
                  const material = child.material;
                  if (material) {
                    if (Array.isArray(material)) {
                      // If the material is an array, set transparency for each material
                      material.forEach((mat) => {
                        mat.opacity = opacity;
                        mat.transparent = opacity < 1.0;
                        mat.needsUpdate = true; // Ensure material updates
                      });
                    } else {
                      // Set transparency for single material
                      material.opacity = opacity;
                      material.transparent = opacity < 1.0;
                      material.needsUpdate = true; // Ensure material updates
                    }
                  }
                }
              });
        }
        this.green_roof_objects.traverse((child) => {
            if (child.isMesh && child.parent.userData.cg_type == objectTypes.standard) {
              const material = child.material;
              if (material) {
                if (Array.isArray(material)) {
                  // If the material is an array, set transparency for each material
                  material.forEach((mat) => {
                    mat.opacity = opacity;
                    mat.transparent = opacity < 1.0;
                    mat.needsUpdate = true; // Ensure material updates
                  });
                } else {
                  // Set transparency for single material
                  material.opacity = opacity;
                  material.transparent = opacity < 1.0;
                  material.needsUpdate = true; // Ensure material updates
                }
              }
            }
          });
    }

    handleKeyDown = ( event ) => {
        if ( event.code === 'ShiftRight'   ) this.keyState.shiftRight   = true;
        if ( event.code === 'ShiftLeft'    ) this.keyState.shiftLeft    = true;
        this.updateControlsMouseButtons();
    }

    handleKeyUp = ( event ) => {
        if ( event.code === 'ShiftRight'   ) this.keyState.shiftRight   = false;
        if ( event.code === 'ShiftLeft'    ) this.keyState.shiftLeft    = false;
        this.updateControlsMouseButtons();
    }

    updateControlsMouseButtons = () => {
        if ( this.keyState.shiftRight || this.keyState.shiftLeft ) {
            this.world.camera.controls.mouseButtons.left = 1;
        }
        else {
            this.world.camera.controls.mouseButtons.left = 0;
            
        }
    }

    switchToPlanView(){
        const whiteColor = new THREE.Color("white");
        const grayFill = new THREE.Color("gray");

        // 1) get bounding green roof objects and ifc-model combined
        // ifc-part
        let ifc_BoundingBox;
        if(this.ifc_model){
            const fragmentBbox = this.components.get(OBC.BoundingBoxer);
            fragmentBbox.add(this.ifc_model);
            const ifc_min = fragmentBbox._absoluteMin;
            const ifc_max = fragmentBbox._absoluteMax;
            ifc_BoundingBox = new THREE.Box3(ifc_min, ifc_max);
            fragmentBbox.reset();

            const classifier = this.components.get(OBC.Classifier);
            classifier.byModel(this.ifc_model.uuid, this.ifc_model);
            const modelItems = classifier.find({ models: [this.ifc_model.uuid] });
            classifier.setColor(modelItems, grayFill);
        }

        // green roof part
        const combinedBoundingBox = new THREE.Box3();
        const green_roof_boundingBox = new THREE.Box3().setFromObject(this.green_roof_objects);
        const individual_assets_boundingBox = new THREE.Box3().setFromObject(this.individual_assets);
        const asset_mixes_boundingBox = new THREE.Box3().setFromObject(this.asset_mixes);

        combinedBoundingBox.union(green_roof_boundingBox);
        combinedBoundingBox.union(individual_assets_boundingBox);
        combinedBoundingBox.union(asset_mixes_boundingBox);
        if (ifc_BoundingBox) combinedBoundingBox.union(ifc_BoundingBox);

        // 2) set camera view to ortho and view from top face of bbox to bottom face of bbox
        // Make sure that whole model fits in view (maybe set the frustum with a really wide angle?!)
        // Find the center of the combined bounding box
        const center = combinedBoundingBox.getCenter(new THREE.Vector3());

        // Adjust the y-coordinate to the maximum y-coordinate of the bounding box to get the top face center
        const y_offset = 2.0
        const topFaceCenter = new THREE.Vector3(center.x, combinedBoundingBox.max.y + y_offset, center.z);
        const bottomFaceCenter = new THREE.Vector3(center.x, combinedBoundingBox.min.y, center.z);
        this.switchToOrthoCamera(topFaceCenter.sub(bottomFaceCenter), topFaceCenter, y_offset, false);

        // 3) change to plan-view style: change the styles of the (ifc) elements (like thin edges, white background etc)
        this.world.renderer.postproduction.enabled = true;
        this.world.renderer.postproduction.customEffects.minGloss = 0.1;
        this.world.scene.three.background = whiteColor;
        // 4) add annotations (might be better in separate function)
        const data_helper = [];
        for (const green_roof of this.green_roof_objects.children) {
            let count = 1;
            for (const sub_roof of green_roof.children) {
                this.world.meshes.add(sub_roof.children[0]);
                sub_roof.children[0].geometry.computeBoundingSphere()
                const center  = sub_roof.children[0].geometry.boundingSphere.center;
                const area = green_roof.userData.cg_db_data.area;
                const name = `${green_roof.userData.cg_db_data.name}-${count}`;
                const type = sub_roof.userData.cg_type_details.common_name;
                const htmlElement = createStandardAnnotation(name, area, type);
                const green_roof_label = new OBCF.Mark(this.world, htmlElement);
                green_roof_label.three.position.copy(center);
                this.annotations.add(green_roof_label.three);
                count++;
                data_helper.push({name: name, type_details: sub_roof.userData.cg_type_details})
            }
        }

        const dimension_lines = this.components.get(OBCF.LengthMeasurement);
        dimension_lines.visible = true;
        return data_helper;
    }

    exitPlanView(){
        const dimension_lines = this.components.get(OBCF.LengthMeasurement);
        dimension_lines.visible = false;
        this.toggleMeasurement(false);
        this.setAnnotationVisibility(false);
        this.switchToPerspectiveCamera();
        this.world.renderer.postproduction.customEffects.minGloss = this.min_gloss;
        this.world.scene.three.background = this.default_background;
        this.world.renderer.postproduction.enabled = false;
        
        if(this.ifc_model){
            const classifier = this.components.get(OBC.Classifier);

            classifier.byModel(this.ifc_model.uuid, this.ifc_model);

            const modelItems = classifier.find({ models: [this.ifc_model.uuid] });
            classifier.resetColor(modelItems);
        }
    }

    toggleMeasurement(enabled){
        if(enabled){
            const dimensions = this.components.get(OBCF.EdgeMeasurement);
            dimensions.world = this.world;
            dimensions.enabled = true;
            dimensions.tolerance = 1;
            this.domElement.addEventListener('click', this.addDimensionAnnotation);
            this.domElement.addEventListener('dblclick', this.deleteDimensionAnnotation);
        }else{
            const dimensions = this.components.get(OBCF.EdgeMeasurement);
            dimensions.world = this.world;
            dimensions.enabled = false;
            this.domElement.removeEventListener('click', this.addDimensionAnnotation);
            this.domElement.removeEventListener('dblclick', this.deleteDimensionAnnotation);
        }
    }
    addDimensionAnnotation = () =>{
        const dimensions = this.components.get(OBCF.EdgeMeasurement);
        if (!dimensions.preview) return;
        if (!dimensions.enabled || !dimensions.preview.visible) return;

        const casters = this.components.get(OBC.Raycasters);
        const caster = casters.get(this.world);
        const result = caster.castRay();
        if (!result || !result.object) {
            return;
        }
        if (!(result.point instanceof THREE.Vector3)) {
            return;
        }

        const dims = this.components.get(OBCF.LengthMeasurement);
        dims.world = this.world;
        const start = dimensions.preview.startPoint.clone();
        const end = dimensions.preview.endPoint.clone();
        dims.color = new THREE.Color("black");

        // Calculate the direction vector of the line
        const direction = new THREE.Vector3().subVectors(end, start).normalize();

        // Find the orthogonal direction vector in the x-z plane
        const orthogonal = new THREE.Vector3(-direction.z, 0, direction.x).normalize();

        // Determine the correct side to offset based on result.point
        const toPoint = new THREE.Vector3().subVectors(result.point, start);
        const crossProduct = new THREE.Vector3().crossVectors(direction, toPoint);
        const offsetDirection = crossProduct.y > 0 ? orthogonal : orthogonal.negate();

        // Apply the offset to the start and end points
        const offsetValue = 1.0; // Adjust this value as needed
        const offset = offsetDirection.multiplyScalar(offsetValue);
        const newStart = start.clone().add(offset);
        const newEnd = end.clone().add(offset);


        // Create a dashed line material
        const dashedLineMaterial = new THREE.LineDashedMaterial({
            color: 0x000000,
            dashSize: 0.25, // Length of dashes
            gapSize: 0.15,  // Length of gaps between dashes
        });

        const dimension = new OBCF.SimpleDimensionLine(this.components, this.world, {
            start: newStart,
            end: newEnd,
            lineMaterial: dims._lineMaterial,
            endpointElement: createDimensionLineTick(),
        });
        dimension.createBoundingBox();

        // Create auxiliary lines from offset to original lines
        const dimension_aux_1 = new OBCF.SimpleDimensionLine(this.components, this.world, {
            start: start,
            end: newStart,
            lineMaterial: dashedLineMaterial,
            endpointElement: document.createElement('div'),
        });
        dimension_aux_1.label.three.element.textContent = "";
        dimension_aux_1.label.three.element.style.backgroundColor = "transparent";
        dimension_aux_1.createBoundingBox();


        const dimension_aux_2 = new OBCF.SimpleDimensionLine(this.components, this.world, {
            start: end,
            end: newEnd,
            lineMaterial: dashedLineMaterial,
            endpointElement: document.createElement('div'),
        });
        dimension_aux_2.label.three.element.textContent = "";
        dimension_aux_2.label.three.element.style.backgroundColor = "transparent";
        dimension_aux_2.createBoundingBox();

        dimension.label.three.element.style.color = "black";
        dimension.label.three.element.style.padding = "1px";
        dimension.label.three.element.style.backgroundColor = "white";
        dims.list.push(dimension);
        dims.list.push(dimension_aux_1);
        dims.list.push(dimension_aux_2);
    }

    deleteDimensionAnnotation = () =>{
        const dimensions = this.components.get(OBCF.LengthMeasurement);
        console.log(dimensions);
        if (!dimensions.world) {
            throw new Error("World is needed for Length Measurement!");
        }
        if (dimensions.list.length === 0) return;
        const boundingBoxes = dimensions.getBoundingBoxes();
    
        const casters = this.components.get(OBC.Raycasters);
        const caster = casters.get(dimensions.world);
        const intersect = caster.castRay(boundingBoxes);
    
        if (!intersect) return;
        const dimension = dimensions.list.find(
            (dim) => dim.boundingBox === intersect.object,
        );
    
        if (dimension) {
            const index = dimensions.list.indexOf(dimension);
            const dimensions_to_dispose = dimensions.list.splice(index, 3);
            for (const dim of dimensions_to_dispose) {
                dim.dispose();
            }
            // dimension.dispose();
        }
    }

    setAnnotationVisibility(value){
        this.annotations.children.forEach(label => {
            label.visible = value;
        });
        const dimension_lines = this.components.get(OBCF.LengthMeasurement);
        dimension_lines.visible = value;
    }

    async switchToLevelView(level_id){
        const whiteColor = new THREE.Color("white");
        const level = this.levels.getObjectByName(level_id);
        // Set all other levels to visible false
        this.levels.children.forEach(child => {
            if (child !== level) {
                child.visible = false;
                setLayerRecursively(child, layers.hidden);
            }
        });
        level.visible = true;
        setLayerRecursively(level, layers.visible);
        const normalVector = new THREE.Vector3(0, 1, 0);
        const referencePoint = level.position.clone().add(new THREE.Vector3(0, clipping_plane_offset, 0));

        this.world.renderer.postproduction.enabled = true;
        this.world.renderer.postproduction.customEffects.minGloss = 0.1;
        // this.world.scene.three.background = whiteColor;

        if(this.ifc_model && this.ifc_model.visible){
            const classifier = this.components.get(OBC.Classifier);
            const modelItems = classifier.find({ models: [this.ifc_model.uuid] });
            classifier.setColor(modelItems, whiteColor);

            const sections = this.components.get(OBCF.Sections);
            sections.world = this.world;
            const foundSection = sections.list.get(level_id);
            if (foundSection) {
                sections.delete(level_id)
            }
            normalVector.multiplyScalar(-1)
            const section = sections.create({
                name: level.userData.cg_level_name,
                id: level_id,
                normal: normalVector,
                point: referencePoint,
            });
            await sections.goTo(section.id);
        
            const edges = this.components.get(OBCF.ClipEdges);
            await edges.update(true);


        }
        else{
            this.switchToOrthoCamera(normalVector, referencePoint, 5, true)
        }

    }

    async setClippingEdgeStyle(){
        const classifier = this.components.get(OBC.Classifier);
        classifier.byModel(this.ifc_model.uuid, this.ifc_model);
        classifier.byEntity(this.ifc_model);

        const edges = this.components.get(OBCF.ClipEdges);
        
        const thickItems = classifier.find({
          entities: ["IFCWALLSTANDARDCASE", "IFCWALL"],
        });
        
        const thinItems = classifier.find({
          entities: ["IFCDOOR", "IFCWINDOW", "IFCPLATE", "IFCMEMBER"],
        });

        const grayFill = new THREE.MeshBasicMaterial({ color: "gray", side: 2 });
        const blackLine = new THREE.LineBasicMaterial({ color: "black" });
        const blackOutline = new THREE.MeshBasicMaterial({
          color: "black",
          opacity: 0.5,
          side: 2,
          transparent: true,
        });
        
        edges.styles.create(
          "thick",
          new Set(),
          this.world,
          blackLine,
          grayFill,
          blackOutline,
        );
        
        for (const fragID in thickItems) {
          const foundFrag = this.fragments.list.get(fragID);
          if (!foundFrag) continue;
          const { mesh } = foundFrag;
          edges.styles.list.thick.fragments[fragID] = new Set(thickItems[fragID]);
          edges.styles.list.thick.meshes.add(mesh);
        }

        edges.styles.create("thin", new Set(), this.world);

        for (const fragID in thinItems) {
          const foundFrag = this.fragments.list.get(fragID);
          if (!foundFrag) continue;
          const { mesh } = foundFrag;
          edges.styles.list.thin.fragments[fragID] = new Set(thinItems[fragID]);
          edges.styles.list.thin.meshes.add(mesh);
        }
        await edges.update(true);

    }

    async exitLevelView(){
        this.world.renderer.postproduction.customEffects.minGloss = this.min_gloss;
        this.world.renderer.postproduction.enabled = false;
        const sections = this.components.get(OBCF.Sections);
        if(sections.enabled){
            await sections.exit();
            const classifier = this.components.get(OBC.Classifier);
            const modelItems = classifier.find({ models: [this.ifc_model.uuid] });
            classifier.resetColor(modelItems);
        }
        else{
            this.switchToPerspectiveCamera();
        }
    }

    createDefaultLevel(){
        this.registerLevels(createPlaneObject(0, default_values.default_level_name, true));
    }

    async createDataForSimulation(project_id, simulation_id){


        for (const green_roof of this.green_roof_objects.children) {           
            let roof_area = 0;
            const export_sub_roofs = [];
            // get inverse transform from first child. All sub roof have same base transform
            const meshed_base_0 = getGreenRoofChildByName(green_roof.children[0],"cg_base");
            const inverse_transform = new THREE.Matrix4().fromArray(meshed_base_0.userData.transformationMatrix);
            inverse_transform.invert();

            let id_counter = -1;
            const sub_roof_ids_map_helper = [];
            
            green_roof.children.forEach(sub_roof => {
                const type_details = sub_roof.userData.cg_type_details;
                const meshed_top = getGreenRoofChildByName(sub_roof,"cg_top");

                const inner_vertex_indices = meshed_top.userData.vertices_index_info.inner;
                const nof_sub_areas = inner_vertex_indices.end_index - inner_vertex_indices.start_index;
                // const positions = meshed_top.geometry.getAttribute('position');
                // const nof_sub_areas = positions.count;

                // Gross area calculated by number of regular grid points times the area of one grid cell (in m^2)
                const gross_area = nof_sub_areas*grid_size*grid_size;
                // Calculation of a corrected area for the sub areas (grid cells) 
                const correction_factor = sub_roof.userData.cg_exact_area/gross_area;
                const effective_area = correction_factor*grid_size*grid_size;

                roof_area += sub_roof.userData.cg_exact_area; // total area of whole roof structure
                const assets = sub_roof.userData.cg_assets;

                console.log(`Gross Area: ${gross_area}`);
                console.log(`Exact Area: ${sub_roof.userData.cg_exact_area}`);
                console.log(correction_factor);
                console.log(`Effective area: ${effective_area}`);
   
                const helper_bufferAttribute = new THREE.BufferAttribute().copy(meshed_top.geometry.getAttribute('position'));

                helper_bufferAttribute.applyMatrix4(inverse_transform);

                // get z-coords of all inner vertices
                const id_counter_start = id_counter+1;
                for (let i = inner_vertex_indices.start_index; i < inner_vertex_indices.end_index; i++) {
                    const z = helper_bufferAttribute.getZ(i);
                    const x = helper_bufferAttribute.getX(i);
                    const y = helper_bufferAttribute.getY(i);
                    const depth = type_details.total_depth + (z - z_fighting_offset*2);
                    // console.log(`layers: ${type_details.total_depth}, z: ${z - z_fighting_offset*2}`);
                    
                    let close_assets = [];
                    let distance_to_assets = [];
                    for (const uuid in assets) {
                        const asset = this.individual_assets.getObjectByName(uuid);
                        const radius = asset.userData.cg_type_details.root_radius_bottom;
                        const id = asset.userData.cg_type_details.id;
                        const center = new THREE.Vector3().copy(asset.position).applyMatrix4(inverse_transform);
                        const point_is_close_to_asset = pointInCircle(x,y,center.x, center.y, radius);

                        if(point_is_close_to_asset){
                            const distance = distance2D(x,y, center.x, center.y);
                            close_assets.push(id);
                            distance_to_assets.push(distance);
                        }
                    }
                    const sub_roof_data = {
                            simulation_id: simulation_id,
                            plant_type_ids: close_assets,
                            layer_stratigraphy_id: type_details.id,
                            depth: depth,
                            area: effective_area,
                            distances_to_plant: distance_to_assets,
                            is_discretized: true
                    };
                    
                    export_sub_roofs.push(sub_roof_data);
                    id_counter++;
                };
                if(id_counter_start <= id_counter) {
                    sub_roof_ids_map_helper.push({start: id_counter_start, end: id_counter});
                }else{
                    sub_roof_ids_map_helper.push(null);
                }
            });
            
            const response_sub_area_data = await createSubAreasInDatabase({subareas: export_sub_roofs}, project_id, green_roof.userData.cg_db_data.id);
            if(!response_sub_area_data){
                // Emit error and display notification
                return;
            }

            const sub_roof_ids_map = sub_roof_ids_map_helper.map(index => 
                index === null ? null : response_sub_area_data.subarea_ids.slice(index.start, index.end + 1)
            );

            for (let i = 0; i <  green_roof.children.length; i++) {
                const sub_roof = green_roof.children[i];
                // This can be used later to get assign simulation results to specific vertices (visualization of color maps)
                if(sub_roof_ids_map[i]){
                    if(!sub_roof.userData.cg_sub_area_id_range){
                        sub_roof.userData.cg_sub_area_id_range = {};
                    }
                    sub_roof.userData.cg_sub_area_id_range[simulation_id] = {start_id: sub_roof_ids_map[i][0], end_id: sub_roof_ids_map[i][sub_roof_ids_map[i].length-1]};
                }
            }
        }

    }

    async applySimulationResults(simulation_results, sim_id, result_type, project_id){
        // Check if this.simulation_objects  contains a direct child with name sim_object_name not using getObjectByName
        this.simulation_objects.children.forEach(child => {
            child.visible = false;
        });
        const sim_object_name = `sim_${sim_id.toString()}_${result_type}`;
        let sim_object = this.simulation_objects.children.find(child => child.name === sim_object_name);
        if(sim_object){
            sim_object.visible = true;
            return this.simulation[sim_id][result_type].lut;
        }

        sim_object = deepCloneGroup(this.green_roof_objects)
        sim_object.visible = false;
        sim_object.name = sim_object_name;
        this.simulation_objects.add(sim_object);

        // Initialize global min and max values
        let globalMinValue = Infinity;
        let globalMaxValue = -Infinity;

        for (const roof_name_sim in simulation_results.greenroofs) {
            const roof_data = simulation_results.greenroofs[roof_name_sim];

            const values = roof_data[result_type];
            // get min and max value of values which is a list
            const minValue = Math.min(...values);
            const maxValue = Math.max(...values);

            if (minValue < globalMinValue) globalMinValue = minValue;
            if (maxValue > globalMaxValue) globalMaxValue = maxValue;
        }
        
        const lut = new Lut("rainbow", 512);
    
        const range = Math.abs(globalMaxValue - globalMinValue);
        let uniform_color;
        if (range < 0.0001) {
            // If globalMaxValue and globalMinValue are the same, set a default range
            const defaultMin = globalMinValue - 1;
            const defaultMax = globalMaxValue + 1;
            lut.setMin(defaultMin);
            lut.setMax(defaultMax);
            uniform_color = lut.getColor(globalMinValue); // Get color from adjusted LUT
        }
        else{
            lut.setMax( globalMaxValue );
            lut.setMin( globalMinValue );
        }

        for (const roof_name_sim in simulation_results.greenroofs) {
            const roof_params_list = simulation_results.params[roof_name_sim];
            const hydraulic_component_id = roof_params_list[0].id;
            const hydraulic_component = await getHydraulicComponent(project_id, hydraulic_component_id);
            if(!hydraulic_component) continue;
            const component_data = JSON.parse(hydraulic_component.data);
            const area_id = component_data.area_id;
            const roof_data = simulation_results.greenroofs[roof_name_sim][result_type];
            for (let i = 0; i < roof_data.length; i++) {
                const sim_value = roof_data[i];
                // get corresponding green roof object 
                const current_area = sim_object.getObjectByName(area_id.toString());
                for (const sub_area_id of roof_params_list[i].sub_area_ids) {
                    if(uniform_color){
                        changeInnerVertexColor(current_area, sub_area_id, uniform_color, sim_id);
                    }else{
                        const color = new THREE.Color();
                        color.copy( lut.getColor( sim_value ) );
                        changeInnerVertexColor(current_area, sub_area_id, color, sim_id);
                    }
                }
        
                for(const sub_roof of current_area.children){
                    const meshed_top = getGreenRoofChildByName(sub_roof, "cg_top");
                    changeBoundaryVertexColor(meshed_top);
                }
            }

        }

        if(!this.simulation){
            this.simulation = {};
        }

        if (!this.simulation[sim_id]) {
            this.simulation[sim_id] = {};
        }
        
        this.simulation[sim_id][result_type] = { lut: lut };        
        sim_object.visible = true;

        return lut;
    }

    hideSimulationResults(){
        this.simulation_objects.children.forEach(child => {
            child.visible = false;
        });
        this.simulation_objects.visible = false;
    }
    
}

class AiToolManager{
    constructor(){
        this.global_manager;
        this.project_id;
        this.climagruen_types;
        this.is_setup = false;
        this.boundCreateNewRoofOnDelete = null; // Store the bound reference
    }
    /**
    * Setup the AiToolManager.
    * @param {ModelingInterfaceManager} global_manager - The global manager instance.
    * @param {number} project_id - The id of the project.   
    * @param {Object} climagruen_types - The climagruen types object from the vuex-store with (layers, vegeatation,...).
    */
    setup(global_manager, project_id, climagruen_types){
        this.global_manager = global_manager;
        this.project_id = project_id;
        this.climagruen_types = climagruen_types;
        this.is_setup = true;
        this.created_green_roof = null;
    }

    selectCreatedObject = () => {
        // Proceed with the selection
        this.global_manager.domElement.removeEventListener(customEvents.exportComplete.type, this.selectCreatedObject); 
        if(this.created_green_roof){
            this.global_manager.selectionManager.select(this.created_green_roof);
            this.global_manager.domElement.dispatchEvent(customEvents.triggerModificationMode);
            this.created_green_roof = null;
        }
    }

    createNewRoofOnDelete = (params) => {
        console.log("Params in callback", params);
        this.functionRegistry.create_greenroof(params);
        this.global_manager.domElement.removeEventListener(customEvents.objectDeleted.type, this.boundCreateNewRoofOnDelete);
        this.global_manager.domElement.removeEventListener(customEvents.errorDuringDeletion.type, this.removeEventListener);
    }

    removeEventListener = () => {
        console.log("REMOVE EVENT LISTENER triggered");
        this.global_manager.domElement.removeEventListener(customEvents.objectDeleted.type, this.boundCreateNewRoofOnDelete);
        this.global_manager.domElement.removeEventListener(customEvents.errorDuringDeletion.type, this.removeEventListener);
    }

    functionRegistry = {
        create_greenroof: async (params) =>{
            if(!this.is_setup) throw new Error("AiToolManager is not set up yet");
            this.global_manager.domElement.dispatchEvent(customEvents.exitAllFunctions);
            const plane_geometry = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
            const plane = new PlaneHelperCustom( plane_geometry, 1, 0xfff8dc );
            plane.updateMatrixWorld(true);
            const width = params.width;
            const length = params.length;
            const layer_id = params.substrate_type_id;
            const vertices = [
                -width/2, 0, -length/2, // lower left corner
                width/2, 0, -length/2, // lower right corner
                width/2, 0, length/2, // upper right corner
                -width/2, 0, length/2, // upper left corner
                -width/2, 0, -length/2 // closing: lower left corner again
            ];
            const response_layer = await axios.get(api_endpoints.layers_id(layer_id),{ headers: { 'Authorization': `Bearer ${window.localStorage.getItem('climagruen_token')}` }})
            if(!response_layer.data){
                console.error("Error in fetching the layer data");
                throw new Error("Error in fetching the layer data");
            }
            const green_roof = createGreenRoofObject(plane, vertices, response_layer.data);
            const greenroofHydraulicType = this.climagruen_types.hydraulic_components.find(type => type.description === "greenroof");
            this.global_manager.current_green_roof_name = `AI-Generated Green Roof ${params.roof_id}`;
            green_roof.userData.cg_ai_roof_id = params.roof_id;
            await this.global_manager.registerNewObject(green_roof, this.project_id, greenroofHydraulicType);

            // Since the registration triggers an automatic save of the 3D geometries we need to wait before we select the object, otherwise it will be saved with the highlight color...
            // Listen for the exportComplete event
            this.created_green_roof = green_roof;
            this.global_manager.domElement.addEventListener(customEvents.exportComplete.type, this.selectCreatedObject);            

        },
        modify_greenroof: async (params) =>{
            if(!this.is_setup) throw new Error("AiToolManager is not set up yet");
            // // modify = to delete and create new one
            this.boundCreateNewRoofOnDelete = this.createNewRoofOnDelete.bind(this, params); // Bind once and store the reference
            this.global_manager.domElement.addEventListener(customEvents.objectDeleted.type, this.boundCreateNewRoofOnDelete);
            this.global_manager.domElement.addEventListener(customEvents.errorDuringDeletion.type, this.removeEventListener);
            this.global_manager.domElement.dispatchEvent(customEvents.deleteObject);
            // {'roof_id': 6, 'length': 10.0, 'width': 10.0, 'total_depth': 0.5, 'substrate_type_id': 8, 'substrate_type_name': 'Intensives Dachbegrünungssystem'}
        }
    }
}


class PolylineDrawer {
    /**
     * Create a PolylineDrawer.
     * @param {ModelingInterfaceManager} global_manager - The global manager instance.
     */
    constructor(global_manager) {
        this.global_manager = global_manager;
        this.domElement = global_manager.domElement;
        this.scene = global_manager.scene;
        this.camera = global_manager.world.camera;
        this.highlighter = global_manager.highlighter;
        this.components = global_manager.components;
        this.raycaster = new THREE.Raycaster();
        this.mouse = new Mouse(this.domElement);

        this.controls = global_manager.custom_orbit_controls;
        this.lastSelection;
        this.current_snapping_point;
        this.current_ray_point;
        this.current_drawing_plane = null;
        this.fixed_drawing_plane = null;
        this.line_started = false;
        this.polyline_start;
        this.last_point;
        this.settings;
        this.panel;
        this.currentLine = null;
        this.points = [];
        this.current_sphere = null;
        this.sphere_helpers = [];
        this.line_ended = false;
        this.selectionManager = global_manager.selectionManager;
        this.actionStack = [];
        this.snapping_enabled;
        this.is_green_roof = false;
        this.existing_green_roof_object = null;
        this.face_pick_mode = false;
        this.y_offset = 0.0;
        this.current_level_id;
    }


    undo() {
        if (this.actionStack.length === 0) {
            console.log("No actions to undo");
            return;
        }

        const lastAction = this.actionStack.pop();
        lastAction.undo();
    }

    startDrawing(){
        console.log("Drawing started");
        this.snapping_enabled = true;
        this.face_pick_mode = false;
        this.domElement.addEventListener("pointermove", this.highlightDrawingAssistance);
        this.domElement.addEventListener("click", this.selectPlaneCallback);
    }

    endDrawing(){
        console.log("Drawing ended");
        this.resetDrawingInterface();
        this.global_manager.switchToPerspectiveCamera();

        this.domElement.removeEventListener("click", this.createLineCallback);
        this.domElement.removeEventListener("click", this.selectPlaneCallback);
        this.domElement.removeEventListener("pointermove", this.highlightDrawingAssistance);
    }

    startFacePicking(){
        this.face_pick_mode = true;
        this.domElement.addEventListener("pointermove", this.highlightDrawingAssistance);
        this.domElement.addEventListener("click", this.selectHeight);
    }

    endFacePicking(){
        this.face_pick_mode = false;
        this.resetDrawingInterface();
        this.global_manager.switchToPerspectiveCamera();
        this.domElement.removeEventListener("pointermove", this.highlightDrawingAssistance);
        this.domElement.removeEventListener("click", this.selectHeight);
    }

    startDrawingOnLevels(){
        this.resetDrawingInterface();
        this.domElement.dispatchEvent(customEvents.planeSelected);
        this.global_manager.switchToLevelView(this.current_level_id);
        // set drawing plane to the selected level
        const level = this.global_manager.levels.getObjectByName(this.current_level_id)
        const plane_constant = -1.0*level.position.y;
        console.log("Plane constant", plane_constant);
        // start drawing as usual
        const plane_geometry = new THREE.Plane(new THREE.Vector3(0,1,0), plane_constant);
        const new_plane = new PlaneHelperCustom( plane_geometry, 10000, 0xfff8dc );
        new_plane.userData.plane_constant = plane_constant;
        new_plane.position.copy(new THREE.Vector3(0,level.position.y,0));
        new_plane.updateMatrixWorld(true);

        this.fixed_drawing_plane = new_plane;

        this.domElement.addEventListener("pointermove", this.levelDrawingAssistance);
        this.domElement.addEventListener("click", this.createLineCallback);
    }
    
    async endDrawingOnLevels(){
        await this.global_manager.exitLevelView();
        this.resetDrawingInterface();
        this.global_manager.switchToPerspectiveCamera();
        this.domElement.removeEventListener("click", this.createLineCallback);
        this.domElement.removeEventListener("pointermove", this.levelDrawingAssistance);
    }

    exchangeEventListeners(new_callback, old_callback){
        console.log("events changed")
        this.domElement.removeEventListener("click", old_callback);
        this.domElement.addEventListener("click", new_callback);
    }

    resetDrawingInterface(){
        this.y_offset = 0.0;
        this.is_green_roof = false;
        this.existing_green_roof_object = null;
        this.actionStack = [];
        this.highlighter.clear();
        this.selectionManager.reset();
        // this.global_manager.setTransparency(1.0);
        if (this.currentLine){
            disposeObject(this.currentLine);
        }
        this.line_started = false;
        this.points = [];
        this.currentLine = null;

        if (this.current_sphere){
            disposeObject(this.current_sphere);
            this.current_sphere = null;
        }

        this.sphere_helpers.forEach(sphere => {
            disposeObject(sphere);
        });
        this.sphere_helpers = [];

        if (this.current_drawing_plane){
            this.current_drawing_plane.dispose();
            this.current_drawing_plane.removeFromParent();
            this.current_drawing_plane = null;
        }
        if (this.fixed_drawing_plane){
            this.fixed_drawing_plane.dispose();
            this.fixed_drawing_plane.removeFromParent();
            this.fixed_drawing_plane = null
        }
    }


    createLine() {
        // NOTE that this function does not extend the line with another line segment but every time a
        // new vertex is added the complete line is created newly.
        // This is why the line from before has to be removed...
        if (this.currentLine){
            disposeObject(this.currentLine);
        }


        const material = new MeshLineMaterial({color: new THREE.Color(colorPalette.polygon_line),  lineWidth: 0.05});
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( this.points.flat(), 3 ) );
        const mesh_line = new MeshLine();
        mesh_line.setGeometry(geometry);
        this.currentLine = new THREE.Mesh(mesh_line, material);
        this.currentLine.renderOrder = 1;
        this.currentLine.material.depthTest = false;
        this.currentLine.material.depthWrite = false;
        this.scene.add(this.currentLine);

        // if (this.line_ended){
        //     this.domElement.dispatchEvent(customEvents.polylineCompleted);
        // }
        if(this.points.length > 2){
            this.domElement.dispatchEvent(customEvents.polylineCompleted);
        }
    }

    // CALLBACKS FOR EVENTLISTERNERS
    selectPlaneCallback = () =>{
        if(this.global_manager.keyState.shiftRight || this.global_manager.keyState.shiftLeft || !this.current_drawing_plane) return; // This ensures that this is not triggered if the user currently rotates the camera
        if(!this.is_green_roof){
            this.existing_green_roof_object = null;
        }else{
            this.domElement.dispatchEvent(customEvents.drawingOnExistingObject);
        }
        const plane_geometry = new THREE.Plane(this.current_drawing_plane.plane.normal, this.current_drawing_plane.plane.constant);
        const new_plane = new PlaneHelperCustom( plane_geometry, 50, 0xfff8dc );
        new_plane.position.copy(this.current_ray_point);
        const save_real_plane_constant = -1.0*(this.current_drawing_plane.plane.normal.x*new_plane.position.x + 
                                            this.current_drawing_plane.plane.normal.y*new_plane.position.y + 
                                            this.current_drawing_plane.plane.normal.z*new_plane.position.z);
        new_plane.userData.plane_constant = save_real_plane_constant;
        this.fixed_drawing_plane = new_plane;
        // this.fixed_drawing_plane = new THREE.PlaneHelper( plane_geometry, 50, 0xfff8dc );
        this.current_drawing_plane.dispose();
        this.current_drawing_plane.removeFromParent();
        this.current_drawing_plane = null;
        this.scene.add(this.fixed_drawing_plane);
        this.exchangeEventListeners( this.createLineCallback , this.selectPlaneCallback);

        this.actionStack.push(new Action(() => {
            this.resetDrawingInterface();
            this.exchangeEventListeners( this.selectPlaneCallback, this.createLineCallback);
            this.domElement.dispatchEvent(customEvents.drawingFreely);
            // this.global_manager.switchToPerspectiveCamera();
        }));

        // this.global_manager.switchToOrthoCamera(plane_geometry.normal, this.current_ray_point);
        // this.global_manager.setTransparency(0.2);
        this.domElement.dispatchEvent(customEvents.planeSelected);
    }

    createLineCallback = () => {
        if(this.global_manager.keyState.shiftRight || this.global_manager.keyState.shiftLeft) return; // This ensures that this is not triggered if the user currently rotates the camera
        if (this.current_snapping_point && !this.line_started) {
            console.log('Line started');
            this.domElement.dispatchEvent(customEvents.polylineStarted);

            this.polyline_start = new THREE.Vector3(this.current_snapping_point.x, this.current_snapping_point.y, this.current_snapping_point.z);
            this.points.push([this.polyline_start.x, this.polyline_start.y, this.polyline_start.z]);
            const polygon_vertex = this.current_sphere.clone()
            changeSphereAppearance(polygon_vertex);
            polygon_vertex.name = 'helper_sphere';
            this.scene.add(polygon_vertex);
            this.sphere_helpers.push(polygon_vertex);
            this.line_started = true;

            this.actionStack.push(new Action(() => {
                this.points.pop();
                disposeObject(polygon_vertex);
                this.sphere_helpers.pop();
                this.line_started = false;
                this.domElement.dispatchEvent(customEvents.planeSelected);
            }));
        }
        else if (this.current_snapping_point && this.line_started && this.points.length > 0) {
            const polyline_end_candidate = new THREE.Vector3(this.current_snapping_point.x, this.current_snapping_point.y, this.current_snapping_point.z);
            const polygon_vertex = this.current_sphere.clone()
            changeSphereAppearance(polygon_vertex);
            polygon_vertex.name = 'helper_sphere';
            this.scene.add(polygon_vertex);
            this.sphere_helpers.push(polygon_vertex);
            const closing_threshold = this.snapping_enabled ?  0.01 : 0.1;
            if(polyline_end_candidate.distanceTo( this.polyline_start ) < closing_threshold){
                console.log('End-Point');
                this.points.push([this.polyline_start.x, this.polyline_start.y, this.polyline_start.z]);
                this.line_ended = true;
                this.line_started = false;
            }
            else{
                console.log('Intermediate-Point');
                this.line_ended = false;
                this.points.push([polyline_end_candidate.x, polyline_end_candidate.y, polyline_end_candidate.z]);
            }
            this.createLine();

            this.actionStack.push(new Action(() => {
                this.line_ended = false;
                this.points.pop();
                disposeObject(polygon_vertex);
                this.sphere_helpers.pop();
                this.createLine();
            }));
        }
    }

    highlightDrawingAssistance = async () => {
        // 1st ray caster for all 3d objects

        const mouse_position = this.mouse.position;
        this.raycaster.setFromCamera(mouse_position, this.camera.three);
        const intersects = this.raycaster.intersectObjects(this.global_manager.objects3d);
        const result = intersects.length > 0 ? intersects[0] : null;

        let plane_transformation_matrix;
        let face_normal;
        let found_level;
        let found_background;

        const reset = () =>{
            this.highlighter.enabled = false;
            this.highlighter.clear();
            this.selectionManager.clearSelection();
            if(this.current_drawing_plane){
                this.current_drawing_plane.dispose();
                this.current_drawing_plane.removeFromParent();
                this.current_drawing_plane = null;
            }
            if (this.current_sphere){
                disposeObject(this.current_sphere);
                this.current_sphere = null;
            }
            this.current_snapping_point = null;
            this.current_ray_point = null;
            this.is_green_roof = false;
        }
        if(!result){            
            if(this.fixed_drawing_plane){
                // Intersect the ray with the plane
                const intersectionPoint = new THREE.Vector3();
                const projection_plane = new THREE.Plane(this.fixed_drawing_plane.plane.normal, this.fixed_drawing_plane.userData.plane_constant)
                this.raycaster.ray.intersectPlane(projection_plane, intersectionPoint);
                if (intersectionPoint) {
                    reset();
                    this.current_ray_point, this.current_snapping_point = intersectionPoint;
                    this.current_sphere = createSphere(this.current_snapping_point);
                    this.current_sphere.name = 'snapping_point';
                    this.scene.add( this.current_sphere );
                    return; 
                }
                else{
                    reset();
                    return;
                }
            }else{
                // Intersect the ray with the x-z plane
                const intersectionPoint = new THREE.Vector3();
                const plane_normal = new THREE.Vector3(0,1,0);
                const xz_plane = new THREE.Plane(plane_normal, 0)
                this.raycaster.ray.intersectPlane(xz_plane, intersectionPoint);
                if (intersectionPoint) {
                    reset();
                    plane_transformation_matrix = new THREE.Matrix4();
                    face_normal = plane_normal;
                    this.current_ray_point = intersectionPoint;
                }
                else{
                    reset();
                    return;
                }

            }
        }
        else if(result.object instanceof bimFragment.FragmentMesh){
            this.highlighter.enabled = true;
            this.selectionManager.clearSelection();
            await this.highlighter.highlight('default', true); // Single selection = true
            plane_transformation_matrix = new THREE.Matrix4().fromArray(result.object.instanceMatrix.array);
            face_normal = result.face.normal;
            this.current_ray_point = result.point;
            this.is_green_roof = false;
        }
        else if (result.object.parent && result.object.parent.userData.cg_type == objectTypes.standard){
            this.highlighter.clear();
            this.selectionManager.highlightSelection(result.object.parent);

            face_normal = new THREE.Vector3(0,0,1); // Default normal vector of three.js plane

            if(this.fixed_drawing_plane){
                plane_transformation_matrix = new THREE.Matrix4();
                this.current_ray_point = result.point;
            }else{
                const base_object = getGreenRoofChildByName(result.object.parent, 'cg_base')
                plane_transformation_matrix = new THREE.Matrix4().fromArray(base_object.userData.transformationMatrix);
                this.current_ray_point = new THREE.Vector3().applyMatrix4(plane_transformation_matrix);
                this.existing_green_roof_object = result.object.parent;
            }
            this.is_green_roof = true;
        }
        else{

            found_level = findAncestorWithProperty(result.object, 'cg_level_name');
            if(found_level){
                this.highlighter.clear();

                plane_transformation_matrix = new THREE.Matrix4();
                face_normal = new THREE.Vector3(0,1,0);
                this.current_ray_point = result.point;
                if(found_level.children.length > 0){
                    const scale = found_level.children[0].scale;
                    found_background = found_level.children[0].children[found_level.children[0].children.length-1];
                    this.raycaster.params.Line.threshold = raycaster_line_threshold/scale.x;
                    this.selectionManager.highlightSelection(found_background);
                }else{
                    this.selectionManager.highlightSelection(found_level);
                }

            }else{
                reset();
                return;
            }
        }
        
        if(!this.fixed_drawing_plane){
            if(this.current_drawing_plane){
                this.current_drawing_plane.dispose();
                this.current_drawing_plane.removeFromParent();
            }

            let plane_normal;
            if(this.face_pick_mode){
                plane_normal = new THREE.Vector3(0,1,0);
            }
            else{
                const rot_matrix_temp = new THREE.Matrix3();
                rot_matrix_temp.setFromMatrix4(plane_transformation_matrix);
                plane_normal = new THREE.Vector3();
                plane_normal.copy(face_normal);
                plane_normal.applyMatrix3(rot_matrix_temp);
                plane_normal.normalize();
            }



            // const plane_constant = -1 * (plane_normal.x*this.current_ray_point.x + plane_normal.y*this.current_ray_point.y + plane_normal.z*this.current_ray_point.z);
            const plane_constant = 0.0;
            const plane_geometry = new THREE.Plane(plane_normal, plane_constant);
            const plane_helper = new PlaneHelperCustom(plane_geometry, 50, 0xfff8dc);
            plane_helper.position.copy(this.current_ray_point);
            this.current_drawing_plane = plane_helper;
            this.current_drawing_plane.name = 'drawing_plane';
            this.scene.add( this.current_drawing_plane );

        }else{
            if (this.current_sphere){
                disposeObject(this.current_sphere);
            }
            if(this.snapping_enabled){
                if(result.object.isLine){
                    // Find the closest line to the mouse in 2D screen space
                    let minScreenDistance = Infinity;
                    let closestLine = result.object;

                    const raycast_helper = new THREE.Raycaster();
                    raycast_helper.setFromCamera(this.mouse.position, this.camera.three);
                    const intersect_background = raycast_helper.intersectObject(found_background);
                    if (intersect_background.length > 0){
                        for (let i = 0; i < intersects.length; i++) {
                            const intersect = intersects[i];
    
                            // Check if the intersected object is a line
                            if (intersect.object.isLine) {    
                                const screenDistance = intersect.point.distanceToSquared(intersect_background[0].point);
                                if (screenDistance < minScreenDistance) {
                                    minScreenDistance = screenDistance;
                                    closestLine = intersect.object;  // Store the line object itself
                                    this.current_ray_point = intersect.point;  // Store the intersection point
                                }
                            }
                        }
                    }

                    const positions = closestLine.geometry.attributes.position
                    let distance_sq = Infinity;
                    let saved_vertex;
                    for (let index = 0; index < positions.count; index++) {
                        const vertex_pos = new THREE.Vector3().fromBufferAttribute(positions, index);
                        const vertex_world = closestLine.localToWorld(vertex_pos);
                        const distance_sq_helper = vertex_world.distanceToSquared(this.current_ray_point);
                        if(distance_sq_helper < distance_sq){
                            distance_sq = distance_sq_helper;
                            saved_vertex = vertex_world;
                        }
                    }

                    if(distance_sq < snapping_threshold_line){
                        this.current_snapping_point = saved_vertex;
                    }else{
                        this.current_snapping_point = this.current_ray_point;
                    }

                }
                else{
                    const vA = new THREE.Vector3();
                    const vB = new THREE.Vector3();
                    const vC = new THREE.Vector3();
                    const face = result.face;
                    const selected_geometry = result.object.geometry;
                    const position = selected_geometry.attributes.position;

                    vA.fromBufferAttribute( position, face.a );
                    vB.fromBufferAttribute( position, face.b );
                    vC.fromBufferAttribute( position, face.c );

                    const projection_plane = new THREE.Plane(this.fixed_drawing_plane.plane.normal, this.fixed_drawing_plane.userData.plane_constant)
                    const projected_ray_point = new THREE.Vector3();
                    vA.applyMatrix4(plane_transformation_matrix);
                    vB.applyMatrix4(plane_transformation_matrix);
                    vC.applyMatrix4(plane_transformation_matrix);

                    const projected_vA = new THREE.Vector3();
                    projection_plane.projectPoint(vA, projected_vA)
                    const projected_vB = new THREE.Vector3();
                    projection_plane.projectPoint(vB, projected_vB)
                    const projected_vC = new THREE.Vector3();
                    projection_plane.projectPoint(vC, projected_vC)

                    projection_plane.projectPoint(this.current_ray_point, projected_ray_point);

                    const distances = [
                        { distance: projected_ray_point.distanceToSquared(projected_vA), vertex: projected_vA },
                        { distance: projected_ray_point.distanceToSquared(projected_vB), vertex: projected_vB },
                        { distance: projected_ray_point.distanceToSquared(projected_vC), vertex: projected_vC }
                    ];
                                
                    // Find the minimum distance
                    const minDistance = Math.min(...distances.map(d => d.distance));
                    const closestVertex = distances.find(d => d.distance === minDistance);
                    
                    if (minDistance < snapping_threshold_face) {
                        this.current_snapping_point = closestVertex.vertex;
                    } else {
                        this.current_snapping_point = this.current_ray_point;
                    }
                
                }
            }else{
                if(result.object.isLine){
                    // Find the closest line to the mouse in 2D screen space
                    let minScreenDistance = Infinity;
                    this.current_snapping_point = this.current_ray_point;

                    const raycast_helper = new THREE.Raycaster();
                    raycast_helper.setFromCamera(this.mouse.position, this.camera.three);
                    const intersect_background = raycast_helper.intersectObject(found_background);
                    if (intersect_background.length > 0){
                        for (let i = 0; i < intersects.length; i++) {
                            const intersect = intersects[i];
    
                            // Check if the intersected object is a line
                            if (intersect.object.isLine) {    
                                const screenDistance = intersect.point.distanceToSquared(intersect_background[0].point);
                                if (screenDistance < minScreenDistance) {
                                    minScreenDistance = screenDistance;
                                    this.current_ray_point, this.current_snapping_point = intersect.point;  // Store the intersection point
                                }
                            }
                        }
                    }
                }else{
                    const projection_plane = new THREE.Plane(this.fixed_drawing_plane.plane.normal, this.fixed_drawing_plane.userData.plane_constant)
                    const projected_ray_point = new THREE.Vector3();
                    projection_plane.projectPoint(this.current_ray_point, projected_ray_point);
                    this.current_snapping_point = projected_ray_point;
                }
            }
            this.current_sphere =  createSphere(this.current_snapping_point);
            this.current_sphere.name = 'snapping_point';
            this.scene.add( this.current_sphere );

        }
        this.highlighter.enabled = false;

    }

    levelDrawingAssistance = () => {
        if (this.current_sphere){
            disposeObject(this.current_sphere);
        }
        const mouse_position = this.mouse.position;
        this.raycaster.setFromCamera(mouse_position, this.camera.three);
        const intersects = this.raycaster.intersectObjects(this.global_manager.objects3d);
        const result = intersects.length > 0 ? intersects[0] : null;
        let plane_transformation_matrix;
        let found_level;
        let found_background;
        if(!result){

            // Intersect the ray with the plane
            const intersectionPoint = new THREE.Vector3();
            const projection_plane = new THREE.Plane(this.fixed_drawing_plane.plane.normal, this.fixed_drawing_plane.plane.constant);
            this.raycaster.ray.intersectPlane(projection_plane, intersectionPoint);
            if (intersectionPoint) {
                this.current_ray_point, this.current_snapping_point = intersectionPoint;
                this.current_sphere = createSphere(this.current_snapping_point);
                this.current_sphere.name = 'snapping_point';
                this.scene.add( this.current_sphere );
            }
            return; 

        }
        else if(result.object instanceof bimFragment.FragmentMesh){
            plane_transformation_matrix = new THREE.Matrix4().fromArray(result.object.instanceMatrix.array);
            this.current_ray_point = result.point;
            // this.is_green_roof = false;
        }
        else if (result.object.parent && result.object.parent.userData.cg_type == objectTypes.standard){    
            plane_transformation_matrix = new THREE.Matrix4();
            this.current_ray_point = result.point;
            // this.is_green_roof = true;
        }
        else{
            found_level = findAncestorWithProperty(result.object, 'cg_level_name');
            if(found_level && found_level.children.length > 0){
                const scale = found_level.children[0].scale;
                found_background = found_level.children[0].children[found_level.children[0].children.length-1];
                this.raycaster.params.Line.threshold = raycaster_line_threshold/scale.x;
                plane_transformation_matrix = new THREE.Matrix4();
                this.current_ray_point = result.point;

            }else{
                return;
            }
        }

        if(this.snapping_enabled){
            if(result.object.isLine){
                // Find the closest line to the mouse in 2D screen space
                let minScreenDistance = Infinity;
                let closestLine = result.object;

                const raycast_helper = new THREE.Raycaster();
                raycast_helper.setFromCamera(this.mouse.position, this.camera.three);
                const intersect_background = raycast_helper.intersectObject(found_background);
                if (intersect_background.length > 0){
                    for (let i = 0; i < intersects.length; i++) {
                        const intersect = intersects[i];

                        // Check if the intersected object is a line
                        if (intersect.object.isLine) {    
                            const screenDistance = intersect.point.distanceToSquared(intersect_background[0].point);
                            if (screenDistance < minScreenDistance) {
                                minScreenDistance = screenDistance;
                                closestLine = intersect.object;  // Store the line object itself
                                this.current_ray_point = intersect.point;  // Store the intersection point
                            }
                        }
                    }
                }

                const positions = closestLine.geometry.attributes.position
                let distance_sq = Infinity;
                let saved_vertex;
                for (let index = 0; index < positions.count; index++) {
                    const vertex_pos = new THREE.Vector3().fromBufferAttribute(positions, index);
                    const vertex_world = closestLine.localToWorld(vertex_pos);
                    const distance_sq_helper = vertex_world.distanceToSquared(this.current_ray_point);
                    if(distance_sq_helper < distance_sq){
                        distance_sq = distance_sq_helper;
                        saved_vertex = vertex_world;
                    }
                }

                if(distance_sq < snapping_threshold_line){
                    this.current_snapping_point = saved_vertex;
                }else{
                    this.current_snapping_point = this.current_ray_point;
                }

            }else{
                const vA = new THREE.Vector3();
                const vB = new THREE.Vector3();
                const vC = new THREE.Vector3();
                const face = result.face;
                const selected_geometry = result.object.geometry;
                const position = selected_geometry.attributes.position;
    
                vA.fromBufferAttribute( position, face.a );
                vB.fromBufferAttribute( position, face.b );
                vC.fromBufferAttribute( position, face.c );
    
                const projection_plane = new THREE.Plane(this.fixed_drawing_plane.plane.normal, this.fixed_drawing_plane.userData.plane_constant)
                const projected_ray_point = new THREE.Vector3();
                vA.applyMatrix4(plane_transformation_matrix);
                vB.applyMatrix4(plane_transformation_matrix);
                vC.applyMatrix4(plane_transformation_matrix);
    
                const projected_vA = new THREE.Vector3();
                projection_plane.projectPoint(vA, projected_vA)
                const projected_vB = new THREE.Vector3();
                projection_plane.projectPoint(vB, projected_vB)
                const projected_vC = new THREE.Vector3();
                projection_plane.projectPoint(vC, projected_vC)
    
                projection_plane.projectPoint(this.current_ray_point, projected_ray_point);
    
                const distances = [
                    { distance: projected_ray_point.distanceToSquared(projected_vA), vertex: projected_vA },
                    { distance: projected_ray_point.distanceToSquared(projected_vB), vertex: projected_vB },
                    { distance: projected_ray_point.distanceToSquared(projected_vC), vertex: projected_vC }
                ];
                            
                // Find the minimum distance
                const minDistance = Math.min(...distances.map(d => d.distance));
                const closestVertex = distances.find(d => d.distance === minDistance);
                
                if (minDistance < snapping_threshold_face) {
                    this.current_snapping_point = closestVertex.vertex;
                } else {
                    this.current_snapping_point = this.current_ray_point;
                }
            }


        }else{
            if(result.object.isLine){
                // Find the closest line to the mouse in 2D screen space
                let minScreenDistance = Infinity;
                this.current_snapping_point = this.current_ray_point;

                const raycast_helper = new THREE.Raycaster();
                raycast_helper.setFromCamera(this.mouse.position, this.camera.three);
                const intersect_background = raycast_helper.intersectObject(found_background);
                if (intersect_background.length > 0){
                    for (let i = 0; i < intersects.length; i++) {
                        const intersect = intersects[i];

                        // Check if the intersected object is a line
                        if (intersect.object.isLine) {    
                            const screenDistance = intersect.point.distanceToSquared(intersect_background[0].point);
                            if (screenDistance < minScreenDistance) {
                                minScreenDistance = screenDistance;
                                this.current_ray_point, this.current_snapping_point = intersect.point;  // Store the intersection point
                            }
                        }
                    }
                }
            }else{
                const projection_plane = new THREE.Plane(this.fixed_drawing_plane.plane.normal, this.fixed_drawing_plane.userData.plane_constant)
                const projected_ray_point = new THREE.Vector3();
                projection_plane.projectPoint(this.current_ray_point, projected_ray_point);
                this.current_snapping_point = projected_ray_point;
            }
        }
        this.current_sphere = createSphere(this.current_snapping_point);
        this.current_sphere.name = 'snapping_point';
        this.scene.add( this.current_sphere );
    }

    selectHeight = () => {
        if(this.current_ray_point && this.current_drawing_plane){
            this.level_height = this.current_ray_point.y;
            this.domElement.dispatchEvent(customEvents.levelHeightSelected);
        }
    }

    closePolyline = () => {
        if(!this.line_ended){
            this.points.push([this.polyline_start.x, this.polyline_start.y, this.polyline_start.z]);
            this.line_ended = true;
            this.line_started = false;           
        }
        this.exchangeEventListeners( this.selectPlaneCallback, this.createLineCallback);
    }
}

class TerrainModeler{
    /**
     * Create a TerrainModeler.
     * @param {ModelingInterfaceManager} global_manager - The global manager instance.
     * @param {THREE.Group} green_roof_object - The green roof object which top face should be elevated.
     */
    constructor(global_manager, green_roof_object) {
        this.components = global_manager.components;
        this.domElement = global_manager.domElement;
        this.controls = global_manager.custom_orbit_controls;
        this.originalAnimationCallback = this.components.update;
        this.scene = global_manager.scene;
        this.individual_assets = global_manager.individual_assets;
        this.green_roof_object = green_roof_object;
        this.terrain_plane = getGreenRoofChildByName(green_roof_object, "cg_top");
        this.terrain_plane.userData.has_terrain = true;
        this.vertex_asset_map = createReverseLookupAssets(green_roof_object.userData.cg_assets, this.terrain_plane.geometry);
        this.current_sphere;
        this.current_snapping_point;
        this.initial_terrain_plane_vertices;
        this.fixed_sphere;
        this.current_vertex_index;
        this.raycaster = new THREE.Raycaster();
        this.raycaster.layers.set(0);
        this.mouse = new Mouse(this.domElement);
        this.camera = global_manager.world.camera.three;

        this.transformControl = new TransformControls(this.camera, this.domElement);
        this.transformControl.showX = false; // Hide X axis controls
        this.transformControl.showZ = false; // Hide Z axis controls
        this.transformControl.addEventListener( 'dragging-changed', this.transformControlCallback);

        this.sigma = default_values.sigma;

        this.actionStack = [];
    }

    startModeling(){
        // Add event listeners
        this.domElement.addEventListener("pointermove", this.showSnappingPointsCallback);
        this.domElement.addEventListener("click", this.selectPointForElevation);
        this.domElement.addEventListener(customEvents.undoTerrain.type, this.undo);
        document.addEventListener("keydown", this.escapeModeling);
    }

    endModeling(){
        // Remove event listeners
        this.actionStack = [];
        this.domElement.removeEventListener("pointermove", this.showSnappingPointsCallback);
        this.domElement.removeEventListener("click", this.selectPointForElevation);
        this.domElement.removeEventListener(customEvents.terrainPointConfirmed.type, this.fixPeakPosition);
        this.domElement.removeEventListener(customEvents.undoTerrain.type, this.undo);
        document.removeEventListener("keydown", this.fixPeakPosition);
        document.removeEventListener("keydown", this.escapeModeling);
        this.resetSceneObjects();
        this.transformControl.dispose();

    }

    saveCurrentState() {
        const currentVertices = this.terrain_plane.geometry.attributes.position.array.slice();
        const current_asset_state = this.save_current_asset_state();
        const currentState = {
            vertices: currentVertices,
            transformControlPosition: this.transformControl.worldPosition.clone(),
            asset_states: current_asset_state
        };
        this.actionStack.push(currentState);
    }

    save_current_asset_state(){
        const current_asset_state = [];
        for (const asset_uuid in this.green_roof_object.userData.cg_assets) {
            const asset_position = new THREE.Vector3().copy(this.individual_assets.getObjectByName(asset_uuid).position);
            current_asset_state.push({name: asset_uuid, position: asset_position});
        }
        return current_asset_state
    }

    undo = () => {
        if (this.actionStack.length > 0) {
            const previousState = this.actionStack.pop();
            this.restoreState(previousState);
        }
    }

    restoreState(state) {
        const plane_vertices_positions = this.terrain_plane.geometry.attributes.position;
        for (let i = 0; i < state.vertices.length; i++) {
            plane_vertices_positions.array[i] = state.vertices[i];
        }
        plane_vertices_positions.needsUpdate = true;

        // Restore positions of transformControl and fixedSphere
        this.fixed_sphere.position.set(state.transformControlPosition.x, state.transformControlPosition.y, state.transformControlPosition.z);

        // Restore asset positions
        for (const asset_state of state.asset_states) {
            this.individual_assets.getObjectByName(asset_state.name).position.copy(asset_state.position);
        }
    }

    transformVertexHeights = () => {
        if(!this.fixed_sphere) return;
 
        const plane_normal = new THREE.Vector3(0,0,1); // default plane normal on initialization
        const transformation_matrix = new THREE.Matrix4().fromArray(this.terrain_plane.userData.transformationMatrix)
        plane_normal.applyQuaternion(new THREE.Quaternion().setFromRotationMatrix(transformation_matrix));
        plane_normal.normalize();
        const main_direction = mainDirection(plane_normal);

        const position_update_new = new THREE.Vector3().setFromMatrixPosition(this.fixed_sphere.matrixWorld);
        const plane_vertices_positions = this.terrain_plane.geometry.attributes.position;
        const seed_vertex_position = new THREE.Vector3(this.initial_terrain_plane_vertices.getX(this.current_vertex_index),
                                                        this.initial_terrain_plane_vertices.getY(this.current_vertex_index),
                                                        this.initial_terrain_plane_vertices.getZ(this.current_vertex_index)
        );

        // max_terrain_height is the height of the hill
        let max_terrain_height, map_vector, map_vector_complement;
        let affected_assets = new Set();
        const assets_present = this.vertex_asset_map.size != 0;

        switch (main_direction) {
            case "x":
                max_terrain_height = position_update_new.x - seed_vertex_position.x;
                map_vector = [1, 0, 0];
                map_vector_complement = [0, 1, 1];
                break;
            case "y":
                max_terrain_height = position_update_new.y-  seed_vertex_position.y;
                map_vector = [0, 1, 0];
                map_vector_complement = [1, 0, 1];
                break;
            case "z":
                max_terrain_height = position_update_new.z-  seed_vertex_position.z;
                map_vector = [0, 0, 1];
                map_vector_complement = [1, 1, 0];
                break;
        }

        // limit the translation of the peak point to normal direction in a fixed interval
        const relative_normal_limit_factor = 5; // in meter
        const relative_normal_limit_vector = new THREE.Vector3(map_vector[0]*relative_normal_limit_factor,
                                                                map_vector[1]*relative_normal_limit_factor,
                                                                map_vector[2]*relative_normal_limit_factor
        );
        const lower_limit = seed_vertex_position.clone();
        const upper_limit = seed_vertex_position.clone();
        lower_limit.sub(relative_normal_limit_vector);
        upper_limit.add(relative_normal_limit_vector);
        this.fixed_sphere.position.clamp( lower_limit, upper_limit);

        // Define influence radius for gaussian hill function
        // sigam = The standard deviation
        const tolerance = 0.01; // The tolerance
        const radius = estimateInfluenceRadius(max_terrain_height, this.sigma, tolerance);

        for (let i = 0; i < plane_vertices_positions.count ; i++) {

            if (i === this.current_vertex_index) continue;

            const vertex = new THREE.Vector3().fromBufferAttribute(this.initial_terrain_plane_vertices, i);
            const squared_distance = vertex.distanceToSquared(seed_vertex_position); //squared for perfromance reasons

            // Change this value to control the 'reach' of the hill.
            const squared_radius = Math.pow(radius,2);

            // If vertex is out of reach do not adapt height
            if (squared_distance >= squared_radius) continue;

            const delta = new THREE.Vector3().copy(vertex).sub(seed_vertex_position).toArray(); // difference between current vertex point and seed point
            let delta_on_plane = [];
            delta.forEach((item, index) => {
                if (map_vector_complement[index] != 0){
                    delta_on_plane.push(item * map_vector_complement[index]);
                }
            });

            const relative_height = gaussian(delta_on_plane[0], delta_on_plane[1], max_terrain_height, this.sigma);
            // const relative_height = gaussianApproximation(delta_on_plane[0], delta_on_plane[1], max_terrain_height, sigma);
            // const scaling = (1 - squared_distance / squared_radius); // this function determines the shapeof the hill

            const initial_position = [this.initial_terrain_plane_vertices.getX(i),
                                        this.initial_terrain_plane_vertices.getY(i),
                                        this.initial_terrain_plane_vertices.getZ(i)
            ];
            // let the increment be uniform in all three direction and then apply the map so that
            // two of three components become '0' and only the main direction maintains an increment
            const increment = [relative_height, relative_height, relative_height];
            // const increment = [scaling * max_terrain_height, scaling * max_terrain_height, scaling * max_terrain_height];
            const mapped_increment = increment.map((item, index) => item * map_vector[index]);
            plane_vertices_positions.setXYZ(i, initial_position[0] + mapped_increment[0],
                                            initial_position[1] + mapped_increment[1],
                                            initial_position[2] + mapped_increment[2]
            );
            if (assets_present && this.vertex_asset_map.has(i)){
                affected_assets = affected_assets.union(this.vertex_asset_map.get(i));
            }
        }
        plane_vertices_positions.setXYZ(this.current_vertex_index, position_update_new.x, position_update_new.y, position_update_new.z);
        plane_vertices_positions.needsUpdate = true;
        
        // The bounding box needs to be recalculated otherwise the raycaster does not work on the modified surface...
        this.terrain_plane.geometry.computeBoundingBox();

        // update asset positions
        if (assets_present){
            this.updateAssetPositions(affected_assets, map_vector);
        }


    }

    updateAssetPositions(affected_assets, direction){
        for (const asset_uuid of affected_assets) {
            const asset = this.individual_assets.getObjectByName(asset_uuid);
            const raycaster = new THREE.Raycaster();

            const origin = new THREE.Vector3().copy(asset.position); // Replace with your origin coordinates
            const ray_direction = new THREE.Vector3(direction[0], direction[1], direction[2]);

            origin.addScaledVector(ray_direction, -1000)

            raycaster.set(origin, ray_direction);

            // Get the intersection
            const intersects = raycaster.intersectObject(this.terrain_plane);

            if (intersects.length > 0) {
                // Move asset position to intersection point
                asset.position.copy(intersects[0].point);
            } else {
                console.log('No intersection found.');
            }
            
        }
    }

    resetSceneObjects(){
        if(this.fixed_sphere){
            disposeObject(this.fixed_sphere);
            this.fixed_sphere = null;
        }
        if(this.current_sphere){
            disposeObject(this.current_sphere);
            this.current_sphere = null;
        }
        this.current_vertex_index = null;
        this.current_snapping_point = null;
        this.transformControl.detach();
        this.transformControl.removeFromParent();
    }

    modifyAnimationLoop(callback){
        // Add custom function to animation loop for smoother (real-time) rendering of the terrain modeling
        // then call the orginal animaton callback funtion
        this.components.update = async () => {
            callback.call();
            return this.originalAnimationCallback.call(this.components);
        };
    }

    resetAnimationLoop(){
        this.components.update = async () => {
            return this.originalAnimationCallback.call(this.components);
        };
    }

    // CALLBACKS FOR EVENT LISTENERS
    transformControlCallback = (event) =>{
        if(event.value){
            this.saveCurrentState()
        }else{
            this.transformVertexHeights();
        }

    }

    showSnappingPointsCallback = (event) => {

        if (this.current_sphere){
            disposeObject(this.current_sphere);
        }

        const mouse_position = this.mouse.position;
        this.raycaster.setFromCamera(mouse_position, this.camera);

        const intersects = this.raycaster.intersectObject(this.terrain_plane);
        let intersect;
        if (intersects.length > 0 && !this.fixed_sphere) {
            intersect = intersects[0];
        }
        else{
            this.current_sphere = null;
            return;
        }

        const ray_point = intersect.point;
        const vA = new THREE.Vector3();
        const vB = new THREE.Vector3();
        const vC = new THREE.Vector3();
        const face = intersect.face;
        const position = intersect.object.geometry.attributes.position;

        vA.fromBufferAttribute( position, face.a );
        vB.fromBufferAttribute( position, face.b );
        vC.fromBufferAttribute( position, face.c );

        const d1 = ray_point.distanceToSquared(vA);
        const d2 = ray_point.distanceToSquared(vB);
        const d3 = ray_point.distanceToSquared(vC);

        if (d1< d2 && d1<d3){
            this.current_snapping_point = vA;
            this.current_vertex_index = face.a;
        }else if(d2< d1 && d2<d3){
            this.current_snapping_point = vB;
            this.current_vertex_index = face.c;
        }else{
            this.current_snapping_point = vC;
            this.current_vertex_index = face.c;
        }

        this.current_sphere = createSphere(this.current_snapping_point);
        this.current_sphere.name = 'snapping_point';

        // move mesh position to center of geometry (sphere center)
        this.current_sphere.geometry.computeBoundingSphere();
        const center = new THREE.Vector3(this.current_sphere.geometry.boundingSphere.center.x,
                                        this.current_sphere.geometry.boundingSphere.center.y,
                                        this.current_sphere.geometry.boundingSphere.center.z
        );
        this.current_sphere.geometry.center();
        this.current_sphere.position.copy(center);

        this.scene.add( this.current_sphere );
    }

    selectPointForElevation = (event) => {

        if (this.current_sphere){
            this.fixed_sphere = this.current_sphere.clone();
            this.fixed_sphere.name = "fixed_point";
            this.initial_terrain_plane_vertices =  this.terrain_plane.geometry.attributes.position.clone();
            disposeObject(this.current_sphere);
            this.scene.add( this.fixed_sphere );
            this.transformControl.attach(this.fixed_sphere);
            this.transformControl.position.set(this.fixed_sphere.geometry.boundingSphere.center.x,
                                                this.fixed_sphere.geometry.boundingSphere.center.y,
                                                this.fixed_sphere.geometry.boundingSphere.center.z)
            ;
            this.scene.add(this.transformControl);

            document.addEventListener("keydown", this.fixPeakPosition); // bake terrain model on enter
            this.domElement.addEventListener(customEvents.terrainPointConfirmed.type, this.fixPeakPosition);

            this.domElement.dispatchEvent(customEvents.terrainPointPicked);
            // this.modifyAnimationLoop(this.transformVertexHeights);
        }
    }

    fixPeakPosition = (event) => {
        if (event.key === "Enter" || event.type == customEvents.terrainPointConfirmed.type) {
            // this.resetAnimationLoop();
            this.resetSceneObjects();
            document.removeEventListener("keydown", this.fixPeakPosition);
            this.domElement.removeEventListener(customEvents.terrainPointConfirmed.type, this.fixPeakPosition);
        }
    };

    escapeModeling = (event) => {
        if (event.key === "Escape") {
            this.endModeling();
        }
    };

}

class AssetLoader{
    /**
     * Create an AssetLoader.
     * @param {ModelingInterfaceManager} global_manager - The global manager instance.
     */
    constructor(global_manager){
        this.global_manager = global_manager;
        this.domElement = global_manager.domElement;
        this.scene = global_manager.scene;
        this.green_roof_objects = global_manager.green_roof_objects;
        this.components = global_manager.components;
        this.controls = global_manager.custom_orbit_controls;
        this.camera = global_manager.world.camera.three;
        this.gltfLoader = createGLTFLoader();
        this.asset_object;
        this.raycaster = new THREE.Raycaster();
        this.raycaster.layers.set(0);
        this.mouse = new Mouse(this.domElement);
        this.current_face_index;
        this.selectionManager = global_manager.selectionManager;
        this.loadedAssets = {};
        this.highlighted_object;
        this.selected_roof_object;
        this.individual_placement = default_values.individual_placement;
        this.current_asset_mix = [];
        this.current_type_details;
    }

    load(id){
        this.discardAsset();
        this.asset_object = this.loadedAssets[id];
    }

    loadArraybuffer = async (arrayBuffer, id, type_details = null) => {
        try {
            const gltf = await new Promise((resolve, reject) => {
                this.gltfLoader.parse(
                    arrayBuffer,
                    "",
                    // called when the resource is loaded
                    (gltf) => resolve(gltf),
                    // called when loading has errors
                    (error) => reject(error)
                );
            });
    
            this.discardAsset();
            this.current_type_details = type_details;
            this.asset_object = gltf.scene;
            this.loadedAssets[id] = this.asset_object;
        } catch (error) {
            console.error(error);
        }
    };
    
    loadArraybufferOnStartUp = (arrayBuffer, parent) => {
        return new Promise((resolve, reject) => {
            this.gltfLoader.parse(
                // Data
                arrayBuffer,
                "",
                // called when the resource is loaded
                (gltf) => {
                    this.loadedAssets[parent.userData.cg_type_details.id] = gltf.scene;
                    resolve(); // Resolve the promise when the asset is loaded and added
                },
                // called when loading has errors
                (error) => {
                    console.error(error);
                    reject(error); // Reject the promise on error
                }
            );
        });
    };
    

    startUserPlacement(){
        this.scene.add( this.asset_object );
        this.domElement.addEventListener('mousemove', this.moveAsset);
        this.domElement.addEventListener('click', this.placeAsset);
    }

    stopUserPlacement(){
        this.domElement.removeEventListener('mousemove', this.moveAsset);
        this.domElement.removeEventListener('click', this.placeAsset);
        if(this.asset_object){
            this.asset_object.removeFromParent();
            this.asset_object = null;
        }
        this.selectionManager.clearSelection();
        this.current_face_index = null;
    }


    moveAsset = (event) => {
        // Update the object position on mouse move
        event.preventDefault();

        const mouse_position = this.mouse.position;
        this.raycaster.setFromCamera(mouse_position, this.camera);


        // Calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects(this.global_manager.objects3d);

        if (intersects.length > 0) {
            this.asset_object.position.copy(intersects[0].point);
            let parent = intersects[0].object;
            // while (parent != null && parent.parent != this.green_roof_objects)
            while (parent.parent != null && !parent.parent.userData.is_cg_roof)
            {
                parent = parent.parent;
            }
            this.selectionManager.highlightSelection(parent);
            this.current_face_index = intersects[0].faceIndex;

        }
        else{
            this.selectionManager.clearSelection();
            this.current_face_index = null;
        }
    };

    placeAsset = (event) => {
        event.preventDefault();
        if(!this.selectionManager.current_selection_id){
            this.domElement.dispatchEvent(customEvents.assetPlacementError);
            return
        }

        const asset_object_wrapper = new THREE.Group();
        asset_object_wrapper.name = uuidv4();
        asset_object_wrapper.userData = {
            cg_type: objectTypes.individual_plant,
            cg_type_details: this.current_type_details
        }
        // Place reference on corresponding green roof object
        const corresponding_green_roof = this.green_roof_objects.getObjectById(this.selectionManager.current_selection_id);
        corresponding_green_roof.userData.cg_assets[asset_object_wrapper.name] = this.current_face_index;

        // Place reference to green roof object on the asset
        asset_object_wrapper.userData.cg_associated_roof = corresponding_green_roof.name;

        const asset_object = this.asset_object.clone();
        const saved_position = asset_object.position.clone();
        asset_object.position.set(0,0,0);
        asset_object_wrapper.position.copy(saved_position);
        asset_object_wrapper.add(asset_object);

        this.global_manager.registerAssets(asset_object_wrapper);

    }

    discardAsset(){
        if(this.asset_object){
            this.asset_object.removeFromParent();
            this.asset_object = null;
        }
    }

    disposeAllAssets(){
        for (const key in this.loadedAssets) {
            const asset = this.loadedAssets[key];
            disposeRecursive(asset)
            delete this.loadedAssets[key];
        }
    }

    toggleOrbitControlsCallback = (event) =>{
        this.controls.enabled = ! event.value;
    }

    enableRoofSelection(){
        this.domElement.addEventListener('mousemove', this.highlightRoofObject);
        this.domElement.addEventListener('click', this.selectRoofObject);
    }

    disableRoofSelection(){
        this.selected_roof_object = null;
        this.highlighted_object = null;
        this.current_asset_mix = [];
        this.domElement.removeEventListener('mousemove', this.highlightRoofObject);
        this.domElement.removeEventListener('click', this.selectRoofObject);
    }

    highlightRoofObject = () => {
        const mouse_position = this.mouse.position;
        this.raycaster.setFromCamera(mouse_position, this.camera);

        const intersects = this.raycaster.intersectObjects(this.green_roof_objects.children);
        if (intersects.length > 0) {
            let parent = intersects[0].object;
            while (parent !== null && !parent.userData.cg_type) {
                parent = parent.parent;
            }

            if(parent.userData.cg_type == objectTypes.standard){
                this.highlighted_object = parent;
                this.selectionManager.highlightSelection(this.highlighted_object);
            }else{
                this.highlighted_object = null;
                this.selectionManager.clearSelection();
                return;
            } 
        }
        else{
            this.highlighted_object = null;
            this.selectionManager.clearSelection();
            return;
        }        
    }

    selectRoofObject = () =>{
        if(this.highlighted_object){
            this.selected_roof_object = this.highlighted_object;
            // this.selectionManager.addSelection(this.selected_roof_object);
            this.tryPlacingAssetMix();
        }
    }

    tryPlacingAssetMix(){
        // Check if all necessary asstes are loaded
        const all_assets_loaded = this.current_asset_mix.every(item => this.loadedAssets.hasOwnProperty(item.id));

        if(all_assets_loaded && this.current_asset_mix.length != 0 && this.selected_roof_object){
            this.placeInstancedMesh();
        }
    }

    placeInstancedMesh(){
        // Get geometric data of the green roof object
        const green_roof_base = getGreenRoofChildByName(this.selected_roof_object, "cg_base");
        const vertices = green_roof_base.userData.vertices_on_xy;
        const holes = green_roof_base.userData.holes_on_xy;
        const global_transformation_matrix = new THREE.Matrix4().fromArray(getGreenRoofChildByName(this.selected_roof_object, "cg_top").userData.transformationMatrix);

        // Create a GeoJSON reader
        const reader = new jsts.io.GeoJSONReader();

        // Define a polygon in Cartesian coordinates
        const polygon_coordinates = [vertices, ...holes];
        const polygonGeoJSON  = {"type": "Polygon","coordinates": polygon_coordinates};
        const polygon = reader.read(polygonGeoJSON);
        const area = polygon.getArea();

        // Calculate distribution and occupancy
        // maybe expose the following parameter
        const asset_per_area = 0.1; // num/m^2
        const total_number_of_assets = Math.round(area*asset_per_area);
        const total_points = generateRandomPointsInPolygon(polygon, total_number_of_assets);
        console.log(`Place ${total_number_of_assets} assets on ${area} m^2, this are approx. ${asset_per_area} assets/m^2`);

        const asset_mix = new THREE.Group();
        asset_mix.name =  uuidv4();
        asset_mix.userData = {cg_type: objectTypes.asset_mix, cg_associated_roof: this.selected_roof_object.name};

        this.current_asset_mix.forEach(asset => {
            const asset_object = this.loadedAssets[asset.id];
            const number_of_assets = Math.floor(total_number_of_assets * asset.spreading);
            const points = total_points.splice(0, number_of_assets);
  
            const sub_geometries = [];
            const sub_materials = [];
            let saved_rotation, saved_scale, mergedGeometry , mergedMaterial ;
            asset_object.traverse((child) => {
                if (child.isMesh) {
                    saved_scale = child.scale;
                    saved_rotation = child.quaternion;
                    sub_geometries.push(child.geometry);
                    sub_materials.push(child.material);
                }
            });
            if(sub_geometries.length > 1){
                mergedGeometry = BufferGeometryUtils.mergeGeometries( sub_geometries );
                mergedMaterial = sub_materials;

            }else{
                mergedGeometry = sub_geometries[0];
                mergedMaterial = sub_materials[0];
            }
            mergedGeometry.scale(saved_scale.x, saved_scale.y, saved_scale.z);
            mergedGeometry.applyQuaternion(saved_rotation);

            // Create InstancedMeshes
            // The assets are with Z-Up orientation but the three.js world is Y-up, so we have to make the following rotation first
            const zUp_to_yUp_rotation = new THREE.Matrix4().makeRotationX(Math.PI / 2); 
            mergedGeometry.applyMatrix4(zUp_to_yUp_rotation);
            const instancedMesh = new THREE.InstancedMesh(mergedGeometry, mergedMaterial, number_of_assets);
            
            for (let i = 0; i < number_of_assets; i++) {
                const [x, y] = points[i];
                const matrix = new THREE.Matrix4().setPosition ( x, y, 0);
                instancedMesh.setMatrixAt(i, matrix);
            }
            
            instancedMesh.applyMatrix4(global_transformation_matrix);

            asset_mix.add(instancedMesh);
        });
        
        this.global_manager.registerAssetMix(asset_mix);

        this.current_asset_mix = [];
        // Apply local transformations

    }
}

class SelectionManager {
    /**
     * Create a SelectionManager.
     * @param {ModelingInterfaceManager} global_manager - The global manager instance.
     */
    constructor(global_manager){
        this.global_manager = global_manager;
        this.green_roof_objects = global_manager.green_roof_objects;
        this.individual_assets = global_manager.individual_assets;
        this.asset_mixes = global_manager.asset_mixes;
        this.levels = global_manager.levels;
        this.current_selection_id;
        this.fixed_selection_id;
        this.box_helper;
        this.box_helper_fixed;
        this.saved_colors = new Object();
    }

    get scene() {
        return this.global_manager.scene;
    }

    getObjectType(object_id){
        let object_type;
        try{
            object_type = this.global_manager.scene.getObjectById(object_id).userData.cg_type;
        }
        catch{
            object_type = null;
        }
        return object_type;
    }

    select(object){
        this.clearSelection();
        this.clearSelectionFixed();
        this.saved_colors[object.id] = []
        object.children.forEach(child => {
            this.saved_colors[object.id].push(child.material.color.toArray())
            child.material.color.set( colorPalette.object_highlight );
        });
        this.fixed_selection_id = object.id;
    }

    addSelection(object){
        const object_type = this.getObjectType(object.id);
        if(!object_type || object_type == objectTypes.level){
            return;
        }

        this.clearSelectionFixed();
        this.fixed_selection_id = object.id;
        if(object_type == objectTypes.individual_plant || object_type == objectTypes.asset_mix){
            const box = new THREE.Box3().setFromObject(object);
            this.box_helper_fixed = new THREE.Box3Helper(box, colorPalette.plant_bounding_box);
            this.scene.add(this.box_helper_fixed);
        }
    }

    highlightSelection(object){
        this.clearSelection();
        const object_type = this.getObjectType(object.id);
        if(object_type && object.id != this.fixed_selection_id){
            switch (object_type) {
                case objectTypes.standard:
                    this.current_selection_id = object.id;
                    this.saved_colors[this.current_selection_id] = []
                    object.children.forEach(child => {
                        this.saved_colors[this.current_selection_id].push(child.material.color.toArray())
                        child.material.color.set( colorPalette.object_highlight );
                    });
                    break;
                case objectTypes.individual_plant:
                case objectTypes.asset_mix:
                    this.current_selection_id = object.id;

                    const box = new THREE.Box3().setFromObject(object);
                    this.box_helper = new THREE.Box3Helper(box, colorPalette.plant_bounding_box);
                    this.scene.add(this.box_helper);
                    break;
                case objectTypes.level_dxf_background:
                case objectTypes.level:
                    this.current_selection_id = object.id;
                    this.saved_colors[this.current_selection_id] = [object.material.color.toArray()]
                    object.material.color.set( colorPalette.object_highlight );
                    break;
                default:
                    break;
            }
        }
        else{
            this.current_selection_id = null;
            return;
        }
    }

    clearSelection(){
        if(this.box_helper){
            this.scene.remove(this.box_helper);
            this.box_helper = null; // Clear the reference
        }
        if(this.current_selection_id && this.current_selection_id != this.fixed_selection_id){
            const object_type = this.getObjectType(this.current_selection_id);
            if(object_type){
                this.clearHelper(object_type, this.current_selection_id)
            }
            else{
                return;
            }
        }
        this.current_selection_id = null;
    }

    clearSelectionFixed(){
        if(this.box_helper_fixed){
            this.scene.remove(this.box_helper_fixed);
            this.box_helper_fixed = null; // Clear the reference
        }
        if(this.fixed_selection_id){
            const object_type = this.getObjectType(this.fixed_selection_id);
            if(object_type){
                this.clearHelper(object_type, this.fixed_selection_id)
            }
            else{
                return;
            }
            this.fixed_selection_id = null;
        }
    }

    clearHelper(object_type, id){
        switch (object_type) {
            case objectTypes.standard:
                const current_object = this.green_roof_objects.getObjectById(id);
                current_object.children.forEach((child, index) => {
                    child.material.color.fromArray(this.saved_colors[id][index]) // = original_material;
                });
                break;
            case objectTypes.individual_plant:
            case objectTypes.asset_mix:
                break;
            case objectTypes.level_dxf_background:
            case objectTypes.level:
                const current_level = this.levels.getObjectById(id);
                current_level.material.color.fromArray(this.saved_colors[id][0])
                break;
            default:
                break;
        }
    }

    reset(){
        this.clearSelection();
        this.clearSelectionFixed();
        this.saved_colors = new Object();
    }    

}

class ObjectModifier{
    /**
     * Create an ObjectModifier.
     * @param {ModelingInterfaceManager} global_manager - The global manager instance.
     */
    constructor(global_manager){
        this.global_manager = global_manager;
        this.scene = global_manager.scene;
        this.green_roof_objects = global_manager.green_roof_objects;
        this.individual_assets = global_manager.individual_assets;
        this.asset_mixes = global_manager.asset_mixes;
        this.selectionManager = global_manager.selectionManager;
        this.raycaster = new THREE.Raycaster();
        this.raycaster.layers.set(0);
        this.domElement = global_manager.domElement;
        this.mouse = new Mouse(this.domElement);
        this.camera = global_manager.world.camera.three;

        this.transformControls = new TransformControls(this.camera, this.domElement)
        this.transformControls.showX = false; // Hide X axis controls
        this.transformControls.showZ = false; // Hide Z axis controls
        this.transformControls.addEventListener( 'dragging-changed', this.updateDragging);

        this.current_object;
        this.selectedObject;
        this.terrain_modeler;
        this.current_transform_position;
        this.current_bounding_sphere;
    }

    startModification(){
        this.domElement.addEventListener("click", this.select3dObject);
        this.domElement.addEventListener("pointermove", this.highlight3dObject);
    }

    endModification(){
        this.selectionManager.reset();
        this.selectedObject = null;
        this.selected_parent_name = null;
        this.current_object = null;
        if (this.terrain_modeler) this.endTerrainModeling();
        this.endObjectElevation();
        this.domElement.removeEventListener("click", this.select3dObject);
        this.domElement.removeEventListener("pointermove", this.highlight3dObject);
    }

    startObjectElevation(){
        this.domElement.removeEventListener("click", this.select3dObject);
        this.domElement.removeEventListener("pointermove", this.highlight3dObject);
        // Get root object of selected green roof object
        const green_roof_root = this.selectedObject.parent;
        // calculate the bounding sphere of the green roof object and then get center
        const bounding_sphere = computeGroupBounding(green_roof_root);
        // transform control (only y-axis)
        this.transformControls.attach(bounding_sphere);
        // this.transformControls.position.copy(bounding_sphere.position);
        this.scene.add(this.transformControls);
        this.scene.add(bounding_sphere);
        this.current_bounding_sphere = bounding_sphere;

        // Store the initial position
        this.current_transform_position = this.current_bounding_sphere.position.clone();
    }

    updateDragging = (event)=>{
        if (event.value) {
            // Dragging started

        } else {
            // Dragging stopped
            // save state of the object for undo 
            // needs to be still implemented

            // update userData of green roof object substructures (bases and tops) and asset positions
            this.updateGreenRoofObject();

        }

    }

    endObjectElevation(){
        this.transformControls.detach();
        this.transformControls.removeFromParent();
        if(this.current_bounding_sphere) disposeObject(this.current_bounding_sphere);
        this.domElement.addEventListener("click", this.select3dObject);
        this.domElement.addEventListener("pointermove", this.highlight3dObject);
    }

    startTerrainModeling(){
        this.domElement.removeEventListener("click", this.select3dObject);
        this.domElement.removeEventListener("pointermove", this.highlight3dObject);
        this.terrain_modeler = new TerrainModeler(this.global_manager, this.selectedObject);
        this.terrain_modeler.startModeling();
    }

    endTerrainModeling(){
        this.terrain_modeler.endModeling();
        this.terrain_modeler = null;
        this.domElement.addEventListener("click", this.select3dObject);
        this.domElement.addEventListener("pointermove", this.highlight3dObject);

        this.selectionManager.reset();
        this.selectedObject = null;
        this.selected_parent_name = null;
        this.current_object = null;
        this.domElement.dispatchEvent(customEvents.objectUnselected);
    }

    switchType(type_details) {
        // create new extruded roof geometry
        const green_roof_base = getGreenRoofChildByName(this.selectedObject, "cg_base");
        const green_roof_top = getGreenRoofChildByName(this.selectedObject, "cg_top");
        const points_on_xy = green_roof_base.userData.vertices_on_xy.map(point => new THREE.Vector2(point[0], point[1]));
        const plane_transformation_matrix = new THREE.Matrix4().fromArray(green_roof_base.userData.transformationMatrix)
        const matrix_base_offset = new THREE.Matrix4().makeTranslation(0, 0, -type_details.total_depth+z_fighting_offset); // 1mm offset to avoid z-fighting
        const transformation_matrix_base = new THREE.Matrix4().multiplyMatrices(plane_transformation_matrix, matrix_base_offset);

        const plane_shape = new THREE.Shape(points_on_xy);
        const extrudeSettings = {depth: type_details.total_depth, bevelEnabled: false};
        const roof_geometry = new THREE.ExtrudeGeometry( plane_shape, extrudeSettings );
        roof_geometry.applyMatrix4(transformation_matrix_base);


        green_roof_base.geometry.dispose();
        green_roof_base.geometry = roof_geometry;
        this.selectedObject.userData.cg_type_details = type_details;
        this.selectedObject.userData.cg_instance_depth = type_details.total_depth;

        // Change color based on type_details.id
        const color = randomColors[type_details.id] || colorPalette.standard_green_roof; // Default to white if id not found
        const color_hex = new THREE.Color(color);
        // const color_hex = parseInt(color.replace('#', ''), 16); //new THREE.Color(color).getHex();
        // green_roof_base.material.color.setHex(color_hex);
        // green_roof_top.material.color.setHex(color_hex);
        this.global_manager.selectionManager.saved_colors[this.selectedObject.id] = [color_hex.toArray(), color_hex.toArray()];
    }

    updateThickness(new_thickness){
        // create new extruded roof geometry
        const green_roof_base = getGreenRoofChildByName(this.selectedObject, "cg_base");
        const points_on_xy = green_roof_base.userData.vertices_on_xy.map(point => new THREE.Vector2(point[0], point[1]));
        const plane_transformation_matrix = new THREE.Matrix4().fromArray(green_roof_base.userData.transformationMatrix)
        const matrix_base_offset = new THREE.Matrix4().makeTranslation(0, 0, -new_thickness+z_fighting_offset); // 1mm offset to avoid z-fighting
        const transformation_matrix_base = new THREE.Matrix4().multiplyMatrices(plane_transformation_matrix, matrix_base_offset);

        const plane_shape = new THREE.Shape(points_on_xy);
        const extrudeSettings = {depth: new_thickness, bevelEnabled: false};
        const roof_geometry = new THREE.ExtrudeGeometry( plane_shape, extrudeSettings );
        roof_geometry.applyMatrix4(transformation_matrix_base);


        green_roof_base.geometry.dispose();
        green_roof_base.geometry = roof_geometry;
        this.selectedObject.userData.cg_instance_depth = new_thickness;
    }

    updateGreenRoofObject(){
        const vector_increment = new THREE.Vector3().copy(this.current_bounding_sphere.position).sub(this.current_transform_position);
        for(const sub_roof of this.selectedObject.parent.children){
            // update userData of green roof object substructures (bases and tops)
            const sub_roof_base = getGreenRoofChildByName(sub_roof, "cg_base");
            const sub_roof_top = getGreenRoofChildByName(sub_roof, "cg_top");
            
            const transformation_matrix_base = new THREE.Matrix4().fromArray(sub_roof_base.userData.transformationMatrix);
            const transformation_matrix_top = new THREE.Matrix4().fromArray(sub_roof_top.userData.transformationMatrix);

            // apply vector increment to the transformation matrices
            const matrix_translation = new THREE.Matrix4().makeTranslation(vector_increment);

            sub_roof_base.geometry.applyMatrix4(matrix_translation);
            sub_roof_base.geometry.attributes.position.needsUpdate = true;

            sub_roof_top.geometry.applyMatrix4(matrix_translation);
            sub_roof_top.geometry.attributes.position.needsUpdate = true;

            const new_position_base = new THREE.Vector3().setFromMatrixPosition(transformation_matrix_base).add(vector_increment);
            transformation_matrix_base.setPosition(new_position_base);

            const new_position_top = new THREE.Vector3().setFromMatrixPosition(transformation_matrix_top).add(vector_increment);
            transformation_matrix_top.setPosition(new_position_top);

            sub_roof_base.userData.transformationMatrix = transformation_matrix_base.elements;
            sub_roof_top.userData.transformationMatrix = transformation_matrix_top.elements;

            // update assets
            const affected_assets = sub_roof.userData.cg_assets;
            const assetMixAssociatedToThisSubRoof = this.asset_mixes.children.find(object => object.userData.cg_associated_roof === sub_roof.name);

            if(affected_assets) {
                for (const asset_uuid in affected_assets) {
                    const asset = this.individual_assets.getObjectByName(asset_uuid);
                    asset.position.add(vector_increment);
                }
            };

            if(assetMixAssociatedToThisSubRoof){
                assetMixAssociatedToThisSubRoof.children.forEach((instancedMesh) => {
                    instancedMesh.position.add(vector_increment);
                });
            }

        }
        this.current_transform_position = this.current_bounding_sphere.position.clone();
    }

    deleteAssociatedAssets(){
        const assets = this.selectedObject.userData.cg_assets;
        if(!assets) return;
        for (const asset_uuid in assets) {
            const asset = this.individual_assets.getObjectByName(asset_uuid);
            disposeRecursive(asset);
        }
    }

    async deleteObject(){
        this.selectionManager.reset();
        this.domElement.dispatchEvent(customEvents.objectUnselected);
        
        if(!this.selectedObject.userData.cg_type) return;

        switch (this.selectedObject.userData.cg_type) {
            case objectTypes.standard:
                const composed_green_roof_object = this.selectedObject.parent; // This corresponds to Area in the database
                console.log(composed_green_roof_object);
                const deletion_helper = () =>{
                    // dispose associated assets (only individual plants)
                    this.deleteAssociatedAssets();

                    // Check if green roof has any associated asset mixes
                    const assetMixAssociatedToThisRoof = this.asset_mixes.children.find(object => object.userData.cg_associated_roof === this.selectedObject.name);
                    if (assetMixAssociatedToThisRoof) {
                        disposeRecursive(assetMixAssociatedToThisRoof);
                    }
            
                    // dispose selected object
                    this.selectedObject.children.forEach((child)=>{
                        disposeObject(child);
                    })
                }

                // Check if parent of selected has other children, if not remove from parent:
                if(composed_green_roof_object.children.length == 1){
                    // Delete area in database
                    const success = await deleteAreaInDatabase(composed_green_roof_object.userData.cg_db_data.project_id, composed_green_roof_object.userData.cg_db_data.id, composed_green_roof_object.userData.cg_hydraulic_id)
                    if(!success){
                        throw new DataBaseError("Error deleting area in database");
                    }
                    deletion_helper();
                    composed_green_roof_object.removeFromParent();
                    this.domElement.dispatchEvent(customEvents.saveCustomGeometries);
                }else{
                    // Update area in database
                    const new_area = composed_green_roof_object.userData.cg_db_data.area - this.selectedObject.userData.cg_exact_area;
                    const db_response = await updateAreaInDatabase({area: new_area}, composed_green_roof_object.userData.cg_db_data.project_id, composed_green_roof_object.userData.cg_db_data.id)
                    if(!db_response){
                        throw new DataBaseError("Error updating area in database");
                    }
                    composed_green_roof_object.userData.cg_db_data = db_response;
                    deletion_helper();
                    this.selectedObject.removeFromParent();
                    this.domElement.dispatchEvent(customEvents.saveCustomGeometries);
                }
                break;
            case objectTypes.individual_plant:
                try{
                    const associated_roof = this.selectedObject.userData.cg_associated_roof;
                    delete this.green_roof_objects.getObjectByName(associated_roof).userData.cg_assets[this.selectedObject.name];
                    disposeRecursive(this.selectedObject);
                }catch(error){
                    console.log("Error deleting asset: ", error);
                }
                break;
            case objectTypes.asset_mix:
                try{
                    disposeRecursive(this.selectedObject);
                }catch(error){
                    console.log("Error deleting asset mix: ", error);
                }
                break;
        }

        this.selectedObject = null;
        this.selected_parent_name = null;
    }

    async updateParentName(new_name){
        const parent = this.selectedObject.parent;
        const db_area_id = parent.userData.cg_db_data.id;
        const project_id = parent.userData.cg_db_data.project_id;
        // Update area in database
        const db_response = await updateAreaInDatabase({name: new_name}, project_id, db_area_id)
        if(!db_response){
            throw new DataBaseError("Error updating area in database");
        }
        parent.userData.cg_db_data.name = new_name;

        // update corrsponding hydraulic component
        const hydraulic_id = parent.userData.cg_hydraulic_id;
        updateHydraulicElementInDatabase(hydraulic_id, project_id, new_name);

    }

    highlight3dObject = () => {
        const mouse_position = this.mouse.position;
        this.raycaster.setFromCamera(mouse_position, this.camera);

        const intersects = this.raycaster.intersectObjects([this.green_roof_objects, this.individual_assets, this.asset_mixes]);
        if (intersects.length > 0) {
            let parent = intersects[0].object;
            while (parent !== null && !parent.userData.cg_type) {
                parent = parent.parent;
            }
            this.current_object = parent;
        }
        else{
            this.current_object = null;
            this.selectionManager.clearSelection();
            return;
        }

        this.selectionManager.highlightSelection(this.current_object);
    }

    select3dObject = () =>{
        if(this.current_object && this.current_object == this.selectedObject){
            this.selectionManager.reset();
            this.selectedObject = null;
            this.current_object = null;
            this.selected_parent_name = null;
            this.domElement.dispatchEvent(customEvents.objectUnselected);
        }
        else if(this.current_object){
            this.selectedObject = this.current_object;
            this.selectionManager.addSelection(this.current_object);
            this.selected_parent_name = this.getSelectedParentName();
            this.domElement.dispatchEvent(customEvents.objectSelected);
        }
    }

    getSelectedParentName(){
        if(this.selectedObject.userData.cg_type == objectTypes.standard){
            return this.selectedObject.parent.userData.cg_db_data.name;
        }else{
            return null;
        }
    }
}

function createGreenRoofObject(plane, vertices, type_details, sub_structure = null){
    if(!type_details){
        window.alert(i18n.global.t('modeling.notype_alert'));
        return;
    }

    let plane_transformation_matrix_unscaled;
    let points_on_xy, points_on_xy_list;
    let plane_shape;
    let holes_list;

    if(!sub_structure){
        const plane_position = new THREE.Vector3();
        const plane_rotation = new THREE.Quaternion();
        const plane_scale = new THREE.Vector3(1,1,1);
        plane.matrixWorld.decompose(plane_position, plane_rotation, new THREE.Vector3());
        plane_transformation_matrix_unscaled = new THREE.Matrix4().compose(plane_position, plane_rotation, plane_scale);
        [points_on_xy, points_on_xy_list] = transformVerticesToXyPlane(plane_transformation_matrix_unscaled, vertices);
        if(!simplePolygonCheck(points_on_xy_list)) throw new Error("The polygon is not valid");
        plane_shape = new THREE.Shape(points_on_xy);
        holes_list = [];
    }
    else{
        plane_transformation_matrix_unscaled = plane;
        points_on_xy_list = vertices;
        if(!simplePolygonCheck(points_on_xy_list)) throw new Error("The polygon is not valid");
        points_on_xy = vertices.map(point => new THREE.Vector2(point[0], point[1]));
        plane_shape = new THREE.Shape(points_on_xy);
        sub_structure.forEach(hole => {
            const hole_path = new THREE.Path(hole.map(point => new THREE.Vector2(point[0], point[1])));   
            plane_shape.holes.push(hole_path)
        });
        holes_list = sub_structure;  
    }


    // This matrix offsets the whole green roof to match the top face with the drawing plane
    const matrix_base_offset = new THREE.Matrix4().makeTranslation(0, 0, -type_details.total_depth+z_fighting_offset); // 1mm offset to avoid z-fighting

    // create extruded roof geometry
    const extrudeSettings = {depth: type_details.total_depth, bevelEnabled: false}; // The offset is to avoid an overlapping of with the top face of the extruded geometry. The overlap renders ugly...
    const roof_geometry = BufferGeometryUtils.mergeVertices(new THREE.ExtrudeGeometry( plane_shape, extrudeSettings )); // the merge is only needed to get an indexed geometry for the edge-measurements
    const transformation_matrix_base = new THREE.Matrix4().multiplyMatrices(plane_transformation_matrix_unscaled, matrix_base_offset);
    roof_geometry.applyMatrix4(transformation_matrix_base);

    // create regular mesh for discretization
    const [regular_mesh, index_info] = createRegularMesh(points_on_xy_list, grid_size, holes_list);

    // uvMapping(regular_mesh);
    const matrix_top_offset = new THREE.Matrix4().makeTranslation(0, 0, 2*z_fighting_offset); // 1mm offset to avoid z-fighting
    const transformation_matrix_top = new THREE.Matrix4().multiplyMatrices(plane_transformation_matrix_unscaled, matrix_top_offset);
    regular_mesh.applyMatrix4(transformation_matrix_top);

    // Create meshes and group with both geometries
    const green_roof_object = new THREE.Group();
    green_roof_object.name = uuidv4();
    const exact_area = calculatePolygonArea(points_on_xy_list, holes_list);
    green_roof_object.userData = {
        cg_type: objectTypes.standard, 
        cg_type_details: type_details,
        cg_instance_depth: type_details.total_depth,
        cg_exact_area: exact_area, 
        cg_assets: new Object()
    }

    // const grassTexture = new THREE.TextureLoader().load('/grass-texture_low.jpg', (texture) => {
    //     texture.colorSpace = THREE.SRGBColorSpace;
    //     texture.wrapS = THREE.RepeatWrapping;
    //     texture.wrapT = THREE.RepeatWrapping;
    //     texture.repeat.set(1, 1); // Adjust these values as needed
    // } );
    // const invisibleMaterial = new THREE.MeshBasicMaterial({
    //     transparent: true,
    //     opacity: 0 // Fully transparent (invisible)
    // });
    const randomColor = randomColors[type_details.id] ? randomColors[type_details.id] : colorPalette.standard_green_roof;

    const material_base = new THREE.MeshStandardMaterial({color: randomColor, roughness: 0.85, metalness: 0.25, side: THREE.DoubleSide, shadowSide: THREE.DoubleSide, flatShading: true});
    const green_roof_base = new THREE.Mesh( roof_geometry, material_base);
    green_roof_base.userData = {
        vertices_on_xy: points_on_xy_list,
        holes_on_xy: holes_list,
        transformationMatrix: plane_transformation_matrix_unscaled.elements
    }

    green_roof_object.add(green_roof_base);

    // const material_top = new THREE.MeshLambertMaterial({color: colorPalette.standard_green_roof, side: THREE.DoubleSide} ); //wireframe: true
    // const material_top = new THREE.MeshLambertMaterial({map: grassTexture, shadowSide: THREE.DoubleSide, side: THREE.DoubleSide, flatShading: false}); 
    const material_top = new THREE.MeshStandardMaterial({color: randomColor, roughness: 0.85, metalness: 0.25, side: THREE.DoubleSide, shadowSide: THREE.DoubleSide, flatShading: true});
    const green_roof_top = new THREE.Mesh(regular_mesh, material_top);


    green_roof_top.receiveShadow = true;
    green_roof_top.castShadow = true;

    // green_roof_top.userData.boundaryIndices = boundaryIndices;
    green_roof_top.userData = {
        transformationMatrix: transformation_matrix_top.elements,
        vertices_index_info: index_info,
        has_terrain: false
    }

    green_roof_object.add(green_roof_top);

    return green_roof_object;
}

async function createComposedGreenRoofObject(plane, vertices, type_details, existing_roof, canvasDomElement){
    const existing_parent = existing_roof.parent;

    // create the existing shape with a hole in the form of the sub structure
    const existing_base = getGreenRoofChildByName(existing_roof, 'cg_base');
    // get outer boundary vertices
    let vertices_on_xy_list = existing_base.userData.vertices_on_xy;
    // get vertices of already existing holes
    let holes_on_xy = existing_base.userData.holes_on_xy;
    

    let plane_transformation_matrix_unscaled = new THREE.Matrix4().fromArray(existing_base.userData.transformationMatrix);
    const [_ , new_hole_on_xy_list ] = transformVerticesToXyPlane(plane_transformation_matrix_unscaled, vertices);
    if(!simplePolygonCheck(new_hole_on_xy_list)) throw new Error("The polygon is not valid");

    let hole_intersects_boundary;

    // First check relation hole to boundary and then to other existing holes
    // For outer boundaries and new hole
    const relation_hole_boundary = relationOf2Polygons(vertices_on_xy_list, new_hole_on_xy_list);
    switch (relation_hole_boundary) {
        case polygon_relations.disjoint:
            // No hole but separate green roof
            throw new HolePlacementError('This polygon could not be included in the existing structure!')
        case polygon_relations.included:
            // polygon completely inside the exiting one -> create hole
            hole_intersects_boundary = false;
            break;
        case polygon_relations.intersect:
            // polygons intersect
            hole_intersects_boundary = true;
            vertices_on_xy_list = polygonDifference(vertices_on_xy_list, new_hole_on_xy_list);
            break;
    }

    // Then for new hole and all existing holes
    for (const hole of holes_on_xy) {
        const relation_new_hole_hole = relationOf2Polygons(hole, new_hole_on_xy_list);
        if(relation_new_hole_hole != polygon_relations.disjoint){
            throw new HolePlacementError('This polygon could not be included in the existing structure!')
        }
    }

    // When this point is reached with out errors and it does not intersect with the outer boundary: Add new hole to hole list
    if(!hole_intersects_boundary){
        holes_on_xy.push(new_hole_on_xy_list);
    }

    // Adapt existing structure (with new hole)
    let new_existing_roof
    try{
        new_existing_roof =  createGreenRoofObject(plane_transformation_matrix_unscaled, vertices_on_xy_list, existing_roof.userData.cg_type_details, holes_on_xy);
    }catch(error){
        throw new Error('Error creating new green roof object with hole: ', error)
    }

    existing_roof.removeFromParent();

    // Add modified roof object
    existing_parent.add(new_existing_roof);

    // create an extrude geometry with shape of the substructure and add it to existing one group
    let new_sub_roof;
    try{
        new_sub_roof = createGreenRoofObject(plane, vertices, type_details);
    }catch(error){
        throw new Error('Error creating new green roof object with hole: ', error)
    }
    
    existing_parent.add(new_sub_roof);
    
    // The area of the whole green roof (parent) needs to be recalculated and updated in the database
    if(hole_intersects_boundary){
        let new_area = 0;
        for (const sub_roof of existing_parent.children) {
            new_area += sub_roof.userData.cg_exact_area;
        }
        const area_data = {area: new_area};
        const project_id = existing_parent.userData.cg_db_data.project_id;
        const area_id = existing_parent.userData.cg_db_data.id;
        const db_response = await updateAreaInDatabase(area_data,project_id, area_id);
        if(!db_response){
            // Add previously existing object again and dispose all newly created geometries and throw error
            existing_parent.add(existing_roof);
            new_sub_roof.children.forEach((child)=>{disposeObject(child)});
            new_existing_roof.children.forEach((child)=>{disposeObject(child)});
            throw new Error('Error updating area in database!');
        }
        existing_parent.userData.cg_db_data = db_response;
        canvasDomElement.dispatchEvent(customEvents.saveCustomGeometries);
    }

    // Dispose the previous existing geometry and material
    existing_roof.children.forEach((child)=>{
        disposeObject(child);
    })
}

function getGreenRoofChildByName(parent, name){
    let child;
    try{
        switch (name) {
            case "cg_base":
                child = parent.children[0];
                break;
            case "cg_top":
                child = parent.children[1];
                break;
            default:
                child = null;
                break;
        }
    }catch(error){
        console.log("Error getting child by name: ", error);
        child = null;        
    }
    return child;
}

function createLineElement(plane, vertices, line_element_specs){
    // const point_helper_1 = (line_element_specs.h-line_element_specs.h_1)/Math.tan(line_element_specs.alpha*Math.PI/180.0)
    // const start_point = new THREE.Vector2(0.0, 0.0);
    // const shape = new THREE.Shape([
    //     start_point,
    //     new THREE.Vector2(0, line_element_specs.h),
    //     new THREE.Vector2(line_element_specs.b_1, line_element_specs.h),
    //     new THREE.Vector2(line_element_specs.b_1+ point_helper_1, line_element_specs.h-line_element_specs.h_1),
    //     new THREE.Vector2(line_element_specs.b- line_element_specs.b_1 - point_helper_1, line_element_specs.h-line_element_specs.h_1),
    //     new THREE.Vector2(line_element_specs.b- line_element_specs.b_1, line_element_specs.h),
    //     new THREE.Vector2(line_element_specs.b, line_element_specs.h),
    //     new THREE.Vector2(line_element_specs.b, 0.0),
    //     start_point,
    // ]);

    // // Create the linear spline curve
    // const points = vertices.map(v => new THREE.Vector3(v[0], v[1], v[2]));
    // const curve = new THREE.CatmullRomCurve3(points, false, 'catmullrom', 0.0);
    // const steps = vertices.length*100;

    // const extrudeSettings = {
    //     steps: steps,
    //     bevelEnabled: false,
    //     extrudePath: curve
    // };

    // const extruded_line_segment = new THREE.ExtrudeGeometry(shape, extrudeSettings);
    // const material = new THREE.MeshBasicMaterial( { color: colorPalette.standard_line_elements} );
    // const mesh = new THREE.Mesh( extruded_line_segment, material ) ;
    // return mesh;
    const plane_position = new THREE.Vector3();
    const plane_rotation = new THREE.Quaternion();
    const plane_scale = new THREE.Vector3(1,1,1);
    plane.matrixWorld.decompose(plane_position, plane_rotation, new THREE.Vector3());
    const plane_transformation_matrix_unscaled = new THREE.Matrix4().compose(plane_position, plane_rotation, plane_scale);
    const [points_on_xy, points_on_xy_list] = transformVerticesToXyPlane(plane_transformation_matrix_unscaled, vertices.flat())
    const polygon_vertices = offsetPolyline(points_on_xy, line_element_specs.b);
    // create extruded geometry
    const plane_shape = new THREE.Shape(polygon_vertices);
    const extrudeSettings = {depth: line_element_specs.h, bevelEnabled: false};
    const roof_geometry = new THREE.ExtrudeGeometry( plane_shape, extrudeSettings );
    roof_geometry.applyMatrix4(plane_transformation_matrix_unscaled);

    // const gravel_Texture = new THREE.TextureLoader().load('/gravel-texture.jpg', (texture) => {
    //     texture.colorSpace = THREE.SRGBColorSpace;
    //     texture.wrapS = THREE.RepeatWrapping;
    //     texture.wrapT = THREE.RepeatWrapping;
    //     texture.repeat.set(1, 1); // Adjust these values as needed
    // } );
    // const material = new THREE.MeshLambertMaterial({map: gravel_Texture, shadowSide: THREE.DoubleSide, side: THREE.DoubleSide, flatShading: false});
    const material = new THREE.MeshStandardMaterial({color: colorPalette.standard_line_elements, roughness: 0.85, metalness: 0.25,shadowSide: THREE.DoubleSide, side: THREE.DoubleSide, flatShading: true});

    // const material = new THREE.MeshBasicMaterial( { color: colorPalette.standard_line_elements} );
    const mesh = new THREE.Mesh( roof_geometry, material ) ;
    return mesh;
}

function offsetPolyline(vertices, distance) {
    // Function to compute the normal vector for a line segment in 2D
    function computeNormal(p1, p2) {
        const direction = new THREE.Vector2().subVectors(p2, p1);
        const normal = new THREE.Vector2(-direction.y, direction.x).normalize().multiplyScalar(distance);
        return normal;
    }

    function findIntersection(l1, l2) {
        // Line 1: p1 to p2
        const p1 = l1[0];
        const p2 = l1[1];

        const A1 = p2.y - p1.y;
        const B1 = p1.x - p2.x;
        const C1 = A1 * p1.x + B1 * p1.y;
    
        // Line 2: p3 to p4
        const p3 = l2[0];
        const p4 = l2[1];
        const A2 = p4.y - p3.y;
        const B2 = p3.x - p4.x;
        const C2 = A2 * p3.x + B2 * p3.y;
    
        const denominator = A1 * B2 - A2 * B1;
    
        // Lines are parallel if denominator is 0
        if (denominator === 0) {
            return null; // No intersection
        }
    
        const intersectX = (B2 * C1 - B1 * C2) / denominator;
        const intersectY = (A1 * C2 - A2 * C1) / denominator;
    
        return new THREE.Vector2(intersectX, intersectY);
    }

    const offsetVertices = [];

    // Process each segment to create offset lines
    for (let i = 0; i < vertices.length - 1; i++) {
        const p1 = vertices[i];
        const p2 = vertices[i + 1];

        const normal = computeNormal(p1, p2);

        const offsetP1 = p1.clone().add(normal);
        const offsetP2 = p2.clone().add(normal);

        offsetVertices.push([offsetP1, offsetP2]);
    }

    // In case the line has only two vertices there is no need for intersections
    if (vertices.length < 2){
        const polygonVertices = [...vertices, offsetVertices[0][1],offsetVertices[0][0], vertices[0]]
        return polygonVertices;
    }

    const offsetVerticesIntersected = [];
    let polygonVertices;

    // Check if polyline is closed
    const closed_polyline = vertices[0].distanceTo(vertices[vertices.length-1]) < 0.05;
    if(closed_polyline){
        offsetVertices.push(offsetVertices[0]);
    }else{
        offsetVerticesIntersected.push(offsetVertices[0][0]);
    }

    // Create intersection points
    for (let i = 0; i < offsetVertices.length - 1; i++) {
        const intersection = findIntersection(offsetVertices[i], offsetVertices[i + 1]);
        if (intersection) {
            offsetVerticesIntersected.push(intersection);
        }
        else{
            console.log("NO Intersection found, something went wrong...")
        }
    }

    // Note: If the polyline is closed this method creates a structure that is an open ring, not a closed one. 
    // In practice this opening, won't be visible. However, in case textures are applied, this might lead to unexpected visual results
    if(closed_polyline){
        offsetVerticesIntersected.reverse();
        offsetVerticesIntersected.push(offsetVerticesIntersected[0]);
        polygonVertices = [...vertices, ...offsetVerticesIntersected];

    }else{
        offsetVerticesIntersected.push(offsetVertices[offsetVertices.length - 1][1]);
        offsetVerticesIntersected.reverse();
        polygonVertices = [...vertices, ...offsetVerticesIntersected];
        polygonVertices.push(vertices[0]);
    }

    // Return the polygon vertices
    return polygonVertices;
}

function transformVerticesToXyPlane(plane_transformation_matrix, vertices){
    // transform to 2D-Space first
    const inv_transform = new THREE.Matrix4();
    inv_transform.copy(plane_transformation_matrix);
    inv_transform.invert();

    const points_on_xy = [];
    const points_on_xy_list = [];
    for (let i = 0; i < vertices.length; i = i + 3) {
        const vec_helper = new THREE.Vector3(vertices[i],vertices[i+1], vertices[i+2]);
        vec_helper.applyMatrix4(inv_transform);
        points_on_xy.push(new THREE.Vector2(vec_helper.x, vec_helper.y));
        points_on_xy_list.push([vec_helper.x, vec_helper.y])
    }
    return [points_on_xy, points_on_xy_list]
}

function uvMapping(geometry){
    // NOTE: This function still needs to be improved!

    geometry.computeBoundingBox();
    const min = geometry.boundingBox.min;
    const max = geometry.boundingBox.max;

    // Get the position attribute from the geometry
    const position = geometry.attributes.position;
    const uv = new Float32Array(position.count * 2);

    // Determine the range of the coordinates
    const range = new THREE.Vector3();
    range.subVectors(max, min);

    // Apply UV mapping
    for (let i = 0; i < position.count; i++) {
        const x = position.getX(i);
        const y = position.getY(i);
        const z = position.getZ(i);

        // Map the position to UVs in a planar way (e.g., XY plane)
        uv[i * 2] = (x - min.x) / range.x;
        uv[i * 2 + 1] = (y - min.y) / range.y;  // For XY plane
        // Uncomment one of the lines below if your mesh is aligned differently
        // uv[i * 2 + 1] = (z - min.z) / range.z;  // For XZ plane
        // uv[i * 2 + 1] = (z - min.z) / range.z;  // For YZ plane
    }

    // Assign the UVs to the geometry
    geometry.setAttribute('uv', new THREE.BufferAttribute(uv, 2));
}


function generateRandomPointsInPolygon(jstsPolygon, numPoints) {
    const points = [];

    const bbox = jstsPolygon.getEnvelopeInternal();

    while (points.length < numPoints) {
        const x = bbox.getMinX() + Math.random() * (bbox.getMaxX() - bbox.getMinX());
        const y = bbox.getMinY() + Math.random() * (bbox.getMaxY() - bbox.getMinY());

        // Create a point from the random coordinates
        const point = new jsts.geom.Coordinate(x, y);
        const geomFactory = new jsts.geom.GeometryFactory();
        const jstsPoint = geomFactory.createPoint(point);

        // Check if the generated point is within the polygon
        if (jstsPolygon.contains(jstsPoint)) {
            points.push([x, y]);
        }
    }

    return points;
}


/**
 * This function creates a three.js sphere for snapping points
 *
 * @param {THREE.Vector3} center - The coordinates of the sphere center
 * @return {[THREE.SphereGeometry, THREE.MeshLambertMaterial]} The sphere geometry and its material
 */
function createSphere(center){
    const radius = 0.2;
    const sphere_geometry = new THREE.SphereGeometry( radius, 16, 8 ); //32 16
    sphere_geometry.translate(center.x,center.y,center.z);
    // const sphere_material = new THREE.MeshLambertMaterial( { color: 0xffffff } );// new THREE.MeshBasicMaterial( { color: 0xffff00 } );
    const sphere_material = new THREE.MeshNormalMaterial();//{transparent: true, opacity: 0.5} new THREE.MeshBasicMaterial( { color: 0xffff00 } );
    const sphere = new THREE.Mesh( sphere_geometry, sphere_material );

    // Object that should always appear on top
    sphere.renderOrder = 1;
    sphere.material.depthTest = false;
    sphere.material.depthWrite = false;
    return sphere;
}

/**
 * This function changes the material and/or size of a three.js sphere in order to mark it as fixed
 *
 * @param {THREE.Mesh} sphere - The coordinates of the sphere center
 * @return {void}
 */
function changeSphereAppearance(sphere){
    // sphere.geometry.computeBoundingSphere();
    const center = new THREE.Vector3(sphere.geometry.boundingSphere.center.x,
                                        sphere.geometry.boundingSphere.center.y,
                                        sphere.geometry.boundingSphere.center.z
    );
    const newGeometry = new THREE.SphereGeometry( 0.1, 16, 8 );
    newGeometry.translate(center.x,center.y,center.z);
    sphere.geometry.dispose();
    sphere.geometry = newGeometry;

    sphere.material.dispose();
    sphere.material = new THREE.MeshBasicMaterial( { color: colorPalette.polygon_vertex } ); // transparent: true, opacity: 0.9,
    // Object that should always appear on top
    sphere.renderOrder = 1;
    sphere.material.depthTest = false;
    sphere.material.depthWrite = false;

}

function disposeObject(object){
    // object has to be THREE.Mesh

    object.geometry.dispose();

    if(Array.isArray(object.material)){
        for(const material of object.material){
            if(material.map) material.map.dispose();
            material.dispose();
        }
    }else{
        if(object.material.map) object.material.map.dispose();
        object.material.dispose();
    }

    object.removeFromParent();
}

function disposeRecursive(object){
    while(object.children.length > 0){
        disposeRecursive(object.children[0]);
    }
    if(object.geometry) object.geometry.dispose();
    if(Array.isArray(object.material)){
        for(const material of object.material){
            if(material.map) material.map.dispose();
            material.dispose();
        }
    }else if(object.material){
        if(object.material.map) object.material.map.dispose();
        object.material.dispose();
    }
    object.removeFromParent();
}

/**
 * Checks if a set of 2D points forms a simple polygon (no self-intersections).
 *
 * @param {Array<Array<number>>} points2D - An array of points, where each point is an [x, y] pair.
 * @return {boolean} A boolean indicating whether the polygon is simple.
 */
function simplePolygonCheck(points2D) {
    // Create a GeoJSON Polygon object
    const geojsonPolygon = {
        type: "Polygon",
        coordinates: [points2D]
    };

    try {
        // Initialize JSTS GeoJSONReader
        const reader = new jsts.io.GeoJSONReader();

        // Parse the GeoJSON Polygon into a JSTS Polygon object
        const polygon = reader.read(geojsonPolygon);

        // Use IsSimpleOp to check the simplicity of the polygon
        const isSimple = polygon.isSimple();
        return isSimple;
    } catch (error) {
        // Rethrow any errors encountered during validation
        console.error(error);
        return false;
    }
}
  

function relationOf2Polygons(vertices_p1, vertices_p2) {
    // Create a GeoJSON reader
    const reader = new jsts.io.GeoJSONReader();

    // Convert vertices to GeoJSON format
    const polygonGeoJSON1 = { "type": "Polygon", "coordinates": [vertices_p1] };
    const polygonGeoJSON2 = { "type": "Polygon", "coordinates": [vertices_p2] };

    // Read GeoJSON into JSTS polygons
    const polygon1 = reader.read(polygonGeoJSON1);
    const polygon2 = reader.read(polygonGeoJSON2);

    // 0. Check if polygons are disjoint
    const disjoint = polygon1.disjoint(polygon2);
    if (disjoint) {
        console.log('Polygons disjoint!');
        return polygon_relations.disjoint;
    }

    // 1. Check if one polygon contains the other
    const contains = polygon1.contains(polygon2);
    // const containsReverse = polygon2.contains(polygon1);

    if (contains) {
        console.log('One polygon is completely inside the other.');
        return polygon_relations.included;
    }

    // 2. Check if polygons intersect and have a common area
    const intersection = polygon1.intersection(polygon2);

    if (!intersection.isEmpty() && intersection.getGeometryType() === 'Polygon') {
        // Calculate the area
        const area = intersection.getArea();
        console.log('Polygons intersect with a resulting common area.');
        console.log(`Intersection Area: ${area}`);
        if(area > intersection_threshold){
            return polygon_relations.intersect
        }else{
            return polygon_relations.disjoint
        }
    } else {
        return polygon_relations.disjoint;
    }
}

function polygonDifference(vertices_p1, vertices_p2) {
    // Create a GeoJSON reader
    const reader = new jsts.io.GeoJSONReader();

    // Convert vertices to GeoJSON format
    const polygonGeoJSON1 = { "type": "Polygon", "coordinates": [vertices_p1] };
    const polygonGeoJSON2 = { "type": "Polygon", "coordinates": [vertices_p2] };

    // Read GeoJSON into JSTS polygons
    const polygon1 = reader.read(polygonGeoJSON1);
    const polygon2 = reader.read(polygonGeoJSON2);

    // Perform the difference operation
    const difference = polygon1.difference(polygon2);

    // Extract the exterior coordinates from the resulting geometry
    const exterior_coordinates = difference.getCoordinates().map(coord => [coord.x, coord.y]);

    console.log(difference);
    console.log(exterior_coordinates);

    return exterior_coordinates;
}

function calculatePolygonArea(outerBoundary, holes) {
    const geometryFactory = new jsts.geom.GeometryFactory();
  
    // Function to create a LinearRing from coordinates
    function createLinearRing(coordinates) {
      const coords = coordinates.map(coord => new jsts.geom.Coordinate(coord[0], coord[1]));
      return geometryFactory.createLinearRing(coords);
    }
  
    // Create the outer boundary LinearRing
    const outerRing = createLinearRing(outerBoundary);
  
    // Create the holes LinearRings
    const holeRings = holes.map(hole => createLinearRing(hole));
  
    // Create the polygon with holes
    const polygon = geometryFactory.createPolygon(outerRing, holeRings);
  
    // Calculate and return the area of the polygon
    return polygon.getArea();
  }

function createGLTFLoader() {

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath( '/libs/draco/gltf/' );

    const loader = new GLTFLoader();
    loader.setDRACOLoader( dracoLoader );
    loader.setMeshoptDecoder( MeshoptDecoder );

    return loader;
}

function mainDirection(vector) {
    const max = Math.max(Math.abs(vector.x), Math.abs(vector.y), Math.abs(vector.z));
    if (Math.abs(vector.x) === max) {
        return 'x';
    } else if (Math.abs(vector.y) === max) {
        return 'y';
    } else {
        return 'z';
    }
}

/**
 * This function calculates the z value for a given x and y according to the Gaussian function.
 *
 * @param {number} x - The x coordinate of the point.
 * @param {number} y - The y coordinate of the point.
 * @param {number} a - The height of the peak.
 * @param {number} sigmaX - The standard deviation along the x direction.
 * @param {number} sigmaY - The standard deviation along the y direction.
 * @return {number} The z value for the given point according to the Gaussian function.
 */
function gaussian(x, y, a, sigmaX, sigmaY=sigmaX) {
    let z = a * Math.exp( - (Math.pow(x, 2) / (2 * Math.pow(sigmaX, 2))
                        + Math.pow(y, 2) / (2 * Math.pow(sigmaY, 2))));
    return z;
}

/**
 * This function calculates the z value for a given x and y according to the polynomial approximation of the Gaussian function.
 *
 * @param {number} x - The x coordinate of the point.
 * @param {number} y - The y coordinate of the point.
 * @param {number} a - The height of the peak.
 * @param {number} sigmaX - The standard deviation along the x direction.
 * @param {number} sigmaY - The standard deviation along the y direction.
 * @return {number} The z value for the given point according to the polynomial approximation of the Gaussian function.
 */
function gaussianApproximation(x, y, a, sigmaX, sigmaY=sigmaX) {
    let z = a * (1 - (Math.pow(x, 2) / (2 * Math.pow(sigmaX, 2)) + Math.pow(y, 2) / (2 * Math.pow(sigmaY, 2)))
                    + (Math.pow(x, 4) / (24 * Math.pow(sigmaX, 4)) + Math.pow(y, 4) / (24 * Math.pow(sigmaY, 4))));
    return z;
}

/**
 * This function estimates the radius of the area where the Gaussian function will be above a certain tolerance.
 *
 * @param {number} a - The height of the peak.
 * @param {number} sigma - The standard deviation.
 * @param {number} tolerance - The tolerance.
 * @return {number} The estimated radius of the area where the Gaussian function will be above the tolerance.
 */
function estimateInfluenceRadius(a, sigma, tolerance) {
    let ratio = tolerance / a;
    let numSigmas;
    if (ratio >= 0.607) {
        numSigmas = 1;
    } else if (ratio >= 0.135) {
        numSigmas = 2;
    } else if (ratio >= 0.011) {
        numSigmas = 3;
    } else {
        numSigmas = 4; // You can add more cases if needed
    }
    return numSigmas * sigma;
}

/**
* This function creates reverse lookup to get the assets placed on a face that contains given vertex. 
*
* @param {Object} asset_references - All placed assets on this green_roof object (key = asset_name, value: faceIndex for face the asset is placed on)
* @param {THREE.BufferGeometry} geometry - The geometry where the assets are placed on
* @return {Map} - Reverse lookup Map
*/
function createReverseLookupAssets(asset_references, geometry){
    const vertexToAssetsMap = new Map();
    const index_buffer = geometry.getIndex();
    for (const [objectId, faceIndex] of Object.entries(asset_references)) {
        const start_index = faceIndex*3;
        const vertices = [index_buffer.array[start_index], index_buffer.array[start_index+1], index_buffer.array[start_index+2]]
        vertices.forEach(vertex => {
            if (!vertexToAssetsMap.has(vertex)) {
                vertexToAssetsMap.set(vertex, new Set());
            }
            vertexToAssetsMap.get(vertex).add(objectId);
        });
    }
    return vertexToAssetsMap;
}

/**
 * Checks if a point is inside a circle.
 *
 * @param {number} px - The x-coordinate of the point.
 * @param {number} py - The y-coordinate of the point.
 * @param {number} cx - The x-coordinate of the circle's center.
 * @param {number} cy - The y-coordinate of the circle's center.
 * @param {number} r - The radius of the circle.
 * @returns {boolean} - True if the point is inside the circle, otherwise false.
 */
function pointInCircle(px, py, cx, cy, r) {
    return (px - cx) ** 2 + (py - cy) ** 2 <= r ** 2;
}
  
/**
 * Calculates the distance between a point and the center of a circle.
 *
 * @param {number} px - The x-coordinate of the point.
 * @param {number} py - The y-coordinate of the point.
 * @param {number} cx - The x-coordinate of the circle's center.
 * @param {number} cy - The y-coordinate of the circle's center.
 * @returns {number} - The distance between the point and the center of the circle.
 */
function distance2D(px, py, cx, cy) {
    return Math.sqrt((px - cx) ** 2 + (py - cy) ** 2);
}


// API calls to insert roof data in database
async function createAreaInDatabase(area_data, project_id, hydraulic_type){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.post(api_endpoints.areas(project_id),area_data,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }}
    ).then(async (response)=>{
        const hydraulic_id = await createHydraulicElementForAreaInDatabase(response.data, project_id, hydraulic_type);
        console.log("Hydrualic ID:", hydraulic_id);
        return [response.data, hydraulic_id]
    })
    .catch(error => {
        console.log(error);
        return [null, null]
    })
}

async function updateAreaInDatabase(area_data, project_id, area_id){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.put(api_endpoints.areas_id(project_id, area_id),area_data,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }}
    ).then(response=>{
        console.log("PUT Area:", response.data)
        return response.data;
    })
    .catch(error => {
        console.log(error);
        return null;
    })
}

async function deleteAreaInDatabase(project_id, area_id, hydraulic_id){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.delete(api_endpoints.areas_id(project_id, area_id),{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }}
    ).then(async response=>{
        await deleteHydraulicElementForAreaInDatabase(project_id, hydraulic_id);
        console.log("DELETE Area:",response.data)
        return true
    })
    .catch(error => {
        console.log(error);
        return false
    })
}                       


async function createSubAreasInDatabase(subarea_data, project_id, area_id){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.post(api_endpoints.subareas(project_id, area_id), subarea_data,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }}
    ).then(response=>{
        console.log("Sub Area Response".toUpperCase(), response.data)
        return response.data
    })
    .catch(error => {
        console.log(error);
        return null
    })
}

async function createHydraulicElementForAreaInDatabase(area_data, project_id, hydraulic_type){
    const token = window.localStorage.getItem('climagruen_token');
    const hydraulic_area_data = {
        name: area_data.name,
        area_id: area_data.id,
        type: hydraulic_type.description,
        areatype: "D",
        surfacetype: "A4",
        inclination: 0.0,
        outlets: [
            {
            "height": 0.0,                  
            "maxQ": 1.0                     
            }        
        ],
        irrigationtype: null    
    }
    const jsonString = JSON.stringify(hydraulic_area_data);
    const hydraulic_component_data = {data: jsonString, geometry_asset_id:0};
    return axios.post(
        api_endpoints.hydraulic_elements(project_id), 
        hydraulic_component_data,
        {
            params: { type_id: hydraulic_type.id },
                
            headers: {
                            'Authorization': `Bearer ${token}`
                        }
        }
    ).then(response=>{
        console.log(response.data)
        return response.data.id
    })
    .catch(error => {
        console.log(error);
        return null
    })
}
async function deleteHydraulicElementForAreaInDatabase(project_id, hydraulic_id) {

    const token = window.localStorage.getItem('climagruen_token');
    return axios.delete(
        api_endpoints.hydraulic_elements_id(project_id, hydraulic_id),
        { headers: { 'Authorization': `Bearer ${token}` }}
    )
    .then(response => {
        console.log("DELETE", response.data);
        return true;
    })
    .catch(error => {
        console.log(error);
        return false;
    });
}
function createBaseHydraulicElementInDatabase(name, hydraulic_type, project_id){
    const token = window.localStorage.getItem('climagruen_token');
    const hydraulic_component_data_helper = {
        name: name,
        type: hydraulic_type.description,
    }
    if(hydraulic_type.description === "urbandrainage") hydraulic_component_data_helper.urbandrainagetype = "rainwater";

    const jsonString = JSON.stringify(hydraulic_component_data_helper);
    const hydraulic_component_data = {data: jsonString, geometry_asset_id:0};
    axios.post(
        api_endpoints.hydraulic_elements(project_id), 
        hydraulic_component_data,
        {
            params: { type_id: hydraulic_type.id },
                
            headers: {
                            'Authorization': `Bearer ${token}`
                        }
        }
    )
    .catch(error => {
        console.log(error);
    })
}

function updateHydraulicElementInDatabase(hydraulic_id, project_id, new_name){
    const token = window.localStorage.getItem('climagruen_token');
    axios.get(
        api_endpoints.hydraulic_elements_id(project_id, hydraulic_id),
        { headers: { 'Authorization': `Bearer ${token}` } }
    )
    .then(response => {
        const component_data = response.data; // this is a JSON string so it needs to be parsed
        const jsonObject = JSON.parse(component_data.data);
        jsonObject.name = new_name;
        axios.put(
            api_endpoints.hydraulic_elements_id(project_id, hydraulic_id),
            { data: JSON.stringify(jsonObject) },
            { headers: { 'Authorization': `Bearer ${token}` } }
        )
        .catch(error => {
            console.log(error);
        });
    })
    .catch(error => {
        console.log(error);
    });
}

async function loadAssetsFromDatabase(individual_assets_shallow, asset_loader) {
    const token = window.localStorage.getItem('climagruen_token');
    const lod = 300;

    // Step 1: Group asset wrappers by their unique IDs
    const assetWrappersById = individual_assets_shallow.reduce((acc, wrapper) => {
        const id = wrapper.userData.cg_type_details.id;
        if (!acc[id]) acc[id] = [];
        acc[id].push(wrapper);
        return acc;
    }, {});

    // Step 2: Create an array of promises for loading unique assets
    const loadAssetPromises = Object.entries(assetWrappersById).map(async ([id, wrappers]) => {
        // Fetch and load the asset for the given ID
        try {
            const response = await axios.get(api_endpoints.plants_get_glb_id(id, lod), {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'arraybuffer',
            });

            // Load the array buffer into the asset loader and add it to all wrappers
            await asset_loader.loadArraybufferOnStartUp(response.data, wrappers[0]);
            wrappers.forEach(wrapper => {
                const cloned_asset = asset_loader.loadedAssets[id].clone();
                cloned_asset.position.set(0,0,0);
                wrapper.add(cloned_asset)
            });
        } catch (error) {
            console.error(`Failed to load asset for id ${id}:`, error);
        }
    });

    // Step 3: Wait for all promises to complete
    await Promise.all(loadAssetPromises);
}

function computeGroupBounding(group) {
    const box = new THREE.Box3().setFromObject(group);

    const sphere = new THREE.Sphere();
    box.getBoundingSphere(sphere);

    const sphere_geometry = new THREE.SphereGeometry(0.1,3,2)
    const material = new THREE.MeshBasicMaterial( { color: 0xffff00 } ); 
    const sphere_mesh = new THREE.Mesh( sphere_geometry, material );
    sphere_mesh.position.copy(sphere.center);
    console.log(sphere.center)
    return sphere_mesh;
}

function createPlaneObject(height, name, default_level = false){
    // Create a large PlaneGeometry to simulate an infinite plane
    const planeGeometry = new THREE.PlaneGeometry(level_dimension, level_dimension); // Very large plane
    const planeMaterial = new THREE.MeshBasicMaterial({color: 'white', side: THREE.DoubleSide, transparent: true, opacity: 0.2});
    const plane = new THREE.Mesh(planeGeometry, planeMaterial);
    // Rotate the plane to be parallel to the XZ plane (y=0)
    plane.rotation.x = - Math.PI / 2; // Rotate 90 degrees around X axis
    plane.position.y = height; // Set the y position to 0 (or any value)
    plane.userData.cg_level_name = name;
    plane.userData.cg_type = objectTypes.level;
    plane.name = default_level ? name : uuidv4();
    return plane;
}


function setLayerRecursively(object, layer) {
    object.layers.set(layer);
    if (object.children) {
        object.children.forEach(child => setLayerRecursively(child, layer));
    }
}

/**
 * Traverses up the parent hierarchy to find an ancestor with a specific userData property.
 * @param {THREE.Object3D} object - The starting object to traverse from.
 * @param {string} propertyName - The userData property name to search for.
 * @returns {THREE.Object3D|null} - The ancestor object if found, otherwise null.
 */
function findAncestorWithProperty(object, propertyName) {
    let currentObject = object;

    while (currentObject.parent) { // Continue until there is no parent
        if (currentObject.userData && currentObject.userData[propertyName] !== undefined) {
            return currentObject;
        }
        currentObject = currentObject.parent;
    }

    return null; // No matching ancestor found
}
  
function changeInnerVertexColor(green_roof_object, sub_area_id , color, simulation_id) {
    // Find the child that contains the sub_area_id
    let sub_roof;
    let vertex_index;
    for (const sub_roof_candidate of green_roof_object.children) {
        const id_range = sub_roof_candidate.userData.cg_sub_area_id_range[simulation_id];
        if (!id_range) continue;

        const sub_roof_contains_id = sub_area_id >= id_range.start_id && sub_area_id <= id_range.end_id;
        if(sub_roof_contains_id){
            sub_roof = sub_roof_candidate;

            // get the correct vertex index
            vertex_index = sub_area_id - id_range.start_id;
            break;
        }
    }

    if(!sub_roof) return;

    // get the top mesh
    const meshed_top = getGreenRoofChildByName(sub_roof, "cg_top");

    // change the color
    const geometry = meshed_top.geometry;
    let colors = geometry.attributes.color;
    if(!colors){
        console.log("No colors found, creating new ones");
        meshed_top.material.color.set(0xffffff); // needs to be set to white because it will get multiplied with the vertex color
        meshed_top.material.vertexColors = true;
        const color_array = new Float32Array( geometry.attributes.position.count * 3 );
        geometry.setAttribute( 'color', new THREE.BufferAttribute( color_array, 3 ) );
        colors = geometry.attributes.color;
    }
    colors.setXYZ( vertex_index, color.r, color.g, color.b );
}

function changeBoundaryVertexColor(meshed_top) {
    // get the top mesh
    const boundary_vertices = meshed_top.userData.vertices_index_info.boundaries;
    const geometry = meshed_top.geometry;
    const index_array = geometry.index.array;
    const colors = geometry.attributes.color;
    if(!colors) return;
    let last_color;
    for (let index = boundary_vertices.start_index; index < boundary_vertices.end_index; index++) {
        const triangle_index = index_array.indexOf(index);
        const position_in_triangle = triangle_index % 3;
        let index_v2, index_v3;
        if(position_in_triangle === 0){
            index_v2 = index_array[triangle_index+1];
            index_v3 = index_array[triangle_index+2]; 
        }else if(position_in_triangle === 1){
            index_v2 = index_array[triangle_index-1];
            index_v3 = index_array[triangle_index+1];
        } else if (position_in_triangle === 2){
            index_v2 = index_array[triangle_index-2];
            index_v3 = index_array[triangle_index-1];
        }else{
            console.log("Something went wrong with the position in triangle")
            continue;
        }
        if(index_v2 < boundary_vertices.start_index && index_v3 < boundary_vertices.start_index){
            // Interpolation factor (0.0 to 1.0)
            const v2_red = colors.getX(index_v2);
            const v2_green = colors.getY(index_v2);
            const v2_blue = colors.getZ(index_v2);
            const v3_red = colors.getX(index_v3);
            const v3_green = colors.getY(index_v3);
            const v3_blue = colors.getZ(index_v3);

            const t = 0.5; // Midway between the two colors

            // Interpolate each component
            const r = v2_red + t * (v3_red - v2_red);
            const g = v2_green + t * (v3_green - v2_green);
            const b = v2_blue + t * (v3_blue - v2_blue);

            colors.setXYZ( index, r, g, b );
            last_color = [r,g,b];
        }else if(index_v2 < boundary_vertices.start_index){
            const r = colors.getX(index_v2);
            const g = colors.getY(index_v2);
            const b = colors.getZ(index_v2);
            colors.setXYZ( index, r, g, b );
            last_color = [r,g,b];
        }else if(index_v3 < boundary_vertices.start_index){
            const r = colors.getX(index_v3);
            const g = colors.getY(index_v3);
            const b = colors.getZ(index_v3);
            colors.setXYZ( index, r, g, b );
            last_color = [r,g,b];
        }else{
            if (last_color){
                colors.setXYZ( index, last_color[0], last_color[1], last_color[2] );
            }
            else{
                colors.setXYZ( index, 1.0, 1.0, 1.0 );  
            }
        }      
    }
    meshed_top.geometry.attributes.color.needsUpdate = true;
}

function deepCloneGroup(group) {
    // Clone the initial group
    const clonedGroup = group.clone();

    // Recursive function to clone geometries and attributes
    function cloneRecursive(object) {
        object.children.forEach((child) => {
            // If the child is a mesh, we clone its geometry and materials deeply
            if (child.isMesh) {
                child.geometry = child.geometry.clone();

                // Clone each buffer attribute in the geometry
                for (const attributeName in child.geometry.attributes) {
                    child.geometry.attributes[attributeName] = child.geometry.attributes[attributeName].clone();
                }

                // Clone any instanced attributes if using InstancedMesh
                if (child.instanceMatrix) {
                    child.instanceMatrix = child.instanceMatrix.clone();
                }

                // Clone the material if you want separate materials as well
                child.material = Array.isArray(child.material)
                    ? child.material.map((mat) => mat.clone())
                    : child.material.clone();
            }else{
                cloneRecursive(child);
            }


        });
    }

    // Start cloning from the top-level cloned group
    cloneRecursive(clonedGroup);

    return clonedGroup;
}


export{ PolylineDrawer,
        AssetLoader,
        ObjectModifier,
        drawingModes,
        customEvents,
        objectTypes,
        createGreenRoofObject,
        createComposedGreenRoofObject,
        ModelingInterfaceManager, 
        HolePlacementError,
        createGLTFLoader,
        default_values, createLineElement,
        AiToolManager,
        layers,
        setLayerRecursively,
        createBaseHydraulicElementInDatabase
}