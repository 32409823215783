<template>
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
      <div class="container-fluid">
        <!-- Brand Logo -->
        <RouterLink to="/welcome" class="navbar-brand">
          <img
            class="d-inline-block align-top logo"
            src="/open_terra_logo.svg"
            alt="open_terra_logo.svg"
          />
        </RouterLink>
  
        <!-- Toggler for Mobile View -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar"
          aria-controls="navbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <!-- Navbar Links -->
        <div class="collapse navbar-collapse" id="navbar">
          <!-- Left-aligned Nav Items -->
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <!-- Example of other nav items -->
            <!--
            <li class="nav-item">
              <RouterLink to="/canvas" class="nav-link">{{ $t('main_view_link') }}</RouterLink>
            </li>
            -->
  
            <!-- Modify Types Dropdown -->
            <!-- v-if="store.state.current_user.role === 'admin'" -->
            <li v-if="store.state.current_user && store.state.current_user.role === 'admin'" class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ $t('modify_types') }}
              </a>
              <ul class="dropdown-menu">
                <RouterLink
                  :to="{ name: 'modify_types', params: { type: 'vegetation' } }"
                  class="dropdown-item"
                >
                  {{ $t('modify_dropdown.vegetation') }}
                </RouterLink>
                <RouterLink
                  :to="{ name: 'modify_types', params: { type: 'vegetation_mix' } }"
                  class="dropdown-item"
                >
                  {{ $t('modify_dropdown.vegetation_mix') }}
                </RouterLink>
                <RouterLink
                  :to="{ name: 'modify_types', params: { type: 'layers' } }"
                  class="dropdown-item"
                >
                  {{ $t('modify_dropdown.layers') }}
                </RouterLink>
                <RouterLink
                  :to="{ name: 'modify_types', params: { type: 'materials' } }"
                  class="dropdown-item"
                >
                  {{ $t('modify_dropdown.materials') }}
                </RouterLink>
              </ul>
            </li>
          </ul>
  
          <!-- Right-aligned Nav Items -->
          <ul class="navbar-nav ms-auto mb-2 mb-md-0">
            <!-- Language Dropdown -->
            <li class="nav-item dropdown me-3">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="languageDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :title="$t('language')"
              >
                <font-awesome-icon icon="fa-solid fa-earth-europe" class="language-icon" />
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
                <li
                  v-for="(lang, index) in languages"
                  :key="index"
                  @click="changeLanguage(lang.code)"
                >
                  <a class="dropdown-item" href="#">
                    {{ lang.label }}
                  </a>
                </li>
              </ul>
            </li>
  
            <!-- User Icon with Badge and Dropdown -->
            <li class="nav-item dropdown">
              <a
                class="nav-link position-relative"
                href="#"
                id="userDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                :title="$t('user_profile')"
              >
                <!-- User Icon -->
                <font-awesome-icon icon="fa-solid fa-user" class="user-icon" />
  
                <!-- Badge -->
                <span class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                  <span class="visually-hidden">Profile Status</span>
                </span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                <li>
                    <!-- This is a mockup for the moment -->
                  <RouterLink to="/welcome" class="dropdown-item d-flex align-items-center gap-2">
                    <font-awesome-icon icon="fa-solid fa-gear" />
                    {{ $t('account_settings') }}
                  </RouterLink>
                </li>
                <li>
                  <button class="dropdown-item d-flex align-items-center gap-2" @click="logout">
                    <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
                    {{ $t('logout') }}
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </template>
  
  <script setup>
  import { RouterLink, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { api_endpoints } from '../js/api_endpoints.js';
  import { useStore } from 'vuex';
  import axios from 'axios';
  
  // Initialize Vuex store and router
  const store = useStore();
  const router = useRouter();
  
  // Initialize internationalization
  const { t, locale } = useI18n({ useScope: 'global' });
  
  // Define available languages
  const languages = [
    { code: 'en', label: t('lang.en') },
    { code: 'de', label: t('lang.de') },
    { code: 'it', label: t('lang.it') },
  ];
  
  // Function to change language
  function changeLanguage(lang) {
    locale.value = lang; // Update locale
  }
  
  // Logout function
  function logout() {
    const token = window.localStorage.getItem('climagruen_token');
    axios
      .post(api_endpoints.logout, '', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log('Logout successful...');
        console.log(response.data);
      })
      .catch((error) => {
        console.log(
          'Logout failed... The current token might still be valid, but it will be removed from the local storage!'
        );
        console.log(error);
      })
      .finally(() => {
        router.push({ name: 'login' });
        store.commit('updateUserInfo', { info: null, auth: false });
        window.localStorage.removeItem('climagruen_token');
        console.log('Logout completed on client-side...');
      });
  }
  </script>
  
  <style scoped>
  .logo {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
  }
  
  #navbar {
    grid-area: nav;
  }
  
  .nav-link {
    border-radius: 0.5rem;
  }
  
  .nav-link:hover {
    background-color: rgba(0, 0, 0, 0.3); /* Black with 30% opacity */
  }
  
  .navbar-brand:hover {
    background-color: transparent;
  }
  
  /* User Icon */
  .user-icon {
    font-size: 1.5rem; /* Adjust icon size as needed */
    color: #ffffff;
  }
  
  /* Adjust Dropdown Menu Alignment */
  .dropdown-menu-end {
    right: 0;
    left: auto;
  }
  .language-icon{
    font-size: 1.5rem;
    color: #ffffff;
  }
  </style>
  