<template>
    <div class="d-flex align-items-start justify-content-between">
        <div ref="chart" class="chart"></div>
        <div v-if="crucial_values" class="d-flex flex-column text-dark p-3 rounded bg-light">
            <span class="h5" >{{ t('results.flooding_crucial') }}</span>
            <p>R[30]: {{ crucial_values.a30 }} m³</p>
            <p>R[100]: {{ crucial_values.a100 }} m³</p>
        </div>
    </div>
</template>
  
<script setup>
    import { ref, onMounted, } from 'vue'
    import * as echarts from 'echarts'

    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    const store = useStore();

    const { t } =  useI18n();

    const crucial_values = ref(null);
    
    // Reference to the chart DOM element
    const chart = ref(null);

    const rainDurations = [
                5,
                10,
                15,
                20,
                30,
                45,
                60,
                90,
                120,
                180,
                240,
                360,
                540,
                720,
                1080,
                1440,
                2880,
                4320,
                5760,
                7200,
                8640,
                10080
    ];

    onMounted(async () => {
        const selectedSimulation = store.state.simulations.selectedSimulation;
        const simulationResults = await store.dispatch('simulations/getSimulationResult', {simulationId: selectedSimulation, resultType: 'total'});
        crucial_values.value = simulationResults.flooding.crucial;
        setupChart(simulationResults);

    })


    const setupChart = (simData) => {
        // Extract and sort rain durations from the data
        const rainDurations = Object.keys(simData.flooding.volume.a30).map(Number).sort((a, b) => a - b);

        // Transform the data to the required format
        const a30Data = rainDurations.map(duration => simData.flooding.volume.a30[duration]);
        const a100Data = rainDurations.map(duration => simData.flooding.volume.a100[duration]);

        // Initialize the chart when the component is mounted
        const myChart = echarts.init(chart.value, 'light')

        const option = {
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    let tooltip = t('results.rain_duration').replace(" [min]", "") + " " + '<strong>' + params[0].axisValue + ' min </strong><br/>';
                    params.forEach(item => {
                        tooltip += item.marker + item.seriesName + ': ' + item.value[1].toFixed(2) + ' m³<br/>';
                    });
                    return tooltip;
                }
            },
            legend: {
                top: 'top',
                data: ['R[30]', 'R[100]']
            },
            xAxis: {
                name: t('results.rain_duration'),
                nameLocation: 'middle',
                nameGap: 30,
                type: 'value',
                // data: rainDurations
            },
            yAxis: {
                type: 'value',
                min: 0,
                axisLabel: {
                    formatter: '{value}'
                },
                name: t('results.flooding_volume'),
            },
            series: [
                {
                    name: 'R[30]',
                    type: 'line',
                    data: a30Data.map((value, index) => [rainDurations[index], value])
                },
                {
                    name: 'R[100]',
                    type: 'line',
                    data: a100Data.map((value, index) => [rainDurations[index], value])
                }
            ],
            graphic: {
                elements: [
                    {
                        type: 'text',
                        left: '15%',  // Adjust 'left' for horizontal position (can also use 'right')
                        top: '15%',   // Adjust 'top' for vertical position (can also use 'bottom')
                        style: {
                            text: 'R ' + t('results.rain_return_period'),  // <-- Place your content here
                            font: 'bold 14px Arial',  // Font styling
                            fill: '#000',  // Text color
                            align: 'center'  // Text alignment
                        }
                    }
                ]
            },
            dataZoom: [
                {
                    type: 'inside',
                    xAxisIndex: [0],
                    start: 0,
                    end: 100
                }
            ]
        }

        // Set the option for the chart
        myChart.setOption(option)
    }
</script>
  
<style scoped>

</style>