<template>
  <div
    id="confirm-email-mask"
    class="d-flex justify-content-center align-items-center"
  >
    <div class="card text-white bg-dark mb-3">
      <div class="card-header">
        {{ $t("confirm_email_page.confirm_email") }}
      </div>
      <div class="card-body">
        <div v-if="confirmed">
          <p>{{ $t("confirm_email_page.email_confirmed") }}</p>
          <router-link to="/login">
            <button class="btn btn-light">
              {{ $t("confirm_email_page.go_to_login") }}
            </button>
          </router-link>
        </div>
        <p v-else>
          {{ $t("confirm_email_page.email_not_confirmed") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { api_endpoints } from "../js/api_endpoints.js";
import axios from "axios";

const confirmed = ref(undefined);

onMounted(() => {
  // get token from url
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  if (!token) return console.error("No token found in URL");

  axios
    .get(api_endpoints.confirm_email(token))
    .then(async (response) => {
      console.log("Email confirmed successfully");
      confirmed.value = true;
    })
    .catch((error) => {
      console.log(error);
      confirmed.value = false;
    });
});
</script>

<style scoped>
#confirm-email-mask {
  grid-area: main;
}
</style>
