<template>
    <div
        id="results-legend-container"
        ref="containerRef"
        :style="{ top: position.top + 'px', left: position.left + 'px' }"
    >
        <div class="bar-header mb-1">
            <!-- Drag Handle Icon -->
            <div class="drag-handle" @mousedown="startDrag">
                <font-awesome-icon icon="fa-solid fa-up-down-left-right" class="drag-icon" />
            </div>

            <!-- Collapse Button -->
            <div class="d-flex bar-wrapper" @click="toggleCollapse">
                <font-awesome-icon icon="fa-solid fa-bars" class="bar-icon" />
            </div>
            <!-- Collapse Button -->
            <div v-if="!collapsed" class="d-flex refresh-btn" @click="refresh">
                <font-awesome-icon icon="fa-solid fa-rotate-left" />
            </div>
        </div>

        <div v-show="!collapsed">
            <!-- Dropdown for selecting results -->
            <SelectSimulation />

            <div v-if="selectedSimulation && selectedSimulationInfo.status === 'success'" class="d-flex flex-column mt-3 w-100">
                <div class="form-floating">
                    <select id="result-dropdown" v-model="selectedResult" @change="showResults" class="form-select">
                        <option v-for="result in results" :key="result.value" :value="result.value">{{ result.label }}</option>
                    </select>
                    <label for="result-dropdown" class="form-label">
                        {{ $t('results.resullt_type') }}
                    </label>
                </div>

                <!-- Color Map and Labels -->
                <div class="color-map-container mt-3">
                    <canvas ref="legendCanvas" class="legend-canvas"></canvas>
                    <div class="labels-container">
                        <div
                            v-for="label in labels"
                            :key="label.value"
                            class="legend-label"
                        >
                            {{ label.value }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import SelectSimulation from "../modals/results/SelectSimulation.vue";
import { useI18n } from "vue-i18n";

const store = useStore(); 
const { t } = useI18n();
const emit = defineEmits(["showResults", "hideResults"]);

const selectedSimulation = computed(() => store.state.simulations.selectedSimulation);
const selectedSimulationInfo = ref({});
const numLabels = 5;
const lut = ref(null);  
const results = [
    {value: "weight", label: t('results.weight')}, 
    {value: "irrigation", label: t('results.irrigation')},
    {value: "outflow_max", label: t('results.outflow_max')}, 
    // {value: "evaporation", label: t('results.evaporation_unit')}, 
    // {value: "height", label: t('results.height')}
];
const selectedResult = ref(null);
const labels = ref([]);
const legendCanvas = ref(null);
const collapsed = ref(false);

watch(selectedSimulation, selectSimulation);

// Default position refs
const position = ref({ top: 16, left: 0 });
const isDragging = ref(false);
const dragStart = ref({ x: 0, y: 0 });

const containerRef = ref(null);

onMounted(() => {
    // Ensure the component is positioned correctly within the viewport
    const containerWidth = containerRef.value?.offsetWidth || 200;
    const containerHeight = containerRef.value?.offsetHeight || 100;

    position.value.left = Math.max(window.innerWidth - containerWidth, 0); // 1rem from right, within viewport
    position.value.top = Math.min(Math.max(position.value.top, 0), window.innerHeight - containerHeight); // Within viewport vertically

    // Add a listener to update the position when the window is resized that checks if the position is still within the viewport if not set it to the edge
    window.addEventListener("resize", resetPosition);
});

onBeforeUnmount(() => {
    window.removeEventListener("resize", resetPosition);
});

const resetPosition =  () => {
        const containerWidth = containerRef.value?.offsetWidth || 200;
        const containerHeight = containerRef.value?.offsetHeight || 100;        
        position.value.left = Math.max(window.innerWidth - containerWidth, 0); // 1rem from right, within viewport
        position.value.top = Math.min(Math.max(position.value.top, 0), window.innerHeight - containerHeight); // Within viewport vertically
    }

const startDrag = (event) => {
    isDragging.value = true;
    dragStart.value = { x: event.clientX - position.value.left, y: event.clientY - position.value.top };
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", stopDrag);
};

const handleDrag = (event) => {
    if (!isDragging.value) return;
    position.value = {
        top: Math.max(Math.min(event.clientY - dragStart.value.y, window.innerHeight - (containerRef.value?.offsetHeight || 0)), 0), // Stay in viewport
        left: Math.max(Math.min(event.clientX - dragStart.value.x, window.innerWidth - (containerRef.value?.offsetWidth || 0)), 0), // Stay in viewport
    };
};

const stopDrag = () => {
    isDragging.value = false;
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", stopDrag);
};

const toggleCollapse = () => {
    collapsed.value = !collapsed.value;
};

async function selectSimulation() {
    selectedResult.value = null;
    if (!selectedSimulation.value) return;

    selectedSimulationInfo.value = store.state.simulations.simulations.find(sim => sim.id === selectedSimulation.value);
    if (selectedSimulationInfo.value.status !== 'success') {
        return;
    }
}

// Update the canvas and labels dynamically
const updateLegend = () => {
    if (!lut.value || !legendCanvas.value) return;

    // Update the canvas with the LUT
    const ctx = legendCanvas.value.getContext("2d");
    const lutCanvas = lut.value.createCanvas();
    ctx.clearRect(0, 0, legendCanvas.value.width, legendCanvas.value.height);
    ctx.drawImage(lutCanvas, 0, 0, legendCanvas.value.width, legendCanvas.value.height);

    // Calculate and update labels
    const minValue = lut.value.minV;
    const maxValue = lut.value.maxV;
    const step = (maxValue - minValue) / (numLabels - 1);
    labels.value = Array.from({ length: numLabels }, (_, i) => ({
        value: (maxValue - i * step).toFixed(2),
    }));
};

const showResults = async () => {
    // trigger the applycation of results
    const project_id = store.state.current_project.id;
    const simulationResults = await store.dispatch('simulations/getSimulationResult', {simulationId: selectedSimulation.value, resultType: 'total'});
    if(!simulationResults) return;
    // pass only params and greenroofs property to the simulation manager
    const {greenroofs, params} = simulationResults;
    const lookUpTable = await store.state.modeling_manager.applySimulationResults({greenroofs, params}, selectedSimulation.value, selectedResult.value, project_id);
    lut.value = lookUpTable;
    updateLegend();
    emit('showResults');
};

const refresh = () => {
    store.commit('simulations/SET_SELECTED_SIMULATION', null);
    lut.value = null;
    labels.value = [];
    legendCanvas.value = null;
    emit('hideResults');
};

</script>

<style scoped>
#results-legend-container {
    position: absolute;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #3c4454;
    padding: 5px;
    border-radius: 4px;
    color: #fff;
    min-width: 150px;
    z-index: 1050;
}

#results-legend-container:active {
    cursor: grabbing;
}

.bar-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-radius: 4px;
    padding: 5px;
}

.drag-handle {
    padding: 5px;
    border-radius: 4px;
    cursor: grab;
    margin-right: 10px;
}

.drag-handle:active {
    cursor: grabbing;
}

.bar-wrapper {
    cursor: pointer;
    width: max-content;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

}

.bar-wrapper:hover {
    background-color: #2a2f3a;
}

.bar-icon {
    transform: scaleX(3);
}

.drag-handle:hover {
    background-color: #2a2f3a;
}

.refresh-btn {
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 4px;

}
.refresh-btn:hover {
    background-color: #2a2f3a;
}

.color-map-container {
    display: flex;
    align-items: center;
    align-self: center;
}

.legend-canvas {
    width: 100px;
    height: 300px;
    margin-right: 10px;
}

.labels-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
}

.legend-label {
    font-size: 12px;
    padding: 1px 2px;
    border-radius: 2px;
    text-align: center;
}
</style>
