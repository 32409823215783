<template>
    <div class="form-floating">
        <select
            id="simulation-dropdown"
            class="form-select"
            v-model="selectedSimulationId"
            @change="handleSelection"
        >
            <option :value="null" disabled>
                {{ $t('results.select_simulation') }}
            </option>
            <option
                v-for="simulation in simulations"
                :key="simulation.id"
                :value="simulation.id"
                :disabled="disabledStatuses.includes(simulation.status)"
                :style="statusTextStyle(simulation.status)"
            >
                {{ simulation.name }} ({{ simulation.status }})
            </option>
        </select>
        <label for="simulation-dropdown" class="form-label">
            {{ $t('results.simulation') }}
        </label>
    </div>
</template>

<script setup>
    import { ref, onMounted, computed, onBeforeUnmount } from 'vue';
    import { useStore } from 'vuex';

    const store = useStore();

    const disabledStatuses = ['running', 'torun'];
    let intervalId;

    // Local state for the selected simulation ID
    const selectedSimulationId = computed({
        get: () => store.state.simulations.selectedSimulation,
        set: (value) => store.commit('simulations/SET_SELECTED_SIMULATION', value)
    });

    // Simulations fetched from the Vuex store
    const simulations = computed(() => store.state.simulations.simulations);

    // Fetch simulations on component mount
    onMounted(() => {
        const projectId = store.state.current_project?.id; // Assuming project_id is stored in the main store
        if (projectId) {
            store.dispatch('simulations/fetchSimulations', projectId);
            intervalId = setInterval(() => {
                store.dispatch('simulations/fetchSimulations', projectId);
            }, 30000); // Refresh every 30 seconds
        }
    });

    onBeforeUnmount(() => {
        clearInterval(intervalId);
    });

    // // Handle selection change and update the Vuex store
    // const handleSelection = () => {
    //     emit('simSelected', selectedSimulationId.value);
    // };

    // Determine the style for the option text
    const statusTextStyle = (status) => {
        switch (status?.toLowerCase()) {
            // case 'torun':
            //     return { color: 'blue' }; // Apply blue color for 'torun'
            // case 'running':
            //     return { color: 'orange' }; // Apply orange color for 'running'
            case 'success':
                return { color: 'green' }; // Apply green color for 'success'
            case 'error':
                return { color: 'red' }; // Apply red color for 'error'
        }
    };
</script>

<style scoped>
/* Optional: Add custom styles for the select and label if needed */
</style>
