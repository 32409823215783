<template>   
    <div v-show="props.chart_data" ref="chartRef" class="chart"></div>
    <span v-show="!props.chart_data">{{ $t('welcome_page.modify_project_modal.no_data_yet') }}</span>
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import * as echarts from 'echarts';
    import { useI18n } from 'vue-i18n';

    const { t } =  useI18n();


    const chartRef = ref(null);
    let chartInstance;
    const chart_data = {};


    const props = defineProps({
        chart_data: Object,
        simulation_type: String
    });

    watch(() => props.chart_data, (newVal) => {
        console.log("Simulation type changed", newVal);
        updateChart();
    });

    onMounted(()=>{
        chartInstance = echarts.init(chartRef.value, 'light');
        updateChart();
    })

    function updateChart() {
        let option;
        chartInstance.clear();
        console.log("Chart data", props.chart_data);
        if(!props.chart_data){
            return;
        }

        switch (props.simulation_type) {
            case 'statistical':
                if(!chart_data['statistical']){
                    setupPrecipitationChart(props.chart_data);
                }
                option = chart_data['statistical'];
                break;
            case 'blockrain':
                if(!chart_data['block_rain']){
                    setupBlockRainChart(props.chart_data);
                }
                option = chart_data['block_rain'];
                break;
            default:
                option = chart_data['statistical'];
        }
        chartInstance.setOption(option);
        chartInstance.resize();
    }


    const setupPrecipitationChart = (data)=>{
        const series_data = [];
        const series_default_options = {
            type: 'line',
            symbol: 'none',
        };
        const precipitation_data = data.dates.map((timestamp, index) => [timestamp, (data.precipitation[index] * 1000).toFixed(2)]); // Convert from m to mm (*1000)
        series_data.push({ ...series_default_options, name: t('results.precipitation_chart_title'), data: precipitation_data });
        const option = {
                        backgroundColor: '#FFFFFF', // Set background to white

                        tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                            return [pt[0], '10%'];
                            }
                        },
                        legend: {
                            data: series_data.map(series => series.name),
                            top: '40px'
                        },
                        toolbox: {
                            feature: {
                            dataZoom: {
                                yAxisIndex: 'none'
                            },
                            saveAsImage: {}
                            }
                        },
                        xAxis: {
                            type: 'time',
                            boundaryGap: false
                        },
                        yAxis: {
                            name: t('results.precipitation_chart_y'),
                            type: 'value',
                            boundaryGap: [0, '10%']
                        },
                        dataZoom: [
                            {
                            type: 'inside',
                            start: 0,
                            end: 100
                            }
                        ],
                        series: series_data
        };
        chart_data['statistical'] = option;

    }

    const setupBlockRainChart = (tableData) => {
        // Extract durations and frequencies
        const durations = Object.keys(tableData).map(Number).sort((a, b) => a - b);
        const frequencies = [
            ...new Set(
                durations.flatMap(duration => Object.keys(tableData[duration]).map(Number))
            )
        ].sort((a, b) => a - b);

        // Build series data
        const series = frequencies.map((freq, index) => ({
            name: `R [${freq}]`, // Legend entry for the frequency
            type: 'line',
            data: durations.map(duration => [duration, tableData[duration][freq] || 0]), // x (duration) and y (rain) values
            lineStyle: {
                width: 2,
                type: index % 3 === 0 ? 'dotted' :
                        index % 2 === 0 ? 'dashed' : 'solid',
            },
        }));

        // Define chart options
        const option = {
            tooltip: {
                trigger: 'axis',
                formatter: function (params) {
                    let tooltip = t('results.rain_duration').replace(" [min]", "")+" " + '<strong>'+params[0].axisValue + ' min </strong><br/>';
                    params.forEach(item => {
                        tooltip += item.marker + item.seriesName + ': ' + item.value[1].toFixed(2) + ' mm<br/>'; // Use item.value[1] for rain value
                    });
                    return tooltip;
                }
            },
            legend: {
                data: series.map(s => s.name),
                title: {
                    text: t('results.rain_return_period'), // Legend title
                    left: 'left'
                },
                top: 0
            },
            xAxis: {
                type: 'value', // Change to value for continuous axis
                name: t('results.rain_duration'),
                nameLocation: 'middle',
                nameGap: 30,
                axisLabel: {
                    interval: (index) => index % 2 === 0, // Show label for every second duration
                },
                axisTick: {
                    show: true,
                    alignWithLabel: true, // Ensure ticks align with labels
                    interval: (index) => index % 2 === 0, // Show tick for every second duration
                },
                splitLine: {
                    show: false, // Hide grid lines if not needed
                },
            },
            yAxis: {
                type: 'value',
                name: t('results.precipitation_mm') 
            },
            series,
            graphic: {
                elements: [
                    {
                        type: 'text',
                        left: '15%',  // Adjust 'left' for horizontal position (can also use 'right')
                        top: '15%',   // Adjust 'top' for vertical position (can also use 'bottom')
                        style: {
                            text:  'R '+t('results.rain_return_period'),  // <-- Place your content here
                            font: 'bold 14px Arial',  // Font styling
                            fill: '#000',  // Text color
                            align: 'center'  // Text alignment
                        }
                    }
                ]
            },
            dataZoom: [
                {
                type: 'inside',
                start: 0,
                end: 100
                }
            ],
        };

        // Store the generated option
        chart_data['block_rain'] = option;
    };

</script>

<style scoped>
span {
    color: black;
    text-align: center;
    padding: 1rem;
}
</style>