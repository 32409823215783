// Use the openterra server API endpoints also for deleopment otherwise simulation services do not work 
// const isProduction = process.env.NODE_ENV === 'production';
const isProduction = true;

const base_api = isProduction
  ? 'https://api.terraopen.ai/api/v1/'
  : 'http://10.30.104.70:7780/api/v1/';

const base_api_ai = isProduction
  ? 'https://apichat.terraopen.ai/api/v1/'
  : 'http://10.30.104.70:7781/api/v1/';

  const base_api_sim = isProduction
  ? 'https://apisimulation.terraopen.ai/api/v1/'
  : 'http://10.30.104.70:7782/api/v1/';

// const base_api_sim_results = 'https://minio.terraopen.ai/simulation-data-dev/'
const base_api_sim_results = 'https://minio.terraopen.ai/simulation-data/'

const api_endpoints = {
    // Administrative
    "login": `${base_api}login`,
    "logout": `${base_api}logout`,
    "users_me": `${base_api}users/me`,
    "users": `${base_api}users/`,
    "users_id": id => `${base_api}users/${id}`,
    "projects":  `${base_api}projects/`,
    "projects_id":  id => `${base_api}projects/${id}`,
    "confirm_email": token => `${base_api}confirm-email?token=${token}`,
    
    // Vegetation types
    "plants": `${base_api}plants/`,
    "plants_id": id => `${base_api}plants/${id}`,
    "plants_upload": (id, lod) => `${base_api}plants/upload/${id}/${lod}`,
    "plants_get_glb_id": (id, lod) => `${base_api}plants/get-glb/${id}/${lod}`,

    // Vegetation Mix types
    "plants_cong": `${base_api}plants/conglomerates/`,
    "plants_cong_id": id =>  `${base_api}plants/conglomerates/${id}`,

    // Green roof types
    "layers": `${base_api}layers/`,
    "layers_id": id => `${base_api}layers/${id}`,

    // Material types
    "materials":  `${base_api}materials/`,
    "materials_id": id => `${base_api}materials/${id}`,

    // Hydraulics
    "hydraulic_types": `${base_api}hydraulics/`,
    "hydraulic_elements": (project_id) => `${base_api}hydraulics/${project_id}/hydraulic-elements`,
    "hydraulic_elements_id": (project_id, element_id) => `${base_api}hydraulics/${project_id}/hydraulic-elements/${element_id}`,
    "hydraulic_connections": (project_id) => `${base_api}connections/${project_id}/hydraulic-connections`,
    "hydraulic_connections_id": (project_id, connection_id) => `${base_api}connections/${project_id}/hydraulic-connections/${connection_id}`,

    // Geometric Modeling
    "areas": project_id => `${base_api}project/${project_id}/areas`,
    "areas_id": (project_id, area_id) => `${base_api}project/${project_id}/areas/${area_id}`,
    "subareas": (project_id, area_id) => `${base_api}project/${project_id}/areas/${area_id}/subareas`,
    "upload_ifc":  project_id => `${base_api}project/${project_id}/upload/model-ifc`,
    "get_ifc_json":  project_id => `${base_api}project/${project_id}/upload/model-ifc/json`,
    "get_ifc_frag":  project_id => `${base_api}project/${project_id}/upload/model-ifc/frag`,
    "custom_model_glb": project_id =>  `${base_api}project/${project_id}/upload/model-custom`,

    // Simulation
    "location":  project_id =>`${base_api_sim}${project_id}/location`,
    "simulations": project_id =>`${base_api_sim}${project_id}/simulations`,
    "results": (project_id, simulation_id) => `${base_api_sim}${project_id}/simulations/${simulation_id}`, // This is deprecated see the endpoints below for getting the result files
    "get_statistical_weather": project_id => `${base_api_sim}${project_id}/weathers/graph-data`,
    "generate_statistical_weather": project_id => `${base_api_sim}${project_id}/weathers/statistical`,
    "get_block_rain": project_id => `${base_api_sim}${project_id}/weathers/graph-matrix`,
    "generate_block_rain": project_id => `${base_api_sim}${project_id}/weathers/blockrain`,
    "modify_block_rain": project_id => `${base_api_sim}${project_id}/weathers/blockrain/batch`,

    // Simulation Results Files
    "sim_results_total": (simulation_id) => `${base_api_sim_results}sim_${simulation_id}_result_total`,
    "sim_results_statistical": (simulation_id) => `${base_api_sim_results}sim_${simulation_id}_result_statistical`,
    "sim_results_blockrain": (simulation_id, event_id) => `${base_api_sim_results}sim_${simulation_id}_result_blockrain_${event_id}`, // event_id is has to be in the format of [duration]_[return_period]
 
    // Chat / AI
    "chat":  `${base_api_ai}chat/open-ai-agent/`
}

export{
    api_endpoints
}