<template>
  <Teleport to="body">
    <div class="custom-modal">
      <form @submit.prevent="handleSubmit" class="modal-content bg-dark text-white p-4">

        <!-- Modal Header -->
        <div class="modal-header d-flex justify-content-between align-items-center">
          <div class="d-flex justify-content-start gap-3 align-items-center">
            <span
              class="badge text-dark"
              :class="objectTypeBadgeClass"
              >{{ objectTypeBadge }}</span
            >
            <span class="tooltip-container">
              <Questionmark />
              <span class="tooltip-text">{{ $t('layer_modal.tooltip') }}</span>
            </span>
          </div>
          <button class="close btn-close btn-close-white" @click="closeModal"></button>
        </div>

        <!-- Scrollable Layer List -->
        <div class="layer_wrapper">
          <div class="h-100 d-flex flex-column">
            <!-- Common Information -->
            <div class="row mb-3">
              <div class="col">
                <label for="common_name" class="form-label">{{ $t('type_forms.common_name') }}</label>
                <input
                  type="text"
                  id="common_name"
                  v-model="form.common_name"
                  class="form-control"
                  required
                />
              </div>
              <div class="col">
                <label for="description" class="form-label">{{ $t('type_forms.description') }}</label>
                <input
                  type="text"
                  id="description"
                  v-model="form.description"
                  class="form-control"
                  required
                />
              </div>
            </div>

            <!-- Layers -->
            <div class="mt-3">
              <div v-for="(layer, index) in activeLayers" :key="index" class="row mb-3 background p-3">
                <div class="col">
                  <h5 class="layer-label">{{ $t('type_forms.layer_form.layer', { layer_no: index + 1 }) }}</h5>

                  <!-- Material Selection -->
                  <div class="form-floating mb-3">
                    <select
                      :id="`material-select-${index}`"
                      class="form-select"
                      v-model="layer.material"
                      :disabled="!layer.material_editable"
                      required
                    >
                      <option v-for="material in materials" :key="material.id" :value="material.id">
                        {{ material.common_name }}
                      </option>
                    </select>
                    <label :for="`material-select-${index}`">{{ $t('type_forms.layer_form.select_material') }}</label>
                  </div>

                  <!-- Depth Input -->
                  <div class="form-floating">
                    <input
                      type="number"
                      step="any"
                      :id="`depth-input-${index}`"
                      v-model="layer.depth"
                      class="form-control"
                      :placeholder="$t('layer_modal.placeholder_depth')"
                      :disabled="!layer.depth_editable"
                      required
                    />
                    <label :for="`depth-input-${index}`">{{ $t('type_forms.layer_form.depth') }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="modal-footer d-flex justify-content-end gap-2 mt-3">
          <button
            type="submit"
            class="btn btn-primary"
            :disabled="isTemplate"
            data-action="modify"
          >
            {{ $t('type_forms.modify_type') }}
          </button>
          <button
            type="submit"
            class="btn btn-secondary"
            data-action="copy"
          >
            {{ $t('type_forms.create_copy') }}
          </button>
        </div>


      </form>

      <ErrorNotification v-if="showError" :message="errorMessage"/>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import Questionmark from '../icons/Questionmark.vue';
import ErrorNotification from '../notifications/ErrorNotification.vue';

import { api_endpoints } from '@/js/api_endpoints';

// Props received by the modal
const props = defineProps({
  layerData: {
    type: Object,
    required: true
  },
  existingNameList: {
    type: Array,
    required: true
  }
});

const showError = ref(false);
const errorMessage = ref('');

// Emits
const emit = defineEmits(['update-success', 'on-close']);

// Internationalization
const { t } = useI18n();

// Store for accessing materials
const store = useStore();

// Local state for the form
const form = ref({
  common_name: '',
  description: '',
  layers: []
});

// Compute active layers (up to the first null material)
const activeLayers = computed(() => {
  const layers = form.value.layers;
  const active = [];
  for (let layer of layers) {
    if (layer.material === null) break;
    active.push(layer);
  }
  return active;
});

// Fetch materials from the store
const materials = computed(() => store.state.climagruen_types.materials);

// Determine if the object is a template or user-created
const isTemplate = computed(() => props.layerData.project_id === null);

// Badge information
const objectTypeBadge = computed(() => (isTemplate.value ? 'OpenTerra Base' : 'User Created'));
const objectTypeBadgeClass = computed(() => (isTemplate.value ? 'bg-base' : 'bg-user'));

// Initialize form data when layerData changes
watch(
  () => props.layerData,
  (newData) => {
    initializeForm(newData);
  },
  { immediate: true }
);

// Function to initialize form data
function initializeForm(data) {
  form.value.common_name = data.common_name;
  form.value.description = data.description;

  // Initialize layers
  form.value.layers = [];
  for (let i = 1; i <= 10; i++) {
    const materialKey = `layer${i}_material`;
    const depthKey = `layer${i}_depth`;
    const materialEditableKey = `layer${i}_material_editable`;
    const depthEditableKey = `layer${i}_depth_editable`;

    const material = data[materialKey];
    const depth = data[depthKey];
    const material_editable = data[materialEditableKey];
    const depth_editable = data[depthEditableKey];

    form.value.layers.push({
      material,
      depth,
      material_editable,
      depth_editable
    });

    // Stop adding layers after the first null material
    if (material === null) break;
  }
}

// Function to construct the payload for the API
function constructPayload(project_id) {
  const payload = {
    common_name: form.value.common_name,
    description: form.value.description,
    total_depth: calculateTotalDepth(),
    project_id: project_id
  };

  // Add layers up to 10
  for (let i = 1; i <= 10; i++) {
    const layer = form.value.layers[i - 1] || {};
    payload[`layer${i}_material`] = layer.material || null;
    payload[`layer${i}_depth`] = layer.depth || null;
    payload[`layer${i}_material_editable`] = layer.material_editable || false;
    payload[`layer${i}_depth_editable`] = layer.depth_editable || false;
  }

  return payload;
}

// Function to calculate total depth
function calculateTotalDepth() {
  let sum = 0;
  for (let layer of form.value.layers) {
    if (layer.depth === null) break;
    sum += Number(layer.depth);
  }
  return sum;
}

// Submit form handler for modification
async function modifyObject() {
  try {
    const token = window.localStorage.getItem('climagruen_token');
    const project_id = store.state.current_project.id;
    if (!project_id) {
      throw new Error('No project selected.');
    }
    const payload = constructPayload(project_id);

    // Since it's a modify action, we assume the object has an ID
    if (props.layerData.id) {
      const updated_layer_type = await axios.put(api_endpoints.layers_id(props.layerData.id), payload, {
        headers: { Authorization: `Bearer ${token}` }
      });
      emit('update-success', updated_layer_type.data);
    } else {
      throw new Error('Cannot modify an object without an ID.');
    }

    // Refresh the store or perform other actions if needed
    await store.dispatch('getLayerTypes');
    closeModal();
  } catch (error) {
    console.error(error);
    errorNotification(t('layer_modal.update_failure'));
  }
}

// Handler to create a copy of the object
async function createCopy() {
  // Check if name in existingNameList
  if (props.existingNameList.includes(form.value.common_name)) {
    // Show form validation error
    const input = document.getElementById('common_name');
    input.setCustomValidity('A layer type with the same name already exists.');
    input.reportValidity();

    setTimeout(() => {
      // Reset validity after 5 seconds
      input.setCustomValidity('');
    }, 4000);
    return;
  }

  try {
    const token = window.localStorage.getItem('climagruen_token');
    const project_id = store.state.current_project.id;
    if (!project_id) {
      throw new Error('No project selected.');
    }
    const payload = constructPayload(project_id);
    payload.common_name = payload.common_name;

    const new_layer_type = await axios.post(api_endpoints.layers, payload, {
      headers: { Authorization: `Bearer ${token}` }
    });
    emit('update-success', new_layer_type.data);

    // Refresh the store or perform other actions if needed
    await store.dispatch('getLayerTypes');
    closeModal();
  } catch (error) {
    console.error(error);
    errorNotification(t('layer_modal.copy_failure'));
  }
}

// Submit form handler
async function handleSubmit(event) {
  const action = event.submitter.getAttribute('data-action');

  if (action === 'modify') {
    await modifyObject();
  } else if (action === 'copy') {
    await createCopy();
  } else {
    console.warn('Unknown form submission action:', action);
  }
}


// Close modal handler
function closeModal() {
  emit('on-close');
}

function errorNotification(message) {
  showError.value = true;
  errorMessage.value = message;
  setTimeout(() => {
    showError.value = false;
    errorMessage.value = '';
  }, 4000);
}
</script>

<style scoped>

.modal-content {
  width: 80%;
  max-width: 800px;
  border-radius: 10px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: #343a40;
  color: white;
}

@media (max-width: 768px) {
  .modal-content {
    width: 95%;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: max-content;
  max-width: 350px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.bg-dark {
  background-color: #343a40;
}

.layer-label {
  font-weight: 700;
  margin-bottom: 1rem;
}

.background {
  background-color: #3a3f47;
  border-radius: 0.25rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.form-label {
  font-weight: 600;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #495057;
  cursor: not-allowed;
  opacity: 0.7;
  color: white;
}

.form-floating > label:after {
  background: transparent !important;
}

.btn {
  min-width: 100px;
}

.badge {
  font-size: 0.9em;
  padding: 0.5em 0.75em;
}

.bg-user {
  background-color: #17a2b8 !important; /* Bootstrap Info Color */
}

.bg-base {
  background-color: #6c757d !important; /* Bootstrap Secondary Color */
}

.text-dark {
  color: #343a40 !important;
}


.modal-header,
.modal-footer {
  flex-shrink: 0;
}

.layer_wrapper {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-footer {
  display: flex;
  justify-content: end;
  gap: 0.5rem;
  margin-top: auto;
}
</style>
