<template>
    <div class="d-flex flex-column">
        <div class="form-floating dropdown mb-2" data-bs-theme="dark">
            <select id="type-select" class="form-select min-width" @change="emitSelectedType" v-model="selected_type_id" :disabled="(props.selectedObject.cg_type != objectTypes.standard) || terrainMode || elevationMode">
                <option v-for="cg_type in enabledCgTypes" :value="cg_type.id">{{ cg_type.common_name }}</option>
            </select>
            <label for="type-select">{{ $t('toolbar.switch_type') }}</label>
        </div>

        <div class="form-floating">
            <input type="text" class="form-control" id="roofName" v-model="roofName" 
                required
                pattern=".*\S.*"
                :title="$t('generic.empty_form_input')"
                @change="emitRoofName"
                :disabled="(props.selectedObject.cg_type != objectTypes.standard) || terrainMode || elevationMode"
            >
            <label for="roofName">{{$t('generic.name')}}</label>
        </div>
    </div>

    <button class="btn btn-secondary me-3 no-gap" @click="showLayerModal = true" :title="$t('toolbar.modify_layer_type')" :disabled="(props.selectedObject.cg_type != objectTypes.standard) || terrainMode || elevationMode"><font-awesome-icon icon="fa-solid fa-table-list"/></button>

    <!-- The positioning and sizing of the following container caused me some headache... see the onMounted hook -->
    <div ref="toolbarColumn" class="d-flex flex-column justify-content-between">
        <div class="d-flex gap-3">
            <div v-if="!elevationMode && !terrainMode">
                <button class="btn btn-danger" @click="emitDeleteObject" :title="$t('toolbar.delete_object')" :disabled="!props.selectedObject.cg_type"><font-awesome-icon icon="fa-solid fa-trash"/></button>
            </div>
            
            <div v-if="!terrainMode">
                <input type="checkbox" v-model="elevationMode" id="elevationToggle" class="btn-check" @change="toggleElevationMode" :disabled="props.selectedObject.cg_type != objectTypes.standard"/>
                <label v-if="!elevationMode" class="btn btn-info" :class="{'active': elevationMode}" for="elevationToggle" :title="$t('toolbar.elevation_start')">
                    <font-awesome-icon icon="fa-solid fa-up-down"/>
                </label>
                <label v-else class="btn btn-danger" :class="{'active': elevationMode}" for="elevationToggle" :title="$t('toolbar.elevation_end')">
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
                </label>
            </div>

            <!-- Uncomment the following when action-stack and undo functionality for elevation is implemented: -->
            <!-- <div v-if="elevationMode" class="sub-toolbar">
                <button class="btn btn-info" @click="dispatchElevationConfirmed" :title="$t('toolbar.confirm_elevation')"><font-awesome-icon icon="fa-solid fa-check"/></button>
                <button class="btn btn-secondary" @click="dispatchUndoElevation" :title="$t('toolbar.undo')"><font-awesome-icon icon="fa-solid fa-rotate-left"/></button>
            </div> -->


            <div v-if="!elevationMode">
                <input type="checkbox" v-model="terrainMode" id="terrainToggle" class="btn-check" @change="toggleTerrainMode" :disabled="props.selectedObject.cg_type != objectTypes.standard || elevationMode"/>
                <label v-if="!terrainMode" class="btn btn-info" :class="{'active': terrainMode}" for="terrainToggle" :title="$t('toolbar.terrain_drawing_start')">
                    <font-awesome-icon icon="fa-solid fa-mountain"/>
                </label>
                <label v-else class="btn btn-danger" :class="{'active': terrainMode}" for="terrainToggle" :title="$t('toolbar.terrain_drawing_end')">
                    <font-awesome-icon icon="fa-solid fa-xmark"/>
                </label>
            </div>


            <div v-if="terrainMode" class="sub-toolbar">
                <button class="btn btn-info" @click="dispatchTerrainPointConfirmed" :title="$t('toolbar.confirm_terrain')"><font-awesome-icon icon="fa-solid fa-check"/></button>
                <button class="btn btn-secondary" @click="dispatchUndoTerrain" :title="$t('toolbar.undo')"><font-awesome-icon icon="fa-solid fa-rotate-left"/></button>

                <label for="sigmaRange" class="form-label">{{ $t('toolbar.sigma') }}</label>
                <input type="range" class="form-range" min="0.5" max="5.0" step="0.1" id="sigmaRange" :title="$t('toolbar.sigma_tooltip')" @change="updateSigma" v-model="sigma">
            </div>
        </div>

        <div class="form-floating">
            <input type="number" class="form-control" id="roofThickness" v-model="roofThickness" 
                step="any"
                :title="$t('generic.empty_form_input')"
                @change="emitRoofThickness"
                :disabled="(props.selectedObject.cg_type != objectTypes.standard) || terrainMode || elevationMode || !flexibleLayer"
            >
            <label for="roofThickness">{{$t('type_forms.layer_form.depth')}}</label>
        </div>

    </div>

    
    

    <!-- Layer Modal -->
    <LayerModal
        v-if="showLayerModal"
        :layerData="type_details"
        :existing-name-list="layerNames"
        @onClose="showLayerModal = false"
        @update-success="handleSuccess"
    />
</template>

<script setup>
    import {onMounted, ref, watch, computed} from "vue";
    import {useStore} from "vuex";
    import { objectTypes, customEvents } from "../../js/modeling_utils.js";
    import {default_values} from "@/js/modeling_utils.js";
    import {loadLayerTypeDetails} from "@/js/api_calls.js";

    import LayerModal from "../modals/LayerModal.vue";


    const emit = defineEmits(['deleteObject','typeSelectChange', 'updateSigma', 'terrainModeling', 'elevation', 'roofNameChange', 'roofThicknessChange']);
    const props = defineProps({
        selectedObject: Object,
    })

    const store = useStore()

    const selected_type_id = ref(null);

    const roofName = ref(null);
    const roofThickness = ref(null);
    const flexibleLayer = ref(false);
    const minThickness = ref(null);
    const maxThickness = ref(null);
    let savedRoofName;
    let savedThickness;
    const toolbarColumn = ref(null);
    // // Another functionality would be the movement of assets
    // const movement = ref(false);


    const sigma = ref(default_values.sigma);

    const terrainMode = ref(false);
    const elevationMode = ref(false);

    const showLayerModal = ref(false);
    const type_details = ref(null);

    const enabledCgTypes = computed(() => {
        return store.state.climagruen_types.layers.filter(cg_type => cg_type.enable && (cg_type.project_id === store.state.current_project.id || cg_type.project_id === null));
    });

    const layerNames = computed(() => {
        return enabledCgTypes.value.map(cg_type => cg_type.common_name);
    });

    onMounted(() => {
        // The following is not really elegant, but I did not have time and muse to do it with css and html only :/
        const styles = window.getComputedStyle(toolbarColumn.value.parentElement);
        const paddingTop = parseFloat(styles.paddingTop);
        const paddingBottom = parseFloat(styles.paddingBottom);
        const parentHeight = toolbarColumn.value.parentElement.getBoundingClientRect().height - paddingTop - paddingBottom;
        toolbarColumn.value.style.height = `${parentHeight}px`;
    })

    watch(()=>props.selectedObject, ()=>{
        if(props.selectedObject.cg_type == objectTypes.standard){

            selected_type_id.value = props.selectedObject.cg_type_details.id;
            type_details.value = props.selectedObject.cg_type_details;

            roofName.value = props.selectedObject.parent_name;
            savedRoofName = roofName.value;

            const flexibleLayerId = props.selectedObject.cg_type_details.flexible_layer;
            flexibleLayer.value = flexibleLayerId != null && props.selectedObject.cg_instance_depth;

            roofThickness.value = props.selectedObject.cg_instance_depth ? props.selectedObject.cg_instance_depth : props.selectedObject.cg_type_details.total_depth;
            savedThickness = roofThickness.value;

            if(flexibleLayer.value){
                const flexibleMaterialId = props.selectedObject.cg_type_details[`layer${flexibleLayerId}_material`];
                const flexibleLayerThickness = props.selectedObject.cg_type_details[`layer${flexibleLayerId}_depth`];
                const minThicknessHelper = flexibleLayerThickness - store.state.climagruen_types.materials.find(material => material.id == flexibleMaterialId).min_depth;
                const maxThicknessHelper = flexibleLayerThickness - store.state.climagruen_types.materials.find(material => material.id == flexibleMaterialId).max_depth;
                // console.log("Type Details", props.selectedObject.cg_type_details);
                minThickness.value = parseFloat((props.selectedObject.cg_type_details.total_depth - minThicknessHelper).toFixed(3));
                maxThickness.value = parseFloat((props.selectedObject.cg_type_details.total_depth - maxThicknessHelper).toFixed(3));
                console.log("Min , Max", minThickness.value, maxThickness.value);
            }
        }else{
            selected_type_id.value = null;
            type_details.value = null;
            roofName.value = null;
            roofThickness.value = null;
            flexibleLayer.value = false;
            savedRoofName = null;
        }
    })

    function emitDeleteObject() {
        emit('deleteObject');
    }

    async function emitSelectedType() {
        type_details.value = await loadLayerTypeDetails(selected_type_id.value);
        handleTypeSwitch(type_details.value);
        emit('typeSelectChange', type_details.value)
    }

    function updateSigma(){
        emit('updateSigma', sigma.value);
    }

    function dispatchTerrainPointConfirmed(){
        store.state.modeling_manager.domElement.dispatchEvent(customEvents.terrainPointConfirmed);
    }
    function dispatchUndoTerrain(){
        store.state.modeling_manager.domElement.dispatchEvent(customEvents.undoTerrain);
    }

    function toggleTerrainMode() {
        if(terrainMode.value){
            emit('terrainModeling', true);
            emit('updateSigma', sigma.value);
        }else{
            emit('terrainModeling', false)
        }
    }
    
    function toggleElevationMode() {
        if(elevationMode.value){
            emit('elevation', true);
        }else{
            emit('elevation', false)
        }
    }

    function emitRoofName(){
        // check if name is not empty
        if(roofName.value.trim() === ""){
            roofName.value = savedRoofName;
            window.alert(t('generic.empty_form_input'));
            return;
        }
        emit('roofNameChange', roofName.value);
    }

    function emitRoofThickness(){
        if(roofThickness.value < minThickness.value || roofThickness.value > maxThickness.value){
            window.alert("The thickness must be between " + minThickness.value + " and " + maxThickness.value + " m for this type of layer.");
            roofThickness.value = savedThickness;
            return;
        }
        emit('roofThicknessChange', roofThickness.value);
    }

    function handleSuccess(type_data){
        selected_type_id.value = type_data.id;
        type_details.value  = type_data;
        handleTypeSwitch(type_data);
        emit('typeSelectChange', type_data);
    }

    function handleTypeSwitch(new_type_details){
        const flexibleLayerId = new_type_details.flexible_layer;
        flexibleLayer.value = flexibleLayerId != null;

        roofThickness.value = new_type_details.total_depth;
        savedThickness = roofThickness.value;

        if(flexibleLayer.value){
            const flexibleMaterialId = new_type_details[`layer${flexibleLayerId}_material`];
            const flexibleLayerThickness = new_type_details[`layer${flexibleLayerId}_depth`];
            const minThicknessHelper = flexibleLayerThickness - store.state.climagruen_types.materials.find(material => material.id == flexibleMaterialId).min_depth;
            const maxThicknessHelper = flexibleLayerThickness - store.state.climagruen_types.materials.find(material => material.id == flexibleMaterialId).max_depth;
            // console.log("Type Details", props.selectedObject.cg_type_details);
            minThickness.value = parseFloat((new_type_details.total_depth - minThicknessHelper).toFixed(3));
            maxThickness.value = parseFloat((new_type_details.total_depth - maxThicknessHelper).toFixed(3));
            console.log("Min , Max", minThickness.value, maxThickness.value);
        }
    }
</script>
  
<style scoped>
    .min-width{
        min-width: 150px;
    }
    .sub-toolbar{
        display: flex;
        gap: 1rem;
    }

    .dropdown{
        width: max-content;
        max-width: 300px;
        min-width: 150px;
    }
    .no-gap{
        margin-left: -0.5rem;
    }

</style>
  