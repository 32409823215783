import axios from 'axios';
import { api_endpoints } from './api_endpoints';

const getToken = () => window.localStorage.getItem('climagruen_token');

function loadLayerTypeDetails(id){
    return axios.get(api_endpoints.layers_id(id),{
                    headers: {
                        'Authorization': `Bearer ${getToken()}`
                    }}
    ).then(response=>{
        return response.data
    })
    .catch(error => {
        console.log(error);
        return null
    })
}

function loadProjectDetails(id){
    return axios.get(api_endpoints.projects_id(id),{
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }}
        )
        .then(response=> response.data)
        .catch(error => {
            console.log(error);
            return null
    })
}

function updateProjectDetails(id, data){
    return axios.put(api_endpoints.projects_id(id), data,
            {   
            headers: {
                'Authorization': `Bearer ${getToken()}`
            }
        })
        .then(response=> response.data)
        .catch(error => {
            console.log(error);
            return null
    })
}

function geoReferenceProjectLocation(project_id){
    return axios.put(api_endpoints.location(project_id), {}, {headers: {'Authorization': `Bearer ${getToken()}`}})
        .then((response)=>{
            console.log('Location found:', response.data);
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

function getStatisticalWeatherData(project_id){
    const token = window.localStorage.getItem('climagruen_token');
    return axios.get(api_endpoints.get_statistical_weather(project_id), {headers: {'Authorization': `Bearer ${token}`}})
        .then((response)=>{
            console.log('Weather data:', response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}

function generateStatisticalWeatherData(project_id){
    return axios.post(api_endpoints.generate_statistical_weather(project_id), {}, {headers: {'Authorization': `Bearer ${getToken()}`}})
        .then((response)=>{
            console.log('Response:', response.data);
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

function getBlockRain(project_id){
    const processBlockRainData = (rawData) => {
        const orderedData = {};
    
        rawData.forEach(({ rain, duration, frequency }) => {
            // If the duration doesn't exist in tableData, initialize it as an object
            if (!orderedData[duration]) {
                orderedData[duration] = {};
            }
            // Assign the rain value to the respective frequency
            orderedData[duration][frequency] = rain;
        });
        return orderedData;
    }

    return axios.get(api_endpoints.get_block_rain(project_id), {headers: {'Authorization': `Bearer ${getToken()}`}})
        .then((response)=>{
            console.log('Block rain:', response.data);
            const processed_data = processBlockRainData(response.data);
            return processed_data;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}

function generateBlockRain(project_id){
    return axios.post(api_endpoints.generate_block_rain(project_id), {}, {headers: {'Authorization': `Bearer ${getToken()}`}})
        .then((response)=>{
            console.log('Response:', response.data);
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

function modifyBlockRain(project_id, data){
    const revertOrderedRainData = (orderedData) => {
        const rawData = [];
        
        // Iterate through the orderedData to reconstruct the original list
        Object.entries(orderedData).forEach(([duration, frequencies]) => {
            Object.entries(frequencies).forEach(([frequency, rain]) => {
                rawData.push({
                    rain: rain,
                    duration: parseFloat(duration), // Convert back to number if necessary
                    frequency: parseFloat(frequency) // Convert back to number if necessary
                });
            });
        });
    
        return rawData;
    };

    const rawData = {entries: revertOrderedRainData(data)};

    return axios.post(api_endpoints.modify_block_rain(project_id), rawData, {headers: {'Authorization': `Bearer ${getToken()}`}})
        .then((response)=>{
            console.log('Response:', response.data);
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
}

function getHydraulicComponent(project_id, hydraulic_id){
    return axios.get(api_endpoints.hydraulic_elements_id(project_id, hydraulic_id), {headers: {'Authorization': `Bearer ${getToken()}`}})
        .then((response)=>{
            console.log('Hydraulic component:', response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}
function getHydraulicComponents(project_id){
    return axios.get(api_endpoints.hydraulic_elements(project_id), {headers: {'Authorization': `Bearer ${getToken()}`}})
        .then((response)=>{
            console.log('Hydraulic components:', response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}

function getHydraulicConnections(project_id){
    return axios.get(api_endpoints.hydraulic_connections(project_id), {headers: {'Authorization': `Bearer ${getToken()}`}})
        .then((response)=>{
            console.log('Hydraulic connections:', response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            return null;
        });
}


export {
    loadLayerTypeDetails,
    loadProjectDetails,
    updateProjectDetails,
    geoReferenceProjectLocation,
    getStatisticalWeatherData,
    generateStatisticalWeatherData,
    getBlockRain,
    generateBlockRain,
    modifyBlockRain,
    getHydraulicComponent,
    getHydraulicComponents,
    getHydraulicConnections
};