<template>
    <div>
        <div class="form-floating">
            <select
                id="components-dropdown"
                class="form-select"
                v-model="selectedHydraulicComponent"
            >
                <option :value="null" disabled>
                    {{ $t('results.select_component') }}
                </option>
                <option
                    v-for="component in filteredHydraulicComponents"
                    :key="component.id"
                    :value="component.id"
                >
                    {{ component.name }} 
                </option>
            </select>
            <label for="components-dropdown" class="form-label">
                {{ $t('hydraulics.components') }}
            </label>
        </div> 
            
        <!-- Display for Gründach (All current content) -->
        <div v-if="selectedHydraulicComponent">
            <!-- Greenroof specific data -->
            <div v-if="greenroofData[selectedHydraulicComponent]" class="ms-3">
                <h5>{{ $t('results.hydraulics_greenroof.title') }}</h5>
                <ul class="data-list">
                    <li>{{ $t('results.hydraulics.overflow_max') }}: <strong>{{ greenroofData[selectedHydraulicComponent].overflow_max }}</strong></li>
                    <li>{{ $t('results.hydraulics.crucial_a') }}: <strong>{{ greenroofData[selectedHydraulicComponent].crucial_a }}</strong></li>
                    <li>{{ $t('results.hydraulics.crucial_d') }}: <strong>{{ greenroofData[selectedHydraulicComponent].crucial_d }}</strong></li>
                    <li>{{ $t('results.hydraulics_greenroof.irrigation_demand_total') }}: <strong>{{ greenroofData[selectedHydraulicComponent].irrigation_demand_total }}</strong></li>
                    <li>{{ $t('results.hydraulics_greenroof.irrigation_fromstorage') }}: <strong>{{ greenroofData[selectedHydraulicComponent].irrigation_fromstorage }}</strong></li>
                </ul>
            </div>

            <!-- Drains data -->
            <div v-if="drainsData[selectedHydraulicComponent]" class="ms-3">
                <h5>{{ $t('results.hydraulics_roof.title_r55') }}</h5>
                <!-- Tables in a row (Freispiegelentwässerung and Drucksystem) -->
                <div class="table-row">
                    <div class="table-wrapper">
                        <strong>{{ $t('results.hydraulics_roof.gravity_drainage') }}</strong>
                        <table>
                            <thead>
                                <tr>
                                    <th>{{ $t('results.hydraulics_roof.dn') }}</th>
                                    <th>{{ $t('results.hydraulics_roof.minimum_discharge') }}</th>
                                    <th>{{ $t('results.hydraulics_roof.number') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dn_value, index) in drainsData[selectedHydraulicComponent].drains_r55.gravity.DN" :key="index">
                                    <td>{{ dn_value }}</td>
                                    <td>{{ drainsData[selectedHydraulicComponent].drains_r55.gravity.Q[index] }}</td>
                                    <td>{{ drainsData[selectedHydraulicComponent].drains_r55.gravity.n[index] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            
                    <div class="table-wrapper">
                        <strong>{{ $t('results.hydraulics_roof.pressure_system') }}</strong>
                        <table>
                            <thead>
                                <tr>
                                    <th>{{ $t('results.hydraulics_roof.dn') }}</th>
                                    <th>{{ $t('results.hydraulics_roof.minimum_discharge') }}</th>
                                    <th>{{ $t('results.hydraulics_roof.number') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dn_value, index) in drainsData[selectedHydraulicComponent].drains_r55.pressure.DN" :key="index">
                                    <td>{{ dn_value }}</td>
                                    <td>{{ drainsData[selectedHydraulicComponent].drains_r55.pressure.Q[index] }}</td>
                                    <td>{{ drainsData[selectedHydraulicComponent].drains_r55.pressure.n[index] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <h5>{{ $t('results.hydraulics_roof.title_r5100') }}</h5>
                <!-- Tables in a row (Freispiegelentwässerung and Drucksystem) -->
                <div class="table-row">
                    <div class="table-wrapper">
                        <strong>{{ $t('results.hydraulics_roof.gravity_drainage') }}</strong>
                        <table>
                            <thead>
                                <tr>
                                    <th>{{ $t('results.hydraulics_roof.dn') }}</th>
                                    <th>{{ $t('results.hydraulics_roof.minimum_discharge') }}</th>
                                    <th>{{ $t('results.hydraulics_roof.number') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dn_value, index) in drainsData[selectedHydraulicComponent].drains_r5100.gravity.DN" :key="index">
                                    <td>{{ dn_value }}</td>
                                    <td>{{ drainsData[selectedHydraulicComponent].drains_r5100.gravity.Q[index] }}</td>
                                    <td>{{ drainsData[selectedHydraulicComponent].drains_r5100.gravity.n[index] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            
                    <div class="table-wrapper">
                        <strong>{{ $t('results.hydraulics_roof.pressure_system') }}</strong>
                        <table>
                            <thead>
                                <tr>
                                    <th>{{ $t('results.hydraulics_roof.dn') }}</th>
                                    <th>{{ $t('results.hydraulics_roof.minimum_discharge') }}</th>
                                    <th>{{ $t('results.hydraulics_roof.number') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dn_value, index) in drainsData[selectedHydraulicComponent].drains_r5100.pressure.DN" :key="index">
                                    <td>{{ dn_value }}</td>
                                    <td>{{ drainsData[selectedHydraulicComponent].drains_r5100.pressure.Q[index] }}</td>
                                    <td>{{ drainsData[selectedHydraulicComponent].drains_r5100.pressure.n[index] }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- Filter data -->
            <div v-if="filterData[selectedHydraulicComponent]" class="ms-3">
                <h5>{{ $t('results.hydraulics_filter.title') }}</h5>
                <ul class="data-list">
                    <li>{{ $t('results.hydraulics_filter.ratio') }}: <strong>{{ filterData[selectedHydraulicComponent].ratio }}</strong></li>
                    <li>{{ $t('results.hydraulics_filter.ratio_max') }}: <strong>{{ filterData[selectedHydraulicComponent].ratio_max }}</strong></li>
                    <li>{{ $t('results.hydraulics_filter.n_overflow') }}: <strong>{{ filterData[selectedHydraulicComponent].n_overflow }}</strong></li>
                    <li>{{ $t('results.hydraulics_filter.fulfilled') }}: <strong>{{ filterData[selectedHydraulicComponent].fulfilled }}</strong></li>
                </ul>
            </div>

            <!-- Infiltration data -->
            <div v-if="infiltrationData[selectedHydraulicComponent]" class="ms-3">
                <h5>{{ $t('results.hydraulics_infiltration.title') }}</h5>
                <ul class="data-list">
                    <li>{{ $t('results.hydraulics_infiltration.height_max_relative') }}: <strong>{{ infiltrationData[selectedHydraulicComponent].height_max_relative }}</strong></li>
                    <li>{{ $t('results.hydraulics.overflow_max') }}: <strong>{{ infiltrationData[selectedHydraulicComponent].overflow_max }}</strong></li>
                    <li>{{ $t('results.hydraulics.crucial_a') }}: <strong>{{ infiltrationData[selectedHydraulicComponent].crucial_a }}</strong></li>
                    <li>{{ $t('results.hydraulics.crucial_d') }}: <strong>{{ infiltrationData[selectedHydraulicComponent].crucial_d }}</strong></li>
                </ul>
            </div>
        </div>
    </div>
</template>
  
<script setup>
    import { onMounted, ref } from 'vue';
    import { useStore } from 'vuex';

    const store = useStore();
    const filterData = ref({});
    const infiltrationData = ref({});
    const greenroofData = ref({});
    const drainsData = ref({});
    const filteredHydraulicComponents = ref({});

    const selectedHydraulicComponent = ref(null);

    onMounted(async () => {
        const selectedSimulation = store.state.simulations.selectedSimulation;
        const simulationResults = await store.dispatch('simulations/getSimulationResult', { simulationId: selectedSimulation, resultType: 'total' });

        if(!simulationResults){
            return;
        }

        let groupedParams = store.state.simulations.simulationResults[selectedSimulation].groupedParams;
        if (!groupedParams) {
            await store.dispatch('simulations/getSimulationResults', { simulationId: selectedSimulation, resultType: 'total' });
            groupedParams = store.state.simulations.simulationResults[selectedSimulation].groupedParams;
        }

        // Create a set of unique keys from .filter, .roofs, .greenroofs, and .infiltration
        const uniqueDiscreteNames = new Set([
            ...Object.keys(simulationResults.filter),
            ...Object.keys(simulationResults.roofs),
            ...Object.keys(simulationResults.greenroofs),
            ...Object.keys(simulationResults.infiltration)
        ]);

        const filteredHydraulicComponentsHelper = []
        // loop over discrete names
        for (const discreteName of uniqueDiscreteNames) {
            if(!groupedParams[discreteName]){
                continue;
            }
            let component_info;
            if(Array.isArray(groupedParams[discreteName])){
                component_info = {id: groupedParams[discreteName][0].id, name: groupedParams[discreteName][0].name}
                filteredHydraulicComponentsHelper.push(component_info)
            } else {
                component_info = {id: groupedParams[discreteName].id, name: groupedParams[discreteName].name}
                filteredHydraulicComponentsHelper.push(component_info)
            }
            // drains from .roofs
            drainsData.value[component_info.id] = simulationResults.roofs[discreteName];

            // additional greenroof data from .greenroofs
            greenroofData.value[component_info.id] = simulationResults.greenroofs[discreteName];

            // filter from .filter
            filterData.value[component_info.id] = simulationResults.filter[discreteName];

            // infiltration from .infiltration
            infiltrationData.value[component_info.id] = simulationResults.infiltration[discreteName];

        }       

        filteredHydraulicComponents.value = filteredHydraulicComponentsHelper;
    });
    
</script>
  
<style scoped>
    /* Set text color and background */
    div {
        color: #495057;
        background-color: #ffffff;
    }
  
    select {
        margin-bottom: 20px;
    }
  
    /* Style for Bewässerungssystem section */
    .bewaesserung-section {
        margin-top: 20px;
    }
  
    .data-list {
        list-style-type: none;
        padding-left: 0;
    }
  
    .data-list li {
        margin-bottom: 10px;
    }
  
    /* Tables layout with two tables side by side */
    .table-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 20px;
    }
  
    .table-wrapper {
        flex: 1;
    }
  
    table {
        width: 100%;
        border-collapse: collapse;
    }
  
    th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: center;
        color: #495057;
    }
  
    th {
        background-color: #f4f4f4;
    }
  
    h3, h4 {
        margin-top: 20px;
        color: #495057;
    }
  
    /* Style for Rigole section */
    .rigole-section {
        margin-top: 20px;
        color: #495057;
    }
  
    .rigole-section p {
        font-size: 18px;
    }
</style>
