<template>
    <button type="button" class="btn btn-primary mb-4" @click="addHydraulicComponent">
        <font-awesome-icon icon="fa-solid fa-plus" />
    </button>
    <form ref="formRef" class="components-container">
        <div v-for="(component, index) in hydraulicComponents" :key="index" class="component mb-3">
            <div class="d-flex align-items-center component-header">
                <div class="form-floating flex-grow-1 me-2">
                    <select v-model="component.type" class="form-select me-2" required :disabled="component.id"
                        :class="{ 'disabled': component.id }">
                        <option disabled value="">{{ $t('hydraulics.select_component_type') }}</option>
                        <option v-for="type in store.state.climagruen_types.hydraulic_components"
                            :value="type.description"
                            :disabled="type.description === 'groundwater' || type.description === 'river'"
                        >
                            {{ type.common_name }}
                        </option>
                    </select>
                    <label>{{ $t('hydraulics.component_type') }}</label>
                </div>
                <div class="form-floating flex-grow-1 me-2">
                    <input v-model="component.name" type="text" class="form-control" id="floatingNameGreenroof"
                        :class="{ 'disabled': component.area_id }" :placeholder="$t('hydraulics.name')"
                        :title="$t('hydraulics.name')" required :disabled="component.area_id">
                    <label for="floatingNameGreenroof">{{ $t('hydraulics.name') }}</label>
                </div>
                <div class="form-floating me-2">
                    <button type="button" class="btn btn-danger" @click="removeHydraulicComponent(index)"
                        :disabled="component.area_id">
                        <font-awesome-icon icon="fa-solid fa-minus" />
                    </button>
                </div>
                <div class="form-floating me-2 ms-2">
                    <!-- Collapse Button -->
                    <button type="button" class="btn btn-secondary" @click="toggleCollapse(index)">
                        <font-awesome-icon
                            :icon="collapsed[index] ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up'" />
                    </button>
                </div>
            </div>

            <div v-show="collapsed[index]">
                <!-- THROUGH Component -->
                <div v-if="component.type === 'through'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- First row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.width" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.width') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.length" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.length') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.height" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.height') }}</label>
                            </div>
                        </div>

                        <!-- Second row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.porosity" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.porosity') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.K_f" type="number" step="any" class="form-control" required>
                                <label>{{ $t('hydraulics.K_f') }}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Image placeholder -->
                    <div class="image-placeholder ms-3">
                        <img src="/schemas/through.png" alt="Schematic" width="300" />
                    </div>
                </div>

                <!-- RIGOL Component -->
                <div v-else-if="component.type === 'rigol'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- First row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.width" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.width') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.length" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.length') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.height" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.height') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.distance" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.distance') }}</label>
                            </div>
                        </div>

                        <!-- Second row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.pipe_diameter_inside" type="number" step="any"
                                    class="form-control" required>
                                <label>{{ $t('hydraulics.pipe_diameter_inside') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.pipe_diameter_outside" type="number" step="any"
                                    class="form-control" required>
                                <label>{{ $t('hydraulics.pipe_diameter_outside') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.number_of_pipes" type="number" step="1" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.number_of_pipes') }}</label>
                            </div>
                        </div>

                        <!-- Third row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.porosity" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.porosity') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.K_f" type="number" step="any" class="form-control" required>
                                <label>{{ $t('hydraulics.K_f') }}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Image placeholder -->
                    <div class="image-placeholder ms-3">
                        <img src="/schemas/rigol.png" alt="Schematic" width="300" />
                    </div>
                </div>

                <!-- THROUGH RIGOL Component -->
                <div v-else-if="component.type === 'throughrigol'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- First row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.width" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.width') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.length" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.length') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.height" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.height') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.distance" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.distance') }}</label>
                            </div>
                        </div>

                        <!-- Second row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.porosity" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.porosity') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.K_f" type="number" step="any" class="form-control" required>
                                <label>{{ $t('hydraulics.K_f') }}</label>
                            </div>

                        </div>

                        <!-- Third row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.pipe_diameter_inside" type="number" step="any"
                                    class="form-control" required>
                                <label>{{ $t('hydraulics.pipe_diameter_inside') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.pipe_diameter_outside" type="number" step="any"
                                    class="form-control" required>
                                <label>{{ $t('hydraulics.pipe_diameter_outside') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.number_of_pipes" type="number" step="1" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.number_of_pipes') }}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Image placeholder -->
                    <div class="image-placeholder ms-3">
                        <img src="/schemas/throughrigol.png" alt="Schematic" width="300" />
                    </div>
                </div>

                <!-- SHAFT Component -->
                <div v-else-if="component.type === 'shaft'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- First row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.diameter_inside" step="any" type="number"
                                    class="form-control" required>
                                <label>{{ $t('hydraulics.diameter_inside') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.diameter_outside" step="any" type="number"
                                    class="form-control" required>
                                <label>{{ $t('hydraulics.diameter_outside') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.height" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.height') }}</label>
                            </div>
                        </div>

                        <!-- Second row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.openings" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.openings') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.pipe_diameter_inside" type="number" step="any"
                                    class="form-control" required>
                                <label>{{ $t('hydraulics.pipe_diameter_inside') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.pipe_diameter_outside" type="number" step="any"
                                    class="form-control" required>
                                <label>{{ $t('hydraulics.pipe_diameter_outside') }}</label>
                            </div>
                        </div>

                        <!-- Third row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.porosity" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.porosity') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.K_f" type="number" step="any" class="form-control" required>
                                <label>{{ $t('hydraulics.K_f') }}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Image placeholder -->
                    <div class="image-placeholder ms-3">
                        <img src="/schemas/shaft.png" alt="Schematic" width="300" />
                    </div>
                </div>

                <!-- AREA Component -->
                <div v-else-if="component.type === 'area'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- First row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.length" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.area') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.psi" type="number" step="any" class="form-control" required>
                                <label>{{ $t('hydraulics.psi') }}</label>
                            </div>
                            <div class="form-floating mb-3 cursor-pointer" @click="openAreaTable(index)">
                                <input v-model="component.areatype" type="text" class="form-control pointer-events-none" readonly>
                                <label>{{ $t('hydraulics.area_type') }}</label>
                            </div>
                        </div>
                        
                        <!-- Second row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.inclination" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.inclination') }}</label>
                            </div>
                            <div class="form-floating mb-3 cursor-pointer" @click="openSurfaceTable(index)">
                                <input v-model="component.surfacetype" type="text" class="form-control pointer-events-none" readonly>
                                <label>{{ $t('hydraulics.surface_type') }}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Image placeholder -->
                    <div class="image-placeholder ms-3">
                        <img src="/schemas/area.png" alt="Schematic" width="300" />
                    </div>
                </div>

                <!-- STORAGE Component -->
                <div v-else-if="component.type === 'storage'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- First row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.width" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.width') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.length" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.length') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <input v-model="component.height" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.height') }}</label>
                            </div>
                        </div>

                        <!-- Second row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.porosity" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.porosity') }}</label>
                            </div>
                        </div>
                    </div>

                    <!-- Image placeholder -->
                    <div class="image-placeholder ms-3">
                        <img src="/schemas/storage.png" alt="Schematic" width="300" />
                    </div>
                </div>

                <!-- GREENROOF Component -->
                <div v-else-if="component.type === 'greenroof'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- First row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.area" type="number" step="any" class="form-control" required
                                    :disabled="component.area_id">
                                <label>{{ $t('hydraulics.area') }}</label>
                            </div>
                            <div class="form-floating mb-3">
                                <select v-model="component.irrigationtype" class="form-select" required>
                                    <option disabled value="">{{ $t('hydraulics.select_irrigation_type') }}</option>
                                    <option v-for="option in irrigationOptions" :value="option.value"
                                        :key="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                                <label>{{ $t('hydraulics.irrigation') }}</label>
                            </div>
                            <div class="form-floating mb-3 cursor-pointer" @click="openAreaTable(index)">
                                <input v-model="component.areatype" type="text" class="form-control pointer-events-none" readonly>
                                <label>{{ $t('hydraulics.area_type') }}</label>
                            </div>
                        </div>

                        <!-- Second row of fields -->
                        <div class="input-row">
                            <div class="form-floating mb-3">
                                <input v-model="component.inclination" type="number" step="any" class="form-control"
                                    required>
                                <label>{{ $t('hydraulics.inclination') }}</label>
                            </div>
                            <div class="form-floating mb-3 cursor-pointer" @click="openSurfaceTable(index)">
                                <input v-model="component.surfacetype" type="text" class="form-control pointer-events-none" readonly>
                                <label>{{ $t('hydraulics.surface_type') }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- IRRIGATION Component -->
                <div v-else-if="component.type === 'irrigation'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <div class="form-floating mb-3">
                            <input v-model="component.outlets" type="number" step="1" class="form-control" required>
                            <label>{{ $t('hydraulics.outlets') }}</label>
                        </div>
                    </div>
                </div>

                <!-- PUMP Component -->
                <div v-else-if="component.type === 'pump'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <div class="form-floating mb-3">
                            <input v-model="component.Qmax" type="number" step="any" class="form-control" required>
                            <label>{{ $t('hydraulics.Qmax') }}</label>
                        </div>
                    </div>
                </div>

                <!-- THROTTLE Component -->
                <div v-else-if="component.type === 'throttle'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <div class="form-floating mb-3">
                            <input v-model="component.Qmax" type="number" step="any" class="form-control" required>
                            <label>{{ $t('hydraulics.Qmax') }}</label>
                        </div>
                    </div>
                </div>

                <!-- GROUNDWATER Component -->
                <div v-else-if="component.type === 'groundwater'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- No parameters text -->
                        <p>{{ $t('hydraulics.noparameters') }}</p>
                    </div>

                    <!-- Image placeholder -->
                    <div class="image-placeholder ms-3">
                        <img src="/schemas/through.png" alt="Schematic" width="300" />
                    </div>
                </div>

                <!-- RIVER Component -->
                <div v-else-if="component.type === 'river'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <!-- No parameters text -->
                        <p>{{ $t('hydraulics.noparameters') }}</p>
                    </div>

                    <!-- Image placeholder -->
                    <div class="image-placeholder ms-3">
                        <img src="/schemas/through.png" alt="Schematic" width="300" />
                    </div>
                </div>

                <!-- URBANDRAINAGE Component -->
                <div v-else-if="component.type === 'urbandrainage'" class="input-group flex-wrap" style="flex: 1;">
                    <div class="input-fields-container">
                        <div class="form-floating mb-3">
                            <select v-model="component.urbandrainagetype" class="form-select" required>
                                <option disabled value="">{{ $t('hydraulics.select_urbandrainage_type') }}</option>
                                <option v-for="option in urbandrainageOptions" :value="option.value"
                                    :key="option.value">
                                    {{ option.label }}
                                </option>
                            </select>
                            <label>{{ $t('hydraulics.urbandrainage_type') }}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <AreaTypeTable ref="areaTypeTableRef" :areaTypeTable="areaTypeTable" @areaSelected="selectAreaGroup" />
    <SurfaceTypeTable ref="surfaceTypeTableRef" :surfaceTypeTable="surfaceTypeTable" @surfaceSelected="selectSurfaceType" />
</template>


<script setup>
import { ref, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import AreaTypeTable from './AreaTypeTable.vue';
import SurfaceTypeTable from './SurfaceTypeTable.vue';

const store = useStore();
const { t } = useI18n();

defineExpose({ getComponents, setComponents, getDeletedComponents, clearDeletedComponents, validateInput });

const hydraulicComponents = ref([]);
const formRef = ref(null);
let deletedComponents = [];
const collapsed = ref([]);  // Array to manage the collapse state of each component


const irrigationOptions = [
    { label: t('hydraulics.label_none'), value: null },
    { label: t('hydraulics.label_sprinkler'), value: 'sprinkler' },
    { label: t('hydraulics.label_driphose'), value: 'driphose' },
    { label: t('hydraulics.label_underfloor'), value: 'underfloor' }
];

const urbandrainageOptions = [
    { label: t('hydraulics.urbandrainage_rainwater'), value: 'rainwater' },
    { label: t('hydraulics.urbandrainage_mixwater'), value: 'mixwater' },
];

const areaTypeTableRef = ref(null);
const areaTypeTable = [
    {
        areaType: t('hydraulics.roofs'),
        areaSpecification: t('hydraulics.roof_specification_small_large'),
        areaGroup: 'D',
    },
    {
        areaType: t('hydraulics.courtyards_and_traffic_areas'),
        areaSpecification: t('hydraulics.courtyard_no_vehicle'),
        areaGroup: 'VW1',
    },
    {
        areaType: t('hydraulics.courtyards_and_traffic_areas'),
        areaSpecification: t('hydraulics.courtyard_light_traffic'),
        areaGroup: 'V1',
    },
    {
        areaType: t('hydraulics.courtyards_and_traffic_areas'),
        areaSpecification: t('hydraulics.marketplaces_frequent_events'),
        areaGroup: 'VW2',
    },
    {
        areaType: t('hydraulics.courtyards_and_traffic_areas'),
        areaSpecification: t('hydraulics.moderate_traffic_outside_residential'),
        areaGroup: 'V2',
    },
    {
        areaType: t('hydraulics.courtyards_and_traffic_areas'),
        areaSpecification: t('hydraulics.high_traffic_outside_industrial'),
        areaGroup: 'V3',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.railway_ballast_track_low_load'),
        areaGroup: 'BG1',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.airport_runways'),
        areaGroup: 'BF',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.agricultural_yards'),
        areaGroup: 'BL',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.railway_ballast_track_high_load'),
        areaGroup: 'BG2',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.roofs_with_material_20_70'),
        areaGroup: 'SD1',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.roofs_with_material_70_plus'),
        areaGroup: 'SD2',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.industrial_special_conditions'),
        areaGroup: 'SV',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.airport_washing_treatment'),
        areaGroup: 'SF',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.agricultural_yards_large_animals'),
        areaGroup: 'SL',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.railway_fixed_track_high_load'),
        areaGroup: 'BG3',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.heavily_impacted_railway_areas'),
        areaGroup: 'SG',
    },
    {
        areaType: t('hydraulics.operating_areas'),
        areaSpecification: t('hydraulics.waste_facility_impacted'),
        areaGroup: 'SA',
    },
];

const selectAreaGroup = (selection) => {
    hydraulicComponents.value[selection.componentIndex].areatype = selection.areaGroup;
};

const surfaceTypeTableRef = ref(null);
const surfaceTypeTable = [
    { surfaceType: t('hydraulics.roof'), surfaceSpecification: t('hydraulics.steep_roof'), surfaceGroup: 'A1' },
    { surfaceType: t('hydraulics.roof'), surfaceSpecification: t('hydraulics.flat_roof_smooth'), surfaceGroup: 'A2' },
    { surfaceType: t('hydraulics.roof'), surfaceSpecification: t('hydraulics.flat_roof_rough'), surfaceGroup: 'A3' },
    { surfaceType: t('hydraulics.roof'), surfaceSpecification: t('hydraulics.flat_roof_gravel'), surfaceGroup: 'A4' },
    { surfaceType: t('hydraulics.roof'), surfaceSpecification: t('hydraulics.green_roof'), surfaceGroup: 'A5' },
    { surfaceType: t('hydraulics.roof'), surfaceSpecification: t('hydraulics.retention_roof'), surfaceGroup: 'A6' },
    { surfaceType: t('hydraulics.traffic_area'), surfaceSpecification: t('hydraulics.asphalt_concrete'), surfaceGroup: 'B1' },
    { surfaceType: t('hydraulics.traffic_area'), surfaceSpecification: t('hydraulics.paving_dense_joints'), surfaceGroup: 'B2' },
    { surfaceType: t('hydraulics.traffic_area'), surfaceSpecification: t('hydraulics.joint_fraction_2_5'), surfaceGroup: 'B3' },
    { surfaceType: t('hydraulics.traffic_area'), surfaceSpecification: t('hydraulics.joint_fraction_6_10'), surfaceGroup: 'B4' },
    { surfaceType: t('hydraulics.traffic_area'), surfaceSpecification: t('hydraulics.porous_paving'), surfaceGroup: 'B5' },
    { surfaceType: t('hydraulics.traffic_area'), surfaceSpecification: t('hydraulics.gravel_lawn'), surfaceGroup: 'B6' },
    { surfaceType: t('hydraulics.traffic_area'), surfaceSpecification: t('hydraulics.grass_paving'), surfaceGroup: 'B7' },
    { surfaceType: t('hydraulics.traffic_area'), surfaceSpecification: t('hydraulics.water_bound_cover'), surfaceGroup: 'B8' },
    { surfaceType: t('hydraulics.water_management_facility'), surfaceSpecification: t('hydraulics.closed_basin_paved'), surfaceGroup: 'C1' },
    { surfaceType: t('hydraulics.water_management_facility'), surfaceSpecification: t('hydraulics.closed_basin_green'), surfaceGroup: 'C2' },
    { surfaceType: t('hydraulics.water_management_facility'), surfaceSpecification: t('hydraulics.open_water_surface'), surfaceGroup: 'C3' },
    { surfaceType: t('hydraulics.vegetation_area'), surfaceSpecification: t('hydraulics.garden_green_area'), surfaceGroup: 'D1' }
];

const selectSurfaceType = (selection) => {
    hydraulicComponents.value[selection.componentIndex].surfacetype = selection.surfaceGroup;
};


const hiddenProperties = {
    through: {
        outlets: [
            { height: 0.0, maxQ: 1000.0 },
            { height: -1.0, maxQ: 1000.0 }
        ],
        K_f_reductionfactor: 0.5,
        psi: 1.0,
        delta_theta: 0.1
    },
    rigol: {
        outlets: [
            { height: 0.0, maxQ: 1000.0 },
            { height: -1.0, maxQ: 1000.0 }
        ],
        K_f_reductionfactor: 0.5,
        psi: 1.0,
        delta_theta: 0.1
    },
    throughrigol: {
        outlets: [
            { height: 0.0, maxQ: 1000.0 },
            { height: -1.0, maxQ: 1000.0 }
        ],
        K_f_reductionfactor: 0.5,
        psi: 1.0,
        delta_theta: 0.1
    },
    shaft: {
        outlets: [
            { height: 0.0, maxQ: 1000.0 },
            { height: -1.0, maxQ: 1000.0 }
        ],
        K_f_reductionfactor: 0.5,
        psi: 1.0,
        delta_theta: 0.1
    },
    area: {
        outlets: [
            { height: 0.0, maxQ: 1000.0 },
            { height: -1.0, maxQ: 1000.0 }
        ],
        areatype: "D",
        width: 1.0,
        height: 0.001,
        type: "factor",
        K_f: 1e-5,
        K_f_reductionfactor: 0.5
    },
    storage: {
        outlets: [
            { height: 0.0, maxQ: 5.0 },
            { height: 0.05, maxQ: 0.0 }
        ]
    },
    greenroof: {
        outlets: [
            { height: 0.0, maxQ: 1.0 },
        ]
    },
    irrigation: {
        outlets: 1
    },
    pump: {
        // No hidden properties specified
    },
    throttle: {
        // No hidden properties specified
    }
};

// Watch for changes in components
watch(hydraulicComponents, (newComponents) => {
    newComponents.forEach((component, index) => {
        const selectedType = store.state.climagruen_types.hydraulic_components.find(type => type.description === component.type);
        if (selectedType) {
            component.type_id = selectedType.id;
            if (selectedType.description in hiddenProperties) {
                const hiddenProps = hiddenProperties[selectedType.description];
                Object.keys(hiddenProps).forEach((prop) => {
                    if (!(prop in component)) {
                        component[prop] = hiddenProps[prop];
                    }
                });
            }
        }
        // Initialize collapse state for new components
        if (collapsed.value[index] === undefined) {
            collapsed.value[index] = false;  // Default to collapsed
        }
    });
}, { deep: true });

// Toggle collapse function
function toggleCollapse(index) {
    collapsed.value[index] = !collapsed.value[index];
}

// Functions to add, remove, and manage components
function addHydraulicComponent() {
    hydraulicComponents.value.push({ type: '' });
    collapsed.value.push(true); 
}

function removeHydraulicComponent(index) {
    if (hydraulicComponents.value[index].id) {
        const deletedComponent = hydraulicComponents.value.splice(index, 1)[0];
        deletedComponents.push(deletedComponent);
    } else {
        hydraulicComponents.value.splice(index, 1);

    }
}

function getComponents() {
    return hydraulicComponents.value;
}

function getDeletedComponents() {
    return deletedComponents;
}

function clearDeletedComponents() {
    deletedComponents = [];
}

function setComponents(components) {
    hydraulicComponents.value = components;
}

// function validateInput() {
//     return formRef.value.reportValidity();
// }
function validateInput() {
    let isValid = true;
    let firstErrorField = null;

    // Iterate over all hydraulic components and validate inputs
    hydraulicComponents.value.forEach((component, index) => {
        const componentFields = document.querySelectorAll(
            `.components-container .component:nth-child(${index + 1}) .form-control`
        );

        let hasError = false;

        // Check each field for validity
        componentFields.forEach((field) => {
            if (!field.checkValidity()) {
                if (!firstErrorField && !field.disabled) {
                    firstErrorField = field; // Capture the first invalid field that is not disabled
                }
                hasError = true;
                isValid = false;
            }
        });

        // Ensure the component is expanded if there are validation errors
        if (hasError) {
            collapsed.value[index] = true;
        }
    });

    // Trigger form-level validation for other elements outside components
    nextTick(() => {
        if (formRef.value) {
            isValid = formRef.value.reportValidity() && isValid;
        }

        // Scroll to the first invalid field if any and ensure it's visible
        if (firstErrorField) {
            // Ensure the field is not disabled and focusable
            if (!firstErrorField.disabled) {
                firstErrorField.scrollIntoView({ behavior: "smooth", block: "center" });
            } else {
                console.warn("First invalid field is disabled and cannot be focused.");
            }
        }
    });

    return isValid;
}


function formatToDecimalPlace(index, field, round = 1) {
    let value = parseFloat(hydraulicComponents.value[index][field]);

    // If the value is a valid number, apply the rounding
    if (!isNaN(value)) {
        // Force Vue to trigger reactivity by setting the value explicitly
        hydraulicComponents.value[index][field] = Number(value.toFixed(round));
    }
}

function validateDecimalInput(index, field) {
    let value = hydraulicComponents.value[index][field];
    const regex = /^\d*(\.\d{0,1})?$/;

    if (!regex.test(value)) {
        // Remove last character if it doesn't match the pattern
        value = value.slice(0, -1);
        hydraulicComponents.value[index][field] = value;
    } else {
        // Apply formatting if the input is valid
        formatToDecimalPlace(index, field, 1);
    }
}

function validateDecimalInputSingle(component, field) {
    let value = component[field];
    const regex = /^\d*(\.\d{0,1})?$/;

    if (!regex.test(value)) {
        // Remove last character if it doesn't match the pattern
        value = value.slice(0, -1);
        component[field] = value;
    } else {
        // Round the value to one decimal place
        component[field] = Number(value).toFixed(1);
    }
}

const openAreaTable = (index) => {
    areaTypeTableRef.value.openModal(index);
};

const openSurfaceTable = (index) => {
    surfaceTypeTableRef.value.openModal(index);
};

</script>

<style scoped>
.pointer-events-none {
    pointer-events: none;
}
.components-tab {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.header-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.add-btn {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
}

.components-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

.component {
    display: flex;
    flex-direction: column;
    background-color: #2c2c2c;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.component-header {
    justify-content: space-between;
}

.component-select {
    width: 60%;
}

.input-fields-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}

.input-row {
    display: flex;
    gap: 10px;
}

.input-row .form-floating {
    flex-basis: calc(33.33% - 10px);
    /* Ensures 3 fields per row */
}

.image-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444;
    padding: 10px;
    border-radius: 8px;
}

.remove-btn {
    background-color: #dc3545;
    color: #fff;
    font-size: 1rem;
}

.input-group {
    display: flex;
    gap: 10px;
    margin-top: 1rem;
}

.input-small {
    max-width: 100px;
}

.form-control {
    padding: 0.5rem;
    font-size: 1rem;
}

.form-floating .form-control {
    color: #000;
    /* Ensures text is visible */
    background-color: #f8f9fa;
    /* Adjust the background color if needed */
}

.form-floating label {
    color: #6c757d;
    /* Default color for the label */
}
.cursor-pointer {
    cursor: pointer;
}
</style>
