<template>
    <LoadingSpinner v-if="loading" />
    <div id="login-mask" class="d-flex flex-column justify-content-center align-items-center">
      <div class="card text-white bg-dark mb-3">
        <div class="card-header">{{$t('register_page.register')}}</div>
        <div class="card-body">
          <p v-if="wasUserCreated">{{$t('register_page.email_sent')}}</p>
          <a v-if="wasUserCreated" href="/login" class="btn btn-light">{{$t('login')}}</a>
          <form v-else @submit.prevent="register">
            <div class="mb-3">
              <label for="firstName" class="form-label">{{$t('register_page.firstName')}}</label>
              <input type="text" id="firstName" v-model="firstName" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="lastName" class="form-label">{{$t('register_page.lastName')}}</label>
              <input type="text" id="lastName" v-model="lastName" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="lang" class="form-label">{{$t('register_page.lang')}}</label>
              <select id="lang" v-model="lang" class="form-control" required>
                <option value="de">{{ $t('lang.de') }}</option>
                <option value="en">{{ $t('lang.en') }}</option>
                <option value="it">{{ $t('lang.it') }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="city" class="form-label">{{$t('register_page.city')}}</label>
              <input type="text" id="city" v-model="city" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="country" class="form-label">{{$t('register_page.country')}}</label>
              <input type="text" id="country" v-model="country" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="zip" class="form-label">{{$t('register_page.zipCode')}}</label>
              <input type="text" id="zip" v-model="zip" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">{{$t('register_page.email')}}</label>
              <input type="email" id="email" v-model="email" class="form-control" required>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">{{$t('register_page.password')}}</label>
              <input type="password" id="password" v-model="password" class="form-control" required>
            </div>
            <button :disabled="loading" type="submit" class="btn btn-light">
              {{$t('register_page.register')}}
            </button>
          </form>
        </div>
      </div>
      <span v-if="!wasUserCreated">
        {{ $t("register_page.already_registered") }}
        <RouterLink :to="{ name: 'login'}">
          {{ $t("login") }}
        </RouterLink>
      </span>
    </div>
</template>
  
<script setup>
    import { ref } from 'vue';
    import {api_endpoints} from '../js/api_endpoints.js';
    import axios from 'axios';
    import LoadingSpinner from './notifications/LoadingSpinner.vue';
    import { RouterLink } from 'vue-router';


    const firstName = ref('');
    const lastName = ref('');
    const lang = ref('');
    const city = ref('');
    const country = ref('');
    const zip = ref('');
    const email = ref('');
    const password = ref('');

    const loading = ref(false);
    const wasUserCreated = ref(false);

    const register = async () => {
            const payload = {
                first_name: firstName.value,
                last_name: lastName.value,
                language: lang.value,
                email: email.value,
                password: password.value,
                city: city.value,
                zip_code: zip.value,
                country: country.value
            };
            
            loading.value = true;
            axios.post(api_endpoints.users, 
                        JSON.stringify(payload),
                        {
                            headers: { 
                                "Content-Type": "application/json"
                            }
                        }
            )
            .then(async (response) => {
                console.log("User registered successfully");
                loading.value = false;
                wasUserCreated.value = true;
            })
            .catch((error) => {
                console.log(error);
                loading.value = false;
            })
    };
</script>
  
<style scoped>
    #login-mask{
        grid-area: main;
    }
</style>