<template>
    <div class="weather-panel container-fluid">
        
        <!-- Tabs -->
        <ul class="nav nav-tabs" id="dashboardTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button 
                    class="nav-link" 
                    :class="{ active: activeTab === 'precipitation' }"
                    @click="activeTab = 'precipitation'"
                    type="button"
                    role="tab"
                    aria-controls="precipitation"
                    aria-selected="true"
                >
                    {{ t('welcome_page.modify_project_modal.weather_data') +': '+ t('welcome_page.modify_project_modal.statistical') }}
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button 
                    class="nav-link" 
                    :class="{ active: activeTab === 'blockRain' }"
                    @click="activeTab = 'blockRain'"
                    type="button"
                    role="tab"
                    aria-controls="blockRain"
                    aria-selected="false"
                >
                    {{t('welcome_page.modify_project_modal.weather_data') +': '+ t('welcome_page.modify_project_modal.extreme') }}
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button 
                    class="nav-link" 
                    :class="{ active: activeTab === 'simSettings' }"
                    @click="activeTab = 'simSettings'"
                    type="button"
                    role="tab"
                    aria-controls="simSettings"
                    aria-selected="false"
                >
                    {{t('welcome_page.modify_project_modal.sim_settings') }}
                </button>
            </li>
        </ul>

        <!-- Tab Content -->
        <div class="tab-content" id="dashboardTabContent">
            <div 
                class="tab-pane fade" 
                :class="{ 'show active': activeTab === 'precipitation' }" 
                id="precipitation" 
                role="tabpanel" 
                aria-labelledby="precipitation-tab"
            >
                <LoadingSpinnerModal 
                    v-if="loading_statistical_data"
                    :message='t("welcome_page.modify_project_modal.please_wait")'
                />
                <div v-else>
                    <button 
                        class="btn btn-secondary me-2" 
                        @click="handleStatisticalButton"
                    >
                        <!-- <font-awesome-icon icon="fa-solid fa-rotate-left"/> -->
                        {{ t('welcome_page.modify_project_modal.generate_data') }}
                    </button>
                    <WeatherChart
                        v-if="statisticalData"
                        :simulation_type="'statistical'"
                        :chart_data="statisticalData"
                    />
                    <span v-else class="text-dark">
                        {{ t('welcome_page.modify_project_modal.no_data_yet') }}
                    </span>
                </div>
            </div>
            <div 
                class="tab-pane fade" 
                :class="{ 'show active': activeTab === 'blockRain' }" 
                id="blockRain" 
                role="tabpanel" 
                aria-labelledby="blockRain-tab"
            >                
                <LoadingSpinnerModal 
                    v-if="loading_block_rain_data"
                    :message='t("welcome_page.modify_project_modal.please_wait")'
                />
                <div v-else>
                    <div class="d-flex align-items-center mb-3">
                        <button 
                            class="btn btn-secondary" 
                            @click="handleBlockRainButton"
                            :disabled="!block_rain_available"
                        >
                            <!-- <font-awesome-icon icon="fa-solid fa-rotate-left"/> -->
                            {{ t('welcome_page.modify_project_modal.generate_data') }}
                        </button>
                        <button v-if="!block_rain_available" type="button" class="btn-dark info ms-1" :title="t('welcome_page.modify_project_modal.extreme_rain_note')">
                            <font-awesome-icon icon="fa-solid fa-circle-info"/>
                        </button>

                        <button  
                            class="btn btn-primary ms-4" 
                            @click="saveBlockRainData"
                        >
                            <font-awesome-icon icon="fa-solid fa-save"/>
                        </button>

                        <!-- Button Group for View Mode -->
                        <div class="btn-group ms-4" role="group" aria-label="View mode selection">
                            <input 
                                type="radio" 
                                class="btn-check" 
                                name="viewMode" 
                                id="viewChart" 
                                autocomplete="off" 
                                value="chart"
                                v-model="viewMode"
                            >
                            <label class="btn btn-outline-secondary" for="viewChart">{{  t('welcome_page.modify_project_modal.diagram') }}</label>
                        
                            <input 
                                type="radio" 
                                class="btn-check" 
                                name="viewMode" 
                                id="viewTable" 
                                autocomplete="off" 
                                value="table"
                                v-model="viewMode"
                            >
                            <label class="btn btn-outline-secondary" for="viewTable">{{ t('welcome_page.modify_project_modal.table') }}</label>
                        </div>
                    </div>

                    <div v-if="blockRainData">
                        <WeatherChart 
                            v-if="viewMode === 'chart'"
                            :simulation_type="'blockrain'"
                            :chart_data="blockRainData"
                        />
                        
                        <WeatherTable 
                            v-else
                            :tableData="blockRainData"
                        />
                    </div>
                    <span v-else class="text-dark">
                        {{ t('welcome_page.modify_project_modal.no_data_yet') }}
                    </span>

                </div>
            </div>
        </div>
    </div>

    <!-- Success and Error messages -->
    <SuccessNotification v-if="success"/>
    <ErrorNotification v-if="failure" :message="error_message"/>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import WeatherChart from '@/components/modals/results/Weather.vue'
import WeatherTable from './WeatherTable.vue' // Import the WeatherTable component
import LoadingSpinnerModal from '@/components/notifications/LoadingSpinnerModal.vue';

import SuccessNotification from '@/components/notifications/SuccessNotification.vue';
import ErrorNotification from '@/components/notifications/ErrorNotification.vue';

import { getStatisticalWeatherData, generateStatisticalWeatherData, getBlockRain, generateBlockRain, modifyBlockRain } from '@/js/api_calls';
import store from '@/store';

const props = defineProps({
    block_rain_available: Boolean,
})

const statisticalData = ref(null);
const blockRainData = ref(null);
let project_id;

const success = ref(false);
const failure = ref(false);
const error_message = ref("");

// Internationalization
const { t } = useI18n()

// Reactive state for active tab
const activeTab = ref('precipitation')

// Reactive state for view mode: 'chart' or 'table'
const viewMode = ref('chart')

const loading_statistical_data = ref(false);
const loading_block_rain_data = ref(false);


onMounted(() => {
    // Fetch weather data
    project_id = store.state.current_project.id
    checkStatisticalData();
    checkBlockRainData();
})

const checkStatisticalData = async () => {
    loading_statistical_data.value = true;
    statisticalData.value = await getStatisticalWeatherData(project_id);
    loading_statistical_data.value = false;
}
const checkBlockRainData = async () => {
    loading_block_rain_data.value = true;
    const blockRainDataHelper = await getBlockRain(project_id);
    if(blockRainDataHelper === null && !props.block_rain_available){
        blockRainData.value = constructBlockRainData();
        viewMode.value = 'table';
    }else{
        blockRainData.value = blockRainDataHelper;
    }
    loading_block_rain_data.value = false;
}


// Handlers for top buttons
const handleStatisticalButton = async () => {
    loading_statistical_data.value = true;
    const data_generated = await generateStatisticalWeatherData(project_id);
    if(data_generated){
        statisticalData.value = await getStatisticalWeatherData(project_id);
        if(statisticalData.value === null){
            failure.value = true;
            error_message.value = t('welcome_page.modify_project_modal.data_could_not_be_loaded');
            setTimeout(() => {
                error_message.value = "";
                failure.value = false;
            }, 2500);
        }else{
            success.value = true;
            setTimeout(() => {
                success.value = false;
            }, 2500);
        }
    }else{
        failure.value = true;
        error_message.value = t('welcome_page.modify_project_modal.data_could_not_be_generated');
        setTimeout(() => {
            error_message.value = "";
            failure.value = false;
        }, 2500);
    }
    loading_statistical_data.value = false;
}

const handleBlockRainButton = async () => {
    loading_block_rain_data.value = true;
    const data_generated = await generateBlockRain(project_id);
    if(data_generated){
        const blockRainDataHelper = await getBlockRain(project_id);
        if(blockRainDataHelper === null){
            failure.value = true;
            error_message.value = t('welcome_page.modify_project_modal.data_could_not_be_loaded');
            setTimeout(() => {
                error_message.value = "";
                failure.value = false;
            }, 2500);
        }else{
            blockRainData.value = blockRainDataHelper;
            success.value = true;
            setTimeout(() => {
                success.value = false;
            }, 2500);
        }
    }
    else{
        failure.value = true;
        error_message.value = t('welcome_page.modify_project_modal.data_could_not_be_generated');
        setTimeout(() => {
            error_message.value = "";
            failure.value = false;
        }, 2500);
    }

    loading_block_rain_data.value = false;
}

const constructBlockRainData = () => {
    const default_data = {};

    // Define durations and frequencies
    const durations = [
        5, 10, 15, 20, 30, 45, 60, 90, 120,
        180, 240, 360, 540, 720, 1080, 1440,
        2880, 4320, 5760, 7200, 8640, 10080,
    ]

    const frequencies = [1, 2, 3, 5, 10, 20, 30, 50, 100]

    durations.forEach(duration => {
        default_data[duration] = {}
        frequencies.forEach(freq => {
            default_data[duration][freq] = null
        })
    })
    return default_data;
}

const saveBlockRainData = async () => {
    console.log('BlockRain Data:', blockRainData.value)
    loading_block_rain_data.value = true;
    const data_saved_in_db = await modifyBlockRain(project_id, blockRainData.value);
    if(data_saved_in_db){
        success.value = true;
        setTimeout(() => {
            success.value = false;
        }, 2500);
    }
    else{
        failure.value = true;
        error_message.value = t('welcome_page.modify_project_modal.data_could_not_be_saved');
        setTimeout(() => {
            error_message.value = "";
            failure.value = false;
        }, 2500);
    }
    loading_block_rain_data.value = false;
}

</script>

<style scoped>
.weather-panel {
    padding-left: 2rem;
    width: auto;
}

.nav{
    border: none;
}

.nav-link {
    border: 1px solid transparent;
    color:#f8f9fa;
    text-decoration: none;
}

.nav-link.active {
    background-color: white;
    border-color: #e5e5e5 #e5e5e5 #fff;
    color: #495057;
}
.tab-content{
    position: relative;
    background-color: white;
    padding: 2rem 1rem 1rem 1rem;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}


.spinner-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1050;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.info{
    background: none;
    outline: none;
    border: none;
}

.info[title]{
    max-width: 200px;
}

</style>
