<template>
    <div class="weather-table-container">
        <table class="table table-bordered weather-table">
            <thead class="table-light">
                <tr>
                    <th rowspan="2">{{$t('results.rain_duration')}}</th>
                    <th colspan="100%">{{$t('results.rain_return_period')}}</th>
                </tr>
                <tr>
                    <th v-for="freq in frequencies" :key="freq">{{ freq }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="duration in durations" :key="duration">
                    <td>{{ duration }}</td>
                    <td v-for="freq in frequencies" :key="freq">
                        <input
                            type="number"
                            class="form-control"
                            v-model.number="tableData[duration][freq]"
                            step="any"
                            @change="validateValue(duration, freq)"
                            required
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const {t} = useI18n();
const props = defineProps({
    tableData: {
        type: Object,
        required: true
    }
})

const durations = ref([]);
const frequencies = ref([]);

const emit = defineEmits(['update:tableData']);

onBeforeMount(() => {
    getDurationsAndFrequencies(props.tableData);
})

function validateValue(duration, freq) {
    const value = props.tableData[duration][freq];
    if (value < 0) {
        props.tableData[duration][freq] = 0.0; // Reset the value or show an error
        alert(t('welcome_page.modify_project_modal.input_cannot_be_0'));
    }
}

function getDurationsAndFrequencies(tableData) {
    // Retrieve durations (keys of tableData) and sort them
    durations.value = Object.keys(tableData).map(Number).sort((a, b) => a - b);

    // Retrieve frequencies (keys of the inner objects) and sort them
    frequencies.value = [
        ...new Set(
            durations.value.flatMap(duration => Object.keys(tableData[duration]).map(Number))
        )
    ].sort((a, b) => a - b);
}

</script>

<style scoped>
.weather-table-container {
    max-height: 50vh; 
    overflow-y: auto;
    width: 100%;
}

.weather-table {
    width: 100%;
    border-collapse: collapse;
}

thead th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa; 
    z-index: 2; /* Ensure headers stay above table body */
}

th, td {
    text-align: center;
    vertical-align: middle;

}

input {
    width: 100px;
}

.weather-table-container::-webkit-scrollbar {
    width: 8px;
}

.weather-table-container::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.weather-table-container::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
}
</style>
