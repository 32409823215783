<template>
    <div class="custom-modal">
        <div class="results-modal bg-dark text-white">
            <div class="modal-header">
                <div class="modal-title">{{ $t("results.modal_title") }}</div>
                <button class="close btn-close btn-close-white" @click="closeModal"></button>
            </div>
            <div class="modal-body">
                <SelectSimulation/>
                <div v-if="selectedSimulation && selectedSimulationInfo.status === 'success'" class="result-container">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'weather' }" @click="activeTab = 'weather'">{{ $t('results.tab_weather') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'time_series' }" @click="activeTab = 'time_series'">{{ $t('results.tab_time_series') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'hydraulics' }" @click="activeTab = 'hydraulics'">{{ $t('results.tab_hydraulic') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'water_balance' }" @click="activeTab = 'water_balance'">{{ $t('results.tab_water_balance') }}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" :class="{ active: activeTab === 'flooding' }" @click="activeTab = 'flooding'">{{ $t('results.tab_flooding') }}</a>
                        </li>
                    </ul>
                    
                    <LoadingSpinnerModal v-if="loading" :message="''" />

                    <div v-if="!loading" class="tab-content">
                        <div v-if="activeTab === 'weather'">
                            <div class="form-floating">
                                <select
                                    id="simulationType-dropdown"
                                    class="form-select"
                                    v-model="selectedSimulationType"
                                    @change="handleSimulationTypeChange"
                                >
                                    <option :value="null" disabled>
                                        {{ $t('results.select_simulation_type') }}
                                    </option>
                                    <option
                                        v-for="simulationType in simulationTypes"
                                        :key="simulationType.value"
                                        :value="simulationType.value"
                                        :disabled="simulationType.value === 'long_term'"
                                    >
                                        {{ simulationType.name }} 
                                    </option>
                                </select>
                                <label for="simulationType-dropdown" class="form-label">
                                    {{ $t('results.simulation_type') }}
                                </label>
                            </div>   
                            <Weather :simulation_type="selectedSimulationType" :chart_data="current_weather_data"/>
                        </div>
                        <TimeSeries v-else-if="activeTab === 'time_series'"/>
                        <Hydraulics v-else-if="activeTab === 'hydraulics'"/>
                        <WaterBalance v-else-if="activeTab === 'water_balance'"/>
                        <Flooding v-else-if="activeTab === 'flooding'"/>
                    </div>
                </div>
                <div v-else-if="selectedSimulation" class="result-container">
                    {{ selectedSimulationInfo.log }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, watch, computed, onBeforeUnmount, onBeforeMount } from 'vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import LoadingSpinnerModal from '../../notifications/LoadingSpinnerModal.vue';
    import TimeSeries from './TimeSeries.vue';
    import Weather from './Weather.vue';
    import WaterBalance from './WaterBalance.vue';
    import Flooding from './Flooding.vue';
    import Hydraulics from './Hydraulics.vue';

    import SelectSimulation from './SelectSimulation.vue';


    const { t } =  useI18n();

    const selectedSimulation = computed(() => store.state.simulations.selectedSimulation);
    const selectedSimulationInfo = ref({});
    const selectedSimulationType = ref('statistical');

    // Simulations fetched from the Vuex store
    const simulationTypes = [
        { value: 'statistical', name: t('results.statistical_type')},
        { value: 'blockrain', name: t('results.extreme_type')},
        { value: 'long_term', name: t('results.long_term_type')},
    ];    const activeTab = ref('weather');

    const current_weather_data = ref(null);
    const weather_data = {};

    const store = useStore();
    const emit = defineEmits(['close-modal']);
    const loading = ref(false);

    watch(selectedSimulation, selectSimulation);

    onBeforeMount(() => {
        store.commit('simulations/SET_SELECTED_SIMULATION', null);
    });


    async function selectSimulation () {
        if(!selectedSimulation.value) return;
        selectedSimulationInfo.value = store.state.simulations.simulations.find(sim => sim.id === selectedSimulation.value)

        if(selectedSimulationInfo.value.status != 'success'){
            return;
        }

        loading.value = true;
        const simulationId = selectedSimulation.value;
        
        const results_total = await store.dispatch('simulations/getSimulationResult', { simulationId, resultType: 'total'});
        const block_rain_data = processBlockRainData(results_total.blockrain);
        weather_data.blockrain = block_rain_data;
        console.log("Block rain data", block_rain_data);
        const results_statistical = await store.dispatch('simulations/getSimulationResult', { simulationId, resultType: 'statistical'});
        const {dates, precipitation} = results_statistical;
        weather_data.statistical = {dates, precipitation};
        
        current_weather_data.value = weather_data[selectedSimulationType.value];

        loading.value = false;
    };

    const processBlockRainData = (rawData) => {
        const orderedData = {};

        Object.keys(rawData).forEach(frequencyKey => {
            const frequency = frequencyKey.slice(1); // Remove the 'a' prefix
            const durations = rawData[frequencyKey];

            Object.keys(durations).forEach(duration => {
                if (!orderedData[duration]) {
                    orderedData[duration] = {};
                }
                orderedData[duration][frequency] = durations[duration];
            });
        });

        return orderedData;
    };

    const handleSimulationTypeChange = () => {
        console.log("Selected simulation type", selectedSimulationType.value);
        current_weather_data.value = weather_data[selectedSimulationType.value];
    };

    const closeModal = () => {
        emit('close-modal');
    };
</script>

<style scoped>
.simType-select{
    position: relative;
    top: 0;
    left: 0;
    width: 30%;
}
.results-modal {
    width: 80vw;
    min-height: 30vh;
    max-height: 95vh;
    max-width: 1000px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    padding: 1rem;
}

.modal-title {
    font-size: 1.25rem;
    font-weight: 500;
}



.modal-body {
    padding: 2rem 1rem;
    overflow-y: hidden;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
}

.result-container{
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color:#343a40;
    border-radius: 0.5rem;
    overflow: hidden;
}

.nav-tabs {
    display: flex;
    width: max-content;
    margin-bottom: 0;
    border: none;
    /* position: sticky; */
    top: 0;
    border: 1px solid #e5e5e5;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    gap: 8px;
}

/* .nav-item {
    margin-right: 8px;
} */

.nav-link {
    padding: 8px 16px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    color:#f8f9fa;
    text-decoration: none;
}

.nav-link.active {
    background-color: white;
    border-color: #e5e5e5 #e5e5e5 #fff;
    color: #495057;
}

.tab-content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* border: 1px solid #e5e5e5; */
    /* background-color: rgba(128, 128, 128, 0.527); */
    background-color: white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.align-right{
    width: max-content;
    align-self: flex-end;
}
</style>